import React, { useState } from 'react'

import {
    Button,
    FormHelperText,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react'
import AutoLinkText from 'react-autolink-text2';

import { QuestionIcon } from '@chakra-ui/icons'

const Helper = ({
    pictureFileUrl,
    label
}) => {

    // return (<>{label}</>)

    const [isActive, setIsActive] = useState(false)

    // if( !pictureFileUrl || !label ){ return false; }
    if (!pictureFileUrl && !label) {
        return null
    }

    else if (!pictureFileUrl) {
        return (
            <Text
                mt={3}
                fontSize='xs'
                color='gray.500'
            >

                {/* <AutoLinkText
                    text={label}
                    disableUrlStripping={true}
                    linkProps={{
                        style: { textDecoration: 'underline' },
                        target: '_blank'
                    }}
                /> */}
                <div
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            </Text>
        )
    }

    else if (pictureFileUrl && label) {

        return (
            <>
                <FormHelperText>
                    <QuestionIcon mr={1} />
                    <Button size='sm' whiteSpace={`pre-wrap`} variant='link' onClick={() => setIsActive(!isActive)}>{label}</Button>
                </FormHelperText>
                <Modal
                    isOpen={isActive}
                    onClose={() => setIsActive(false)}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                            <ModalHeader>
                                {label}
                            </ModalHeader>
                            <Image
                                src={pictureFileUrl}
                                alt='label'
                            />
                            <ModalFooter>
                                <Button onClick={() => setIsActive(false)}>Fermer</Button>
                            </ModalFooter>
                        </ModalBody>
                    </ModalContent>

                </Modal>
            </>
        )

    }

    return null


}

export default Helper