import { Box, Button, ButtonGroup, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl';

const ImageRotate = ({
    src,
    validate
}) => {
    const [rotateIndex, setRotateIndex] = React.useState(0);
    const [imageHasBeenDrawn, setImageHasBeenDrawn] = React.useState(0);
    const [isBuffering, setIsBuffering] = React.useState(false);

    useEffect(() => {
            setTimeout(()=>{
                setImageHasBeenDrawn( false );
                setIsBuffering( true );
                drawRotate();
            }, 1000)


        // if( !isInitiated ){ initialisation() }
    }, [rotateIndex]);

    // const initialisation = () => {
    //     setRotateIndex(rotateIndex + 1);
    //     setRotateIndex(rotateIndex - 1);
    //     setRotateIndex( 0 );
    //     setIsInitiated( true )
    // }

    const rotateRight = () => {
        setRotateIndex(rotateIndex + 1);
    }

    const rotateLeft = () => {
        setRotateIndex(rotateIndex - 1);
    }

    const drawRotate = () => {
        // console.log('drawRotate');

        var sourceimage = document.querySelector('#uploadedProfilPict');
        var canvas = document.querySelector('canvas');
        canvas.height = canvas.width = 0;
        var context = canvas.getContext('2d');

        var imgwidth = sourceimage.width;
        var imgheight = sourceimage.height;

        canvas.width = imgwidth;
        canvas.height = imgheight;
        context.save();
        context.translate(imgwidth / 2, imgheight / 2);
        context.rotate((Math.PI * rotateIndex) / 2);
        context.drawImage(
            sourceimage,
            -(imgwidth / 2),
            -(imgheight / 2),
            350,
            imgheight / (imgwidth / 350)
        );
        context.restore();

        setTimeout(()=>{
            setImageHasBeenDrawn(true)
            setIsBuffering( false );

        }, 1000 );



    }

    const transfertImageToCropper = () => {
        
        setIsBuffering( true );
        
        var canvas = document.querySelector('canvas');
        var dataURL = canvas.toDataURL();

        setTimeout(
            ()=> validate(dataURL),
            1000
        )

        // validate(dataURL);
    }
    

    return (
        <Stack
            overflow='hidden'
            p={2}
        >
            <Heading fontSize={{ base: 'xl', lg: 'xl' }}>

                <FormattedMessage id='picture.orientation' />
            :</Heading>
            <Text>
                <FormattedMessage id='picture.orientation.notice' />
            </Text>

            {/* <Box as='pre'>{ src }</Box> */}
            <Box
                position='relative'
            >
                <Flex
                    justify='center'
                    position='absolute'
                    p={6}
                    top={0}
                    left={0}
                    right={0}
                    zIndex='tooltip'
                >
                    <ButtonGroup size="sm">
                        <Button
                            boxShadow='md'
                            onClick={() => rotateLeft()}
                        >↺
                            <Box
                                as='span'
                                display={{ base: 'none', lg: 'inline-block' }}
                                ml={1}
                            >
                                <FormattedMessage id='picture.orientation.rotate.left' />
                            </Box>
                        </Button>

                        <Button
                            onClick={() => transfertImageToCropper()}
                            boxShadow='md'
                            colorScheme={'blue'}
                            isLoading={ isBuffering || !imageHasBeenDrawn }
                            isDisabled={ !imageHasBeenDrawn }
                        >
                            <FormattedMessage id='picture.orientation.validate' />
                        </Button>

                        <Button
                            boxShadow='md'
                            onClick={() => rotateRight()}
                        >
                            <Box
                                as='span'
                                display={{ base: 'none', lg: 'inline-block' }}
                                mr={1}
                            >
                                <FormattedMessage id='picture.orientation.rotate.right' />
                            </Box>
                             ↻</Button>
                    </ButtonGroup>


                </Flex>

                <Box
                    transition='transform 200ms ease-in-out'
                    transform={`rotate(${rotateIndex * 90}deg)`}

                >
                    <img
                        // style={{ display: 'none' }}
                        id='uploadedProfilPict'
                        src={src}
                    /></Box>
            </Box>

            <Box
                display='none'
            >
                <canvas></canvas>
            </Box>

        </Stack>
    )
}

export default ImageRotate