import React from 'react'
import {
    Box,
    Flex,
    SimpleGrid,
    Text
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { useIntl, FormattedMessage } from 'react-intl'

const ProgressBar = ({ step, errors, values, hasResponsiblesStep }) => {

    const messages = useIntl()['messages']
    const locale = useIntl()['locale']

    const steps = [
        messages['step.1'],
        // messages['step.2'],
        ...(hasResponsiblesStep ? [messages['step.2']] : ``),
        messages['step.3'],
        messages['step.4'],
        messages['step.5']
        // 'Profil',
        // 'Responsable',
        // 'Confirmation',
        // 'Paiement',
        // 'finalisation'
    ]

    const getStepPercent = () => {
        return 50
    }
    return (
        step > 0 ?
            <>
                {/* <pre>
                    { JSON.stringify( messages, false, 2 )}
                </pre> */}
                <Box
                    display={{ base: 'none', lg: 'block' }}
                    px={4}
                    py={2}
                    // mx={4}
                    borderRadius={30}
                    // position='fixed'
                    // top='0'
                    // w='1000px'
                    // bg='white'
                    zIndex='tooltip'
                    bg='white'
                >
                    <SimpleGrid
                        columns={steps.filter(item => item).length}
                    // gap={10}
                    >
                        {steps.map((label, index) =>
                            index < 4 && label ?
                                <Flex
                                    position='relative'
                                    zIndex='banner'
                                    justify='center'
                                    key={`breadcrumbe-item-${index}`}
                                >
                                    <Text
                                        bg='white'
                                        boxShadow='sm'
                                        borderRadius='20px'
                                        key={`progressBarStep${index}`}
                                        color={
                                            step > index + 1 ? 'brand.1.500'
                                                : step === index + 1 ? 'gray.700'
                                                    : 'gray.400'}
                                        textTransform='uppercase'
                                        transform='color 200ms'
                                        // borderBottom='solid 4px'
                                        // borderBottomColor='gray.100'
                                        p={2}
                                        px={5}
                                        fontWeight='bold'
                                        fontSize='sm'
                                        textAlign='center'
                                    >
                                        {step > index + 1 ?
                                            <CheckCircleIcon mr={2} />
                                            : null}

                                        {label}
                                    </Text>
                                    {/* <Box
                                    transform='translateY(-12px)'
                                    borderRadius='6px'
                                    h='4px'
                                    w={`${ getStepPercent() }%`}
                                    bg='brand.1.500'
                                    transition='all 600ms ease'
                                /> */}
                                </Flex>
                                : null

                        )}


                    </SimpleGrid>

                    <Box
                        bg='gray.200'
                        transform='translateY(-18px)'
                    >
                        <Box
                            borderRadius='6px'
                            h='4px'
                            w={`${(step - 1) * 25 + 12.5}%`}
                            maxW='100%'
                            bg='brand.1.500'
                            transition='all 3600ms ease'
                        />
                    </Box>

                </Box>
                <Flex
                    display={{ base: 'block', lg: 'none' }}
                    px={6}
                >
                    <Box
                        bg='brand.1.500'
                        color='white'
                        px={5}
                        py={2}
                        borderRadius='20px'
                    >
                        <Text
                            fontWeight='700'
                        >
                            <FormattedMessage id="step" /> {step} : {steps[step - 1]}
                        </Text>
                    </Box>
                </Flex>
            </>
            : null
    )
}

export default ProgressBar