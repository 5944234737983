import * as React from 'react'
import { Box, Button, Divider, Flex, Heading, SimpleGrid, Stack } from "@chakra-ui/react"
import Debug from "../Form/debug"
import dayjs from 'dayjs'
require('dayjs/locale/fr')
dayjs.locale('fr')

const dataMatch = [
    {
        key: "firstName",
        displayName: "Prénom"
    },
    {
        key: "firstName_2",
        displayName: "Deuxième prénom"
    },
    {
        key: "firstName_3",
        displayName: "Troisième prénom"
    },
    {
        key: "lastName",
        displayName: "Nom"
    },
    {
        key: "city",
        displayName: "Ville"
    },
    {
        key: "birthPlace_country",
        displayName: "Pays de naissance"
    },
    {
        key: "birthPlace_postalCode",
        displayName: "Code postal de la localité de naissance"
    },
    {
        key: "birthPlace_city",
        displayName: "Nom de la localité de naissance"
    },
    {
        key: "birthDate",
        displayName: "Date de naissance",
        transformer: (value) => dayjs(value).format('DD MMMM YYYY')
    },
    {
        key: "nationality",
        displayName: "Nationalité"
    },
    {
        key: "postalCode",
        displayName: "Code postal de la localité de résidence"
    },
    {
        key: "city",
        displayName: "Localité de résidence"
    },
    {
        key: "country",
        displayName: "Pays de résidence"
    },
    {
        key: "address",
        displayName: "Adresse"
    },
    {
        key: "phone",
        displayName: "Numéro de téléphone"
    },
    {
        key: "gender",
        displayName: "Genre"
    },

]

const subjects = dataMatch.map(item => item.key)

const Line = ({ subject, value }) => {
    // console.log(typeof value, value)
    console.log('subject', subject, value)
    if (
        typeof value !== 'string'
        // && subject !== 'firstName'
        || subjects.indexOf(subject) === -1
        || !value
    ) return null
    else return (
        <>
            <Flex
                justify={'space-between'}
                fontSize={'sm'}
            >
                <Box
                    color={`gray.500`}
                >
                    {dataMatch.filter(item => item.key === subject)[0].displayName}
                </Box>
                <Box
                    color={`gray.800`}
                    fontWeight={'600'}
                >
                    {
                        !dataMatch.filter(item => item.key === subject)[0].transformer ?
                            value
                            :
                            dataMatch.filter(item => item.key === subject)[0].transformer(value)
                    }
                </Box>
            </Flex>
            <Divider />
        </>
    )
    // return (<Box>Subject : {subject} / Value : {value}</Box>)

}

const ResponsiblesDataSummary = ({ data }) => {
    if (!data || data.length === 0) return null
    return (
        <Stack spacing={4}>
            <Heading fontSize={"md"}>Reponsables :</Heading>
            <SimpleGrid gap={8} columns={{ base: 1, lg: data.length }}>
                {data.map((person, index) =>
                    <Stack spacing={2} key={`person-${index}`}>
                        {Object.keys(person).map((keyName, i) => (
                            <Line key={i} subject={keyName} value={person[keyName]} />
                        ))}
                    </Stack>
                )}
            </SimpleGrid>
        </Stack>
    )
}

export const DataSummary = ({ data }) => {
    const [visible, setVisible] = React.useState(true)
    if (!data) return
    return (
        <Stack id="data-summary" spacing={2}>
            <Button
                onClick={() => setVisible(!visible)}
            >
                Rappel des informations saisies
            </Button>
            <Stack
                display={!visible ? `none` : `block`}
                bgColor={`white`}
                rounded={'md'}
                shadow={'md'}
                p={4}
                spacing={10}

            >
                <Stack
                    spacing={2}

                >
                    {Object.keys(data).map((keyName, i) => (
                        <Line key={i} subject={keyName} value={data[keyName]} />
                    ))}
                </Stack>
                <ResponsiblesDataSummary data={data.responsibles} />
                {/* <Debug data={data.responsibles} /> */}
            </Stack>
        </Stack>
    )
}