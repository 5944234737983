import React, { Component } from 'react'

import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react'
import Layout from '../Layouts/student-funnel'
import axios from 'axios'
import { navigate } from '@reach/router'


class ProfileSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialValues: null,
            isProfilLoaded: false,
            isError: false,
            student: {},
            data: {},
            settings: false
            // settings:{
            //     // ineNumber: { isEnabled: true, value:'1234567890A', isValid: true },
            //     // rqthFile: { isEnabled: false, value:'///' },
            //     // cvFile: { isEnabled: true, value:'///' },
            //     // apprenticeNumber: { isEnabled: true, value:'///' },
            //     // aLevelHighSchool: { isEnabled: true, value:123 },
            //     // aLevelYear: { isEnabled: true, value:2003, isValid:true },
            // }
        }
    }
    componentDidMount() {
        const _this = this
        axios.get(
            `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${this.props.id}`
            // `${process.env.REACT_APP_API_URL}/student/${this.props.id}`
        )
            .then(
                (response) => {
                    if (response.data) {
                        if (response.data.status !== 'completed') {
                            // alert('Warning : you should have paid before start this step')
                            navigate(`/${this.props.lang}/admission/${this.props.id}`)
                        }
                        _this.setState({
                            settings: response.data,
                            initialValues: response.data,
                            student: response.data,
                            data: response.data,
                            isProfilLoaded: true,
                            isError: false
                        })
                    } else {
                        _this.setState({
                            isError: true
                        })
                    }

                },
                (error) => {
                    _this.setState({
                        isError: true
                    })
                    console.log(error)
                }
            )
    }

    render() {
        const { initialValues, isError, settings, data } = this.state

        return (
            initialValues ?
                <Layout
                    school={initialValues.school}
                    locale={this.props.lang}
                >
                    <Box
                        p={2}
                    >
                        <Center
                            minH={{ base:'50vh', lg:'100vh'}}
                            minW='100%'
                        >

                            <Box
                                bg='white'
                                borderRadius='md'
                                boxShadow='md'
                                w='lg'
                                position='relative'
                                p={8}
                                pt={18}
                            >
                                <Center
                                    position='absolute'
                                    top='0'
                                    left='0'
                                    right='0'
                                    w='100%'
                                >

                                    <Center
                                        w={{ base:'90px', lg:'120px' }}
                                        h={{ base:'90px', lg:'120px' }}
                                        bg='white'
                                        fontSize='5xl'
                                        boxShadow='md'
                                        borderRadius='full'
                                        transform='translateY(-55px)'
                                    >
                                        👍
                                </Center>
                                </Center>

                                <Heading
                                    fontSize='xl'
                                    mt={16}
                                    textAlign='center'
                                    mb='4'
                                >
                                    Votre profil est complet,{' '}
                                    <Text
                                        as='span'
                                        borderBottom='solid 3px'
                                        borderBottomColor='brand.1.500'
                                    >
                                        félicitations

                                    </Text>
                                    .
                                </Heading>
                            </Box>
                        </Center>
                    </Box>
                </Layout>
                : null
        )
    }
}

export default ProfileSuccess