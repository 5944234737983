import React, { Component } from 'react'
import {
    Box,
    Button,
    Input,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Stack,
    Spinner,
    Text
} from "@chakra-ui/react"
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl'
import CanvasDraw from "react-canvas-draw";
import html2canvas from 'html2canvas';
import css from '../Styles/wysiwyg.css'

require('typeface-caveat')


class DocumentReadingModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            content: null,
            sign: null,
            firstName: null,
            lastName: null,
            isDrawing: true,
            signCanvas: React.createRef()

        }
    }

    componentDidMount() {
        this.setState({ sign: false })
        this.setState({ content: '' });
        axios.get(this.props.url)
            .then((response) => {
                this.setState({ content: response.data });
            });
    }
    // componentDidUpdate(){
    //     axios.get(this.props.url)
    //         .then((response) => {
    //             this.setState({ content: response.data });
    //         });
    // }

    // const [content, setContent] = useState('')
    // const [sign, setSign] = useState('')
    // const [firstName, setFirstName] = useState(props.firstName )
    // const [lastName, setLastName] = useState(props.lastName )
    // const translations = useIntl()['messages']

    handleClose() {
        this.setState({ content: null }, this.props.onClose())
    }

    render() {

        const currentDate = () => {

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            var todayDate = []

            // today = dd + '/' + mm + '/' + yyyy;
            todayDate["fr"] = dd + '/' + mm + '/' + yyyy;
            todayDate["en"] = mm + '/' + dd + '/' + yyyy;

            return (todayDate)
        }


        // useEffect(() =>{
        //     // setSign( false );
        //     axios.get(props.url)
        //         .then((response) => {
        //             setContent(response.data)
        //         });
        // });

        const { title, firstName, lastName, isOpen, onClose, translations, modalData, handleDecline, locale } = this.props
        const { content, signCanvas, sign } = this.state

        return (
            <Modal
                size='4xl'
                isOpen={isOpen}
                onClose={() => this.handleClose()}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader
                        bg='gray.50'
                    >
                        {title ? title : null}
                        <FormattedMessage id='document.sign.notice.2' />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        pb={20}
                    >
                        {
                            content
                                ?
                                <Box p={{ base: 0, lg: 15 }}>
                                    <div className={'wysiwyg'} dangerouslySetInnerHTML={{ __html: content }} />
                                </Box>
                                :
                                <Flex p={10} justify='center'><Spinner size="sm" /></Flex>
                        }
                        <Stack
                            spacing={{ base: 4, lg: 6 }}
                            p={{ base: 0, lg: 15 }}
                            display={modalData.readOnly ? 'none' : 'block'}
                        >

                            <Box my={4}>
                                <Text
                                    display={{ base: 'block', lg: 'none' }}
                                >
                                    <FormattedMessage id="document.sign.notice.4" />
                                </Text>
                                <Text
                                    display={{ base: 'none', lg: 'block' }}
                                >
                                    <FormattedMessage id="document.sign.notice.5" />
                                </Text>
                            </Box>

                            <Box
                                w='320px'
                                h='280px'
                                border='solid 2px'
                                borderColor='gray.400'
                                id='signModule'
                                bg='white'
                                borderRadius={`md`}
                            >
                                <Text
                                    px={4}
                                    py={2}
                                    fontFamily='Caveat'
                                    fontSize='18px'
                                >
                                    <FormattedMessage id="read.and.approved.on" />&nbsp;{currentDate()[locale]}
                                    :</Text>
                                <Text
                                    px={4}
                                    py={2}
                                    borderBottom='solid 1px'
                                    borderBottomColor='gray.100'
                                    fontFamily='Caveat'
                                >
                                    {`${firstName} ${lastName}`}
                                </Text>
                                <Box
                                    px={4}
                                    py={2}
                                >
                                    <Text fontSize='14px'><FormattedMessage id='student.signature' />&nbsp;:</Text>
                                </Box>
                                <Box
                                    border={this.state.isDrawing ? `solid 4px` : `none`}
                                    borderColor={`green.300`}
                                >
                                    <CanvasDraw
                                        ref={signCanvas}
                                        onChange={() => { this.setState({ sign: true }) }}
                                        loadTimeOffset={1}
                                        lazyRadius={4}
                                        brushRadius={1}
                                        brushColor={"#444"}
                                        catenaryColor={"#0a0302"}
                                        hideGrid={true}
                                        canvasWidth={305}
                                        canvasHeight={148}
                                        disabled={false}
                                        imgSrc={""}
                                        saveData={null}
                                        immediateLoading={false}
                                        hideInterface={false}

                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Stack
                                    isInline
                                    wrap='wrap'
                                >
                                    <Button
                                        onClick={
                                            () => {
                                                this.setState({ isDrawing: false });
                                                setTimeout(
                                                    () => {
                                                        html2canvas(document.querySelector("#signModule"),
                                                            {
                                                                imageTimeout: 1000,
                                                                scrollX: 0,
                                                                scrollY: 0
                                                            }).then(canvas => {
                                                                var signImage = canvas.toDataURL();
                                                                this.props.handleSign(signImage);
                                                                console.log(signImage)
                                                            });
                                                    },
                                                    2000
                                                )
                                            }
                                        }
                                        isDisabled={!sign || !firstName}
                                        colorScheme='blue'
                                        background='brand.1.500'
                                        _hover={{
                                            bg: 'brand.1.600'
                                        }}
                                    >
                                        <FormattedMessage id="confirm.your.signature" />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.setState({ sign: false })
                                            signCanvas.current.clear()
                                        }}
                                    >
                                        <FormattedMessage id="button.delete" />
                                    </Button>
                                    {!modalData.mandatory ?
                                        <Button
                                            variant='shadow'
                                            onClick={() => handleDecline()}
                                        >
                                            <FormattedMessage id="i.dont.want.to.sign" />
                                        </Button>
                                        : null}
                                </Stack>
                            </Box>

                        </Stack>
                        <Box
                            display={modalData.readOnly ? 'block' : 'none'}
                            p={{ base: 5, lg: 15 }}
                            pb={20}
                        >
                            <Button
                                onClick={() => this.props.handleSign(null)}
                            >
                                <FormattedMessage id="i.have.read.this.document" />
                            </Button>
                        </Box>
                    </ModalBody>

                </ModalContent>
            </Modal>
        )
    }
}

export default DocumentReadingModal
