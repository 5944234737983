import React, { useState } from 'react'
import { Transition } from 'react-transition-group';
import {
  Box,
  Flex,
  Button,
  Center,
  Text
} from '@chakra-ui/react';

const duration = 300;

const defaultStyle = {
  transition: `
        opacity ${duration}ms ease-in-out,
        transform ${duration}ms ease-in-out
    `,
  opacity: 0,
  transform: `translateY(100%)`
}

const transitionStyles = {
  entering: { opacity: 1, transform: `translateY(0%)` },
  entered: { opacity: 1, transform: `translateY(0%)` },
  exiting: { opacity: 0, transform: `translateY(100%)` },
  exited: { opacity: 0, transform: `translateY(100%)` }
}

const UpdateButton = ({
  inProp,
  isSubmitting
}) => {
  // const [inProp, setInProp] = useState(false);
  return (
    <div>
      <Transition in={inProp} timeout={500}>
        {state => (
          <Box
            position='fixed'
            boxShadow={{ base:'xl', lg:'sm' }}
            bottom={0}
            right={0}
            left={{ base: 0, lg: '320px' }}
            p={{ base:4, lg:8 }}
            bg='whiteAlpha.900'
            zIndex='banner'
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}>
            <Center>
              <Text
                mb={2}
                color='gray.600'
                fontSize='sm'
              >
                Vous venez d'apporter des modifications à votre profil, pensez à sauvegarder les mises à jour quand vous aurez terminé sans quoi elles seraient perdues.
            </Text>
            </Center>
            <Center>

              <Button
                isLoading={ isSubmitting }
                borderRadius='full'
                bg='brand.1.500'
                color='white'
                _hover={{ bg: 'brand.1.600' }}
                type='submit'
              >
                Sauvegardez vos informations
              </Button>
            </Center>
          </Box>
        )}
      </Transition>
      {/* <button onClick={() => setInProp( !inProp)}>
        Click to Enter
      </button> */}
    </div>
  );
}

export default UpdateButton