import { useAuth } from "../Context/Auth"

export const LoginForm = () => {
    const { user, googleSignIn, adeSignIn } = useAuth();
    return (
        <>
            Login Form: <br />
            <input type="text" />
            <input type="password" />
            <hr />
            <button onClick={() => googleSignIn()}>Login with Google</button>
            <button onClick={() => adeSignIn()}>Login with ADE</button>
        </>
    )
}