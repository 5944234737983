import { Box, Button, Divider, Heading, Link, Stack, Text } from '@chakra-ui/react'
import { navigate } from '@reach/router'
import { render } from '@testing-library/react'
import axios from 'axios'
import React, { Component } from 'react'
import GenericLayout from '../Layouts/generic-layout'
import { LegalPageViewer } from '../LegalPageViewer'
import TeacherForm from './Form'
import TeacherProfileForm from './FormBase'
import TeacherProfileDetailsForm from './FormDetails'

class TeacherProfileFormLoader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            initialValues: null,
            isProfilLoaded: false,
            isError: false,
            student: {},
            data: {},
            settings: false,
            legalPageViewerVisible: false
        }
    }


    componentDidMount() {
        const _this = this

        axios.get(
            `${process.env.REACT_APP_API_URL}/inscription_farce.php/teacher/${this.props.id}`
        )
            .then(
                (response) => {

                    if (response.data.status === 'completed') {
                        navigate(`/${this.props.lang}/teacher/profile/thankyou/${this.props.id}`)
                    }

                    if (response.data) {

                        _this.setState({
                            settings: response.data,
                            initialValues: response.data,
                            isProfilLoaded: true,
                            isError: false
                        })
                    } else {
                        _this.setState({
                            isError: true
                        })
                    }

                },
                (error) => {
                    _this.setState({
                        isError: true
                    })
                    console.log(error)
                }
            )
    }

    render() {
        const { lang, step, id } = this.props
        const { settings, legalPageViewerVisible } = this.state
        return (
            <>

                <GenericLayout
                    locale={lang}
                    displayNav={step === 1}
                >
                    <LegalPageViewer
                        isVisible={legalPageViewerVisible}
                        documentUrl={settings ? settings.school.legalNoticeUrl : null}
                        handleClose={() => this.setState({ legalPageViewerVisible: false })}
                    />
                    <Box
                        px={{
                            base: 4, lg: 0
                        }}
                    >
                        <Stack spacing={{ base: 6, lg: 10 }} my={{ base: 4, lg: 10 }}>
                            <Heading
                                my={{ base: 2, lg: 2 }}
                                color='gray.800'
                            >
                                Votre profil formateur
                            </Heading>
                            <Stack>
                                <Text>
                                    Pour faciliter la gestion administrative de vos échanges avec les écoles du Groupe ADE, nous vous invitons à renseigner votre profil de façon détaillée.
                                </Text>
                                <Text>
                                    Pas d’inquiétude, vous pouvez compléter votre profil en plusieurs fois.
                                </Text>
                            </Stack>
                            <Divider />
                        </Stack>

                        {settings ?
                            <TeacherForm id={id} settings={settings} />
                            : null
                        }

                        {/* {settings ?
                            step === 1 ?
                                <TeacherProfileForm id={id} settings={settings} />
                                : step === 2 ?
                                    <TeacherProfileDetailsForm id={id} settings={settings} />
                                    : null


                            : null} */}


                        {settings.school ?
                            <Box
                                as='footer'
                                color='gray.400'
                                py={10}
                                px={{ base: 4, lg: 0 }}
                            >
                                <Stack>
                                    <Text>
                                        Merci d’avoir complété votre profil.
                                    </Text>
                                    <Text>
                                        S’il vous manque des informations à renseigner ou des documents à charger, vous pourrez revenir sur votre profil plus tard.
                                    </Text>
                                    <Text>
                                        Si vous rencontrez des difficultés à remplir ce formulaire, n’hésitez pas à nous contacter :{' '}

                                        {settings.school.title} au{' '}
                                        <Link color='gray.500' textDecoration='none' target='_blank' href={`tel:${settings.school.phone.replace(/ /g, "")}`}>{settings.school.phone}</Link>
                                        {' '}et/ou <Link color='gray.500' textDecoration='none' target='_blank' href={`mailto:${settings.school.email}`}>{settings.school.email}</Link>
                                    </Text>

                                    {settings.school.legalNoticeUrl}

                                    {settings.school.legalNoticeUrl ?
                                        <Text>
                                            <Button
                                                variant='link'
                                                documentUrl={settings.school.legalNoticeUrl}
                                                onClick={
                                                    () => this.setState({ legalPageViewerVisible: true })
                                                }
                                            >
                                                Mentions légales
                                            </Button>
                                        </Text>
                                        : null}
                                </Stack>
                            </Box>
                            : null}



                    </Box>
                </GenericLayout>
            </>
        )
    }
}

export default TeacherProfileFormLoader