import React from 'react'

import {
    Box,
    Link,
    Stack,
    Text
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import Debug from './Form/debug'

const Footer = ({ data, apiData, locale }) => {
    return (
        <Box
            as='footer'
            borderTop='solid 1px'
            borderTopColor='gray.100'
            color='gray.400'
            py={10}
            px={{ base: 4, lg: 0 }}
        >
            <Stack>
                {
                    locale === 'fr' ?
                        <Text>
                            Si vous rencontrez des difficultés à remplir ce formulaire, n’hésitez pas à nous contacter :
                            {' '}<Link color='gray.500' textDecoration='none' href={`mailto:${data.school.email}`}>{data.school.email}</Link>
                            {' '}et/ou
                            {' '}<Link color='gray.500' textDecoration='none' href={`tel:${data.school.phone.replace(/ /g, "")}`}>{data.school.phone}</Link>.
                        </Text>
                        :
                        <Text>
                            If you have any issue while filling this form, do not hesitate do contact us :
                            {' '}<Link color='gray.500' textDecoration='none' href={`mailto:${data.school.email}`}>{data.school.email}</Link>
                            {' '}and/or
                            {' '}<Link color='gray.500' textDecoration='none' href={`tel:${data.school.phone.replace(/ /g, "")}`}>{data.school.phone}</Link>.
                        </Text>
                }
                {
                    apiData && apiData.scheduleFileUrl ?
                        locale === 'fr' ?
                            <Text>
                                Vous pouvez consulter également <Link textDecor='underline' color='gray.500' href={apiData.scheduleFileUrl} target='_blank'>l’échéancier de paiement des frais de scolarité via ce lien</Link>.
                            </Text>
                            :
                            <Text>
                                You can also consult <Link textDecor='underline' color='gray.500' href={apiData.scheduleFileUrl} target='_blank'>the tuition fee payment schedule via this link</Link>.
                            </Text>
                        : null
                }
            </Stack>
        </Box>
    )
}

export default Footer