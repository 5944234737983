import getAge from '../../Utils/getAge'

const isStepButtonEnable = (step, errors, values) => {

    // console.log(
    //     errors.firstName,
    //     errors.lastName,
    //     errors.birthDate,
    //     errors.postalCode,
    //     errors.city,
    //     errors.country,
    //     errors.address,
    //     errors.phone,
    //     errors.gender,
    //     errors.profilePictFileCropped,
    //     errors.idCardPictFile,
    //     values.firstName,
    //     values.lastName,
    //     values.birthPlace_country,
    //     values.birthPlace_postalCode,
    //     values.birthPlace_city,
    //     values.birthDate,
    //     values.gender,
    //     values.profilePictFileCropped,
    //     values.idCardPictFile,
    // )

    if (step === 0) {
        return true
    }

    if (step === 1) {
        if (
            !errors.firstName
            && !errors.lastName
            && !errors.birthDate
            && !errors.postalCode
            && !errors.city
            && !errors.country
            && !errors.address
            && !errors.phone
            && !errors.gender
            && !errors.profilePictFileCropped
            && !errors.idCardPictFile
            && !errors.exactInfoCertificate
            && !errors.nationality
            && values.firstName
            && values.lastName
            && values.birthPlace_country
            && values.birthPlace_postalCode
            && values.birthPlace_city
            && values.birthDate
            && values.gender
            && values.profilePictFileCropped
            && values.idCardPictFile
            && values.exactInfoCertificate
            && values.nationality
        ) {
            return true
        }
        else { return false }
    }

    if (step === 2) {
        if (getAge(values.birthDate) < 18) {

            if (
                values.responsibles
                && values.responsibles.length >= 1
                && values.validateResponsibles
                && !errors.responsibles
                && !errors.mailRecipient
            ) {
                return true
            }
            else { return false }

        }
        else {

            if (
                !errors.responsibles
                && values.validateResponsibles
            ) {
                return true
            }
            else { return false }

        }
    }
    // return true
}

export default isStepButtonEnable