import * as React from 'react'
import { Global } from '@emotion/react'

const Fonts = () => (
    <Global styles={`
        // *{ background: red !important}
        @font-face {
            font-family: 'AW Conqueror Didot';
            src: url('/Fonts/AWConquerorDidot-Light.woff2') format('woff2'),
                url('/Fonts/AWConquerorDidot-Light.woff') format('woff');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: 'Gotham';
            src: url('/Fonts/Gotham-Bold.woff2') format('woff2'),
                url('/Fonts/Gotham-Bold.woff') format('woff');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
        }
        
    `}/>
)

export default Fonts