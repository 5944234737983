import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as yup from 'yup'

import { Box, Button, ButtonGroup, Divider, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, Input, Select, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react'
import { Section, SectionLegend, SectionTitle } from './Section'
import FieldBlock from './FieldBlock'
import Debug from '../Form/debug'
//import AddressFields from '../Form/AddressFields'
import UploaderWithCrop from '../Form/UploaderWithCrop'
import BrowseAndJoinDocument from '../Form/BrowseAndJoinDocument'
import PhoneNumberField from '../Form/PhoneNumberField'
import SignModule from '../SignModule'
import UpdateButton from '../Profile/UpdateButton'
import countries from '../../Utils/countries.js'
import Helper from '../Profile/Helper'

import { AddressField } from '../Address/AddressField'
import { handleRecordAdressComponents } from '../Address/handleRecordAdressComponents';
import BrowseAndJoinManyDocuments from '../Form/BrowseAndJoinManyDocuments'
import { RibField } from '../RibField'


const TeacherForm = ({ id, settings }) => {

    const [hasUpdates, setHasUpdates] = useState(false)

    const [billingAddressIsSame, setBillingAddressIsSame] = useState(null)

    const toast = useToast();

    const sortCountries = (a, b) => {
        if (a.libelle_aurion_pays > b.libelle_aurion_pays) return 1;
        if (a.libelle_aurion_pays < b.libelle_aurion_pays) return -1;
    }

    const MandatoryStar = () => (<Text as='sup' color='red.400' fontSize={14}>*</Text>)

    useEffect(() => {
        // Met à jour le titre du document via l’API du navigateur
        if (
            settings.billingAddress.value === ''
            || settings.billingAddress.value === null
        ) {
            setBillingAddressIsSame(true)
        } else {
            setBillingAddressIsSame(false)
        }
    }, []);
    return (
        <Formik
            initialValues={{
                profilePictFileCropped: settings.profilePictFileCropped ? settings.profilePictFileCropped : null,

                firstName: settings.firstName.value ? settings.firstName.value : '',
                lastName: settings.lastName.value ? settings.lastName.value : '',

                birthPlace_postalCode: settings.birthPlace_postalCode && settings.birthPlace_postalCode.value ? settings.birthPlace_postalCode.value : '',
                birthPlace_city: settings.birthPlace_city && settings.birthPlace_city.value ? settings.birthPlace_city.value : '',
                birthPlace_country: settings.birthPlace_country && settings.birthPlace_country.value ? settings.birthPlace_country.value : '',

                // birthPlace: settings.birthPlace && settings.birthPlace.value ? settings.birthPlace.value : 'FRANCE',

                birthDate: settings.birthDate && settings.birthDate.value ? settings.birthDate.value : '',
                nationality: settings.nationality.value ? settings.nationality.value : 'FRANCE',
                linkedInProfileUrl: settings.linkedInProfileUrl.value ? settings.linkedInProfileUrl.value : '',
                gender: settings.gender.value ? settings.gender.value : '',
                address: settings.address.value ? settings.address.value : '',
                postalCode: settings.postalCode.value ? settings.postalCode.value : '',
                city: settings.city.value ? settings.city.value : 'FRANCE',
                country: settings.country.value ? settings.country.value : 'FRANCE',
                phonePrefix: settings.formPrefix && settings.formPrefix.value ? settings.formPrefix.value : '+33',
                phone: settings.phoneNumber.value ? settings.phoneNumber.value : '',

                idCardPictureFile: settings.idCardPictureFile.value ? settings.idCardPictureFile.value : '',

                billingAddress: settings.billingAddress.value ? settings.billingAddress.value : '',
                billingAddress_postalCode: settings.billingAddress_postalCode.value ? settings.billingAddress_postalCode.value : '',
                billingAddress_city: settings.billingAddress_city.value ? settings.billingAddress_city.value : '',
                billingAddress_country: settings.billingAddress_country.value ? settings.billingAddress_country.value : 'FRANCE',
                companyName: settings.companyName.value ? settings.companyName.value : '',
                siretNumber: settings.siretNumber.value ? settings.siretNumber.value : '',
                kbisNumber: settings.kbisNumber.value ? settings.kbisNumber.value : '',
                acceptDigitalSalaryDocument: settings.acceptDigitalSalaryDocument.value || settings.acceptDigitalSalaryDocument.value === false ? settings.acceptDigitalSalaryDocument.value : null,
                acceptDigitalSalaryDocumentEmail: settings.acceptDigitalSalaryDocumentEmail.value ? settings.acceptDigitalSalaryDocumentEmail.value : '',
                acceptDigitalSalaryDocumentSign: settings.acceptDigitalSalaryDocumentSign.value ? settings.acceptDigitalSalaryDocumentSign.value : '',
                socialSecurityNumber: settings.socialSecurityNumber.value ? settings.socialSecurityNumber.value : '',
                residencePermitFile: settings.residencePermitFile.value ? settings.residencePermitFile.value : '',
                curriculumVitaeFile: settings.curriculumVitaeFile.value ? settings.curriculumVitaeFile.value : '',
                ribFile: settings.ribFile.value ? settings.ribFile.value : '',

                ribIban: settings.ribIban && settings.ribIban.value ? settings.ribIban.value : '',
                ribBic: settings.ribBic && settings.ribBic.value ? settings.ribBic.value : '',
                ribBankAddress: settings.ribBankAddress && settings.ribBankAddress.value ? settings.ribBankAddress.value : '',

                socialSecurityCardFile: settings.socialSecurityCardFile.value ? settings.socialSecurityCardFile.value : '',
                proInsuranceCertificateFile: settings.proInsuranceCertificateFile.value ? settings.proInsuranceCertificateFile.value : '',
                socialSecurityCertificateFile: settings.socialSecurityCertificateFile.value ? settings.socialSecurityCertificateFile.value : '',
                urssafCertificateFile: settings.urssafCertificateFile.value ? settings.urssafCertificateFile.value : '',

                qualityChartSign: settings.qualityChartSign.value ? settings.qualityChartSign.value : '',
                imageRightsSign: settings.imageRightsSign.value ? settings.imageRightsSign.value : '',
                itChartSign: settings.itChartSign.value ? settings.itChartSign.value : '',
                schoolChartSign: settings.schoolChartSign && settings.schoolChartSign.value ? settings.schoolChartSign.value : '',

                nonUniqueEmployerCertificateFile: settings.nonUniqueEmployerCertificateFile.value ? settings.nonUniqueEmployerCertificateFile.value : '',


                urssafCertificateFile: settings.urssafCertificateFile.value,
                healthInsuranceFile: settings.healthInsuranceFile.value,
                otherHealthInsuranceFile: settings.otherHealthInsuranceFile.value,
                annualQuoteFile: settings.annualQuoteFile.value,
                urssafWarningCertificateFile: settings.urssafWarningCertificateFile.value,
                courseMapFile: settings.courseMapFile.value,
                criminalRecordExtractFile: settings.criminalRecordExtractFile.value,
                agessaMdaAffiliationCertificateFile: settings.agessaMdaAffiliationCertificateFile.value,
                agessaMdaExemptionCertificateFile: settings.agessaMdaExemptionCertificateFile.value,
                highestDiplomaFiles: settings.highestDiplomaFiles ? settings.highestDiplomaFiles.value : [],
                nonUniqueEmployerCertificateSign: settings.nonUniqueEmployerCertificateSign.value,


            }}
            onSubmit={(values, actions) => {
                axios.patch(
                    `${process.env.REACT_APP_API_URL}/inscription_farce.php/teacher/${id}`,
                    values,
                ).then(() => {
                    actions.setSubmitting(false);
                    setHasUpdates(false);

                    toast({
                        title: "Votre profil a été sauvegardé !",
                        description: "Vous pouvez poursuivre la saisie de vos informations sans oublier de finir par un nouvel enregistrement.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });

                    // this.props.redirectToPayment()
                })
            }}
            validate={(values, props /* only available when using withFormik */) => {
                if (!hasUpdates) {
                    setHasUpdates(true);
                }
            }}
            validateOnChange={true}
            validationSchema={
                yup.object().shape({
                    linkedInProfileUrl: yup
                        .string()
                        .matches(
                            "^(https:\/\/)?(www.)?linkedin.com\/(.*)$",
                            "Cette URL ne semble pas correcte."
                        )
                        .nullable(),
                    birthDate: yup
                        // .date( intl.formatMessage({ id: "required.field" }) )
                        .string()
                        .matches(
                            /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
                            "Le format de date attendu est : AAAA-MM-JJ"
                        )
                        .nullable(),
                })
            }

        >{({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
        }) => (
            <Form>
                <UpdateButton
                    inProp={
                        hasUpdates
                        && Object.entries(errors).length === 0
                    }
                    isSubmitting={isSubmitting}
                />

                {settings.contractType.value !== 'Honoraire_ponctuel' ?
                    <Section id='profilepicture'>
                        <SectionTitle>Photo de profil</SectionTitle>
                        <FormControl>
                            {/* {!values.profilePictFileCropped ? */}
                            <UploaderWithCrop
                                errors={errors}
                                touched={touched}
                                setFieldValue={(value) => {
                                    setFieldTouched('profilePictFileCropped')
                                    setFieldValue('profilePictFileCropped', value)
                                }
                                }

                                initialValue={values.profilePictFileCropped}
                            />
                        </FormControl>
                    </Section>
                    : null}
                <Section id='civility'>
                    <SectionTitle>Civilité</SectionTitle>
                    <FieldBlock
                        isValid={
                            (
                                settings.firstName.isValid
                                && settings.lastName.isValid
                                && settings.gender.isValid
                                && settings.nationality.isValid
                                && settings.birthDate.isValid
                                && settings.birthPlace.isValid
                            )
                        }
                        comment={
                            [
                                settings.firstName.comment
                                && settings.lastName.comment
                                && settings.gender.comment
                                && settings.nationality.comment
                                && settings.birthDate.comment
                                && settings.birthPlace.comment
                            ]
                        }
                    >
                        <Stack spacing={4} >

                            <SimpleGrid
                                columns={{ base: 1, lg: 2 }}
                                gap={{ base: 1, lg: 3 }}
                            >
                                <Field name='firstName'>
                                    {({ field, form }) => (
                                        <FormControl>
                                            <FormLabel>
                                                {settings.firstName.label ? settings.firstName.label : 'Votre prénom'}
                                                <MandatoryStar /></FormLabel>
                                            <Input
                                                {...field}
                                                bg='white'
                                                isDisabled={settings.firstName.isReadonly}
                                            />
                                            <Helper
                                                label={settings.firstName.helpLabel}
                                                pictureFileUrl={settings.firstName.helpPictureFileUrl}
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='lastName'>
                                    {({ field, form }) => (
                                        <FormControl>
                                            <FormLabel>
                                                {settings.lastName.label ? settings.lastName.label : 'Votre nom'}
                                                <MandatoryStar />
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                bg='white'
                                                isDisabled={settings.lastName.isReadonly}
                                            />
                                            <Helper
                                                label={settings.lastName.helpLabel}
                                                pictureFileUrl={settings.lastName.helpPictureFileUrl}
                                            />
                                        </FormControl>
                                    )}

                                </Field>
                            </SimpleGrid>

                            <SimpleGrid
                                columns={{ base: 1, lg: 2 }}
                                gap={{ base: 1, lg: 3 }}
                            >
                                {settings.gender.isEnabled ?
                                    <Field name='gender'>
                                        {({ field, form }) => (
                                            <FormControl>
                                                <FormLabel>
                                                    {settings.gender.label ? settings.gender.label : 'Genre'}
                                                </FormLabel>
                                                <Select
                                                    {...field}
                                                    bg='white'
                                                    placeholder='Sélectionnez'
                                                >
                                                    <option value='F'>Féminin</option>
                                                    <option value='M'>Masculin</option>
                                                    <option value='NB'>Non binaire</option>
                                                </Select>
                                                <Helper
                                                    label={settings.gender.helpLabel}
                                                    pictureFileUrl={settings.gender.helpPictureFileUrl}
                                                />
                                            </FormControl>
                                        )}
                                    </Field>
                                    : null}

                                {settings.nationality.isEnabled ?
                                    <Field name='nationality'>
                                        {({ field, form }) => (
                                            <FormControl>
                                                <FormLabel>
                                                    {settings.nationality.label ? settings.nationality.label : 'Nationalité'}
                                                    <MandatoryStar />
                                                </FormLabel>
                                                <Select {...field} bg='white' placeholder='Sélectionnez'>
                                                    {countries.sort(sortCountries).map(country =>
                                                        <option
                                                            key={country.libelle_aurion_pays}
                                                        >{country.libelle_aurion_pays}</option>)}
                                                </Select>
                                                <Helper
                                                    label={settings.nationality.helpLabel}
                                                    pictureFileUrl={settings.nationality.helpPictureFileUrl}
                                                />
                                            </FormControl>
                                        )}
                                    </Field>
                                    : null}
                            </SimpleGrid>


                            <Box>
                                <Field name='birthDate'>
                                    {({ field, form }) => (
                                        <FormControl isInvalid={touched.birthDate && errors.birthDate}>
                                            <FormLabel>
                                                {settings.birthDate.label ? settings.birthDate.label : 'Date de naissance'}
                                                <MandatoryStar />
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                type='date'
                                                bg='white'
                                            />
                                            <FormErrorMessage>{errors.birthDate}</FormErrorMessage>
                                            <Helper
                                                label={settings.birthDate.helpLabel}
                                                pictureFileUrl={settings.birthDate.helpPictureFileUrl}
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                            </Box>


                            <SimpleGrid
                                columns={{ base: 1, lg: 2 }}
                                gap={{ base: 1, lg: 3 }}
                            >
                                <Box>
                                    <Field name={`birthPlace_city`}>
                                        {({ field, form, meta }) => (
                                            <FormControl>

                                                <FormLabel htmlFor="birthPlace_city">
                                                    {/* <FormattedMessage id="label.birthplace" /> */}
                                                    Ville de naissance
                                                    <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                    <Input {...field} />
                                                </FormLabel>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                <Box>
                                    <Field name={`birthPlace_postalCode`}>
                                        {({ field, form, meta }) => (
                                            <FormControl>
                                                <FormLabel htmlFor="birthPlace_postalCode">
                                                    {/* <FormattedMessage id="label.birthplace" /> */}
                                                    Code postal du lieu naissance
                                                    <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                    <Input {...field} />
                                                </FormLabel>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                <Box>
                                    <Field name={`birthPlace_country`}>
                                        {({ field, form, meta }) => (
                                            <FormControl>
                                                <FormLabel htmlFor="birthPlace_country">
                                                    {/* <FormattedMessage id="label.birthplace" /> */}
                                                    Pays du lieu naissance
                                                    <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                    <Input {...field} />
                                                </FormLabel>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>

                            </SimpleGrid>

                        </Stack>
                    </FieldBlock>

                </Section>

                <Section id='contact'>
                    <SectionTitle>Coordonnées</SectionTitle>
                    <FieldBlock
                        isValid={
                            (
                                settings.phoneNumber.isValid
                            )
                        }
                        comment={
                            [
                                settings.phoneNumber.comment
                            ]
                        }
                    >
                        <Field name='phoneNumber'>
                            {({ field, form }) => (
                                <FormControl>
                                    <FormLabel>
                                        {settings.phoneNumber.label ? settings.phoneNumber.label : 'Numéro de téléphone portable'}
                                        <MandatoryStar />
                                    </FormLabel>
                                    <PhoneNumberField
                                        {...field}
                                        phone={values.phone}
                                        phonePrefix={values.phonePrefix}
                                        setPhoneValue={
                                            (phone) => {
                                                setFieldValue(`phone`, phone, true, true)
                                                setFieldTouched(`phone`, true, true)
                                            }
                                        }
                                        setPhonePrefixValue={
                                            (prefix) => {
                                                setFieldValue(`phonePrefix`, prefix, true, true)
                                                setFieldTouched(`phonePrefix`, true, true)
                                            }
                                        }
                                    />
                                    <Helper
                                        label={settings.phoneNumber.helpLabel}
                                        pictureFileUrl={settings.phoneNumber.helpPictureFileUrl}
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </FieldBlock>

                    <FieldBlock>
                        <Field name={`address`}>
                            {({ field, form, meta }) => (
                                <FormControl mb={4}>
                                    <FormLabel htmlFor="address">
                                        Addresse
                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                        <Input {...field} />
                                    </FormLabel>
                                </FormControl>
                            )}
                        </Field>
                        <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>

                            <Field name={`city`}>
                                {({ field, form, meta }) => (
                                    <FormControl>
                                        <FormLabel htmlFor="city">
                                            Ville
                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                            <Input {...field} />
                                        </FormLabel>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={`postalCode`}>
                                {({ field, form, meta }) => (
                                    <FormControl>
                                        <FormLabel htmlFor="postalCode">
                                            Code postal
                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                            <Input {...field} />
                                        </FormLabel>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={`country`}>
                                {({ field, form, meta }) => (
                                    <FormControl>
                                        <FormLabel htmlFor="country">
                                            Pays
                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                            <Input {...field} />
                                        </FormLabel>
                                    </FormControl>
                                )}
                            </Field>
                        </SimpleGrid>
                    </FieldBlock>

                </Section>

                {
                    settings.curriculumVitaeFile.isEnabled
                        || settings.linkedInProfileUrl.isEnabled
                        || settings.highestDiplomaFiles.isEnabled
                        ?

                        <Section id='diplomas'>
                            <SectionTitle>Profil et parcours</SectionTitle>
                            <SectionLegend> Dans le cadre de notre démarche qualité, nous avons besoin de votre CV, ou du lien de votre page LinkedIn. Vous devez remplir au moins l’un des deux champs (vous pouvez bien entendu remplir les deux si vous souhaitez).</SectionLegend>
                            <FieldBlock
                                isValid={
                                    (
                                        settings.curriculumVitaeFile.isValid
                                        && settings.linkedInProfileUrl.isValid
                                    )
                                }
                                comment={
                                    [
                                        settings.curriculumVitaeFile.comment,
                                        settings.linkedInProfileUrl.comment
                                    ]
                                }
                            >
                                {
                                    settings.curriculumVitaeFile.isEnabled
                                        || settings.linkedInProfileUrl.isEnabled
                                        ?
                                        <SimpleGrid
                                            columns={{ base: 1, lg: 1 }}
                                            gap={{ base: 1, lg: 3 }}
                                        >
                                            {settings.curriculumVitaeFile.isEnabled ?
                                                <Field name='curriculumVitaeFile'>
                                                    {({ field, form }) => (
                                                        <FormControl>
                                                            <FormLabel>
                                                                {settings.curriculumVitaeFile.label ? settings.curriculumVitaeFile.label : 'Votre cv'}
                                                            </FormLabel>
                                                            <BrowseAndJoinDocument
                                                                fieldName='curriculumVitaeFile'
                                                                uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                                value={values.curriculumVitaeFile}
                                                                previewUrl={settings.curriculumVitaeFile}
                                                                setFieldValue={(value) => {
                                                                    setFieldValue('curriculumVitaeFile', value);
                                                                    setHasUpdates(true);
                                                                }}
                                                                isDisabled={settings.curriculumVitaeFile.isValid}
                                                                isValid={settings.curriculumVitaeFile.isValid}
                                                                userId={id}
                                                            />
                                                            <Helper
                                                                label={settings.curriculumVitaeFile.helpLabel}
                                                                pictureFileUrl={settings.curriculumVitaeFile.helpPictureFileUrl}
                                                            />
                                                        </FormControl>

                                                    )}

                                                </Field>
                                                : null}

                                            {settings.linkedInProfileUrl.isEnabled ?
                                                <Field name='linkedInProfileUrl'>
                                                    {({ field, form }) => (
                                                        <FormControl isInvalid={touched.linkedInProfileUrl && errors.linkedInProfileUrl}>
                                                            <FormLabel>
                                                                {settings.linkedInProfileUrl.label ? settings.linkedInProfileUrl.label : 'Votre page LinkedIn'}
                                                            </FormLabel>
                                                            <Input {...field} placeholder='https://linkedin.com/...' bg='color' />
                                                            <FormErrorMessage>{errors.linkedInProfileUrl}</FormErrorMessage>
                                                            <Helper
                                                                label={settings.linkedInProfileUrl.helpLabel}
                                                                pictureFileUrl={settings.linkedInProfileUrl.helpPictureFileUrl}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </Field>
                                                : null}

                                        </SimpleGrid>
                                        : null}

                                {settings.highestDiplomaFiles.isEnabled ?
                                    <FormControl mt={6}>
                                        <FormLabel>
                                            {settings.highestDiplomaFiles.label ? settings.highestDiplomaFiles.label : 'Copies de vos diplômes les plus élevés'}
                                        </FormLabel>
                                        <Helper
                                            label={settings.highestDiplomaFiles.helpLabel}
                                            pictureFileUrl={settings.highestDiplomaFiles.helpPictureFileUrl}
                                        />
                                        <BrowseAndJoinManyDocuments
                                            fieldName='highestDiplomaFiles'
                                            uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                            values={values.highestDiplomaFiles}
                                            setFieldValue={(value) => {
                                                setFieldValue('highestDiplomaFiles', value);
                                                setHasUpdates(true);
                                            }}
                                            isDisabled={false}
                                            userId={id}
                                        />
                                    </FormControl>
                                    : null}

                            </FieldBlock>
                        </Section>
                        : null}


                {/* {settings.highestDiplomaFiles.isEnabled ?
                    <Section id='diplomes'>
                        <SectionTitle>Diplômes</SectionTitle>

                    </Section>
                    : null} */}

                <Section id='billing'>
                    <SectionTitle>Facturation et fiche de paie</SectionTitle>
                    <SectionLegend>Veuillez trouver ci-dessous toutes les informations dont nous avons besoin pour la facturation de vos interventions.</SectionLegend>

                    {
                        settings.companyName.isEnabled
                            && settings.siretNumber.isEnabled
                            ?
                            <FieldBlock
                                isValid={
                                    (
                                        settings.companyName.isValid
                                        && settings.siretNumber.isValid
                                        && settings.kbisNumber.isValid
                                    )
                                }
                                comment={
                                    [
                                        settings.companyName.comment,
                                        settings.siretNumber.comment,
                                        settings.kbisNumber.comment
                                    ]
                                }
                            >
                                <SimpleGrid
                                    columns={{ base: 1, lg: 3 }}
                                    gap={{ base: 2, lg: 3 }}
                                >
                                    <Field name='companyName'>
                                        {({ field, form }) => (
                                            <FormControl>
                                                <FormLabel>
                                                    {settings.companyName.label ? settings.companyName.label : `Nom de l'entreprise / Raison sociale`}
                                                </FormLabel>
                                                <Input {...field} placeholder='' />
                                                <Helper
                                                    label={settings.companyName.helpLabel}
                                                    pictureFileUrl={settings.companyName.helpPictureFileUrl}
                                                />
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='siretNumber'>
                                        {({ field, form }) => (
                                            <FormControl>
                                                <FormLabel>
                                                    {settings.siretNumber.label ? settings.siretNumber.label : 'Votre n° SIRET ou n° RNA'}
                                                </FormLabel>
                                                <Input {...field} placeholder='' />
                                                <Helper
                                                    label={settings.siretNumber.helpLabel}
                                                    pictureFileUrl={settings.siretNumber.helpPictureFileUrl}
                                                />
                                            </FormControl>
                                        )}
                                    </Field>
                                    {
                                        settings.kbisNumber.isEnabled ?
                                            <Field name='kbisNumber'>
                                                {({ field, form }) => (
                                                    <FormControl>
                                                        <FormLabel>
                                                            {settings.kbisNumber.label ? settings.kbisNumber.label : 'KBIS'}
                                                        </FormLabel>
                                                        <Input {...field} placeholder='' />
                                                        <Helper
                                                            label={settings.kbisNumber.helpLabel}
                                                            pictureFileUrl={settings.kbisNumber.helpPictureFileUrl}
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            : null}
                                </SimpleGrid>
                            </FieldBlock>

                            : null}

                    {settings.billingAddress.isEnabled ?
                        <FieldBlock>
                            <Stack spacing={4}>
                                <FormLabel>Votre adresse de facturation est-elle la même que votre adresse personnelle ?</FormLabel>
                                <ButtonGroup>
                                    <Button
                                        colorScheme={billingAddressIsSame ? 'blue' : 'gray'}
                                        onClick={() => {
                                            setBillingAddressIsSame(true);
                                            setFieldValue('billingAddress', '')
                                            setFieldValue('billingAddress_city', '')
                                            setFieldValue('billingAddress_postalCode', '')
                                            setFieldValue('billingAddress_country', '')
                                        }}
                                    >
                                        Oui
                                    </Button>
                                    <Button colorScheme={billingAddressIsSame === false ? 'blue' : 'gray'} onClick={() => setBillingAddressIsSame(false)}>Non</Button>
                                </ButtonGroup>
                                {billingAddressIsSame === false ?
                                    <>
                                        <FormControl>
                                            <FormLabel>
                                                {settings.billingAddress.label ? settings.billingAddress.label : 'Adresse de facturation'}
                                            </FormLabel>
                                            <AddressField
                                                initialValue={`${values.billingAddress}`}
                                                newAddressCallback={
                                                    (adressObject) => {
                                                        handleRecordAdressComponents(
                                                            adressObject,
                                                            setFieldValue,
                                                            setFieldTouched,
                                                            `billingAddress_`,
                                                            `billingAddress`
                                                        )
                                                    }
                                                }
                                            />
                                            <Helper
                                                label={settings.billingAddress.helpLabel}
                                                pictureFileUrl={settings.billingAddress.helpPictureFileUrl}
                                            />
                                        </FormControl>
                                    </>
                                    : null}
                            </Stack>
                        </FieldBlock>
                        : null}

                    {settings.ribIban && settings.ribIban.isEnabled ?
                        <FieldBlock>
                            <FormLabel>{settings.ribIban.label}</FormLabel>
                            <RibField setHasUpdates={setHasUpdates} />
                            <Helper
                                label={settings.ribIban.helpLabel}
                                pictureFileUrl={settings.ribIban.helpPictureFileUrl}
                            />
                        </FieldBlock> : null
                    }

                    {settings.ribFile.isEnabled ?
                        <FieldBlock
                            isValid={settings.ribFile.isValid}
                            comment={[settings.ribFile.comment]}
                        >
                            <FormControl
                                mt={2}
                                isInvalid={false}
                            >
                                <FormLabel>
                                    {settings.ribFile.label ? settings.ribFile.label : 'RIB'}
                                </FormLabel>
                                <BrowseAndJoinDocument
                                    fieldName={`ribFile`}
                                    uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                    value={values.ribFile}
                                    previewUrl={settings.ribFile}
                                    userId={id}
                                    isDisabled={settings.ribFile.isValid}
                                    isValid={settings.ribFile.isValid}
                                    setFieldValue={(value) => {
                                        setFieldTouched('ribFile', true, true);
                                        setFieldValue('ribFile', value);
                                        setHasUpdates(true);
                                    }}
                                />
                                <Helper
                                    label={settings.ribFile.helpLabel}
                                    pictureFileUrl={settings.ribFile.helpPictureFileUrl}
                                />
                            </FormControl>
                        </FieldBlock>
                        : null}

                    {settings.acceptDigitalSalaryDocument.isEnabled ?
                        <FieldBlock
                            isValid={settings.acceptDigitalSalaryDocument.isValid}
                            comment={[settings.acceptDigitalSalaryDocument.comment]}
                        >
                            <FormLabel>
                                {settings.acceptDigitalSalaryDocument.label ? settings.acceptDigitalSalaryDocument.label : "Êtes-vous d'accord pour la dématérialisation de vos bulletins de salaire ?"}
                            </FormLabel>
                            <ButtonGroup>
                                <HStack>
                                    <Button
                                        onClick={() => setFieldValue('acceptDigitalSalaryDocument', true)}
                                        colorScheme={
                                            values.acceptDigitalSalaryDocument ? 'blue' : 'gray'
                                        }
                                    >Oui</Button>
                                    <Button
                                        onClick={() => {
                                            setFieldValue('acceptDigitalSalaryDocument', false);
                                            setFieldValue('acceptDigitalSalaryDocumentSign', null);
                                            setFieldValue('acceptDigitalSalaryDocumentEmail', null);
                                        }}
                                        colorScheme={
                                            values.acceptDigitalSalaryDocument === false ? 'blue' : 'gray'
                                        }
                                    >Non</Button>
                                </HStack>
                            </ButtonGroup>

                            {values.acceptDigitalSalaryDocument ?
                                <SimpleGrid
                                    columns={2}
                                    gap={4}
                                    mt={6}
                                >

                                    <Field name='acceptDigitalSalaryDocumentEmail'>
                                        {({ field, form }) => (
                                            <FormControl>
                                                <FormLabel>
                                                    {settings.acceptDigitalSalaryDocumentEmail.label ? settings.acceptDigitalSalaryDocumentEmail.label : `L'adresse email de réception de votre bulletin de paie`}
                                                    <MandatoryStar />
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    bg='white'

                                                />
                                                <Helper
                                                    label={settings.acceptDigitalSalaryDocumentEmail.helpLabel}
                                                    pictureFileUrl={settings.acceptDigitalSalaryDocumentEmail.helpPictureFileUrl}
                                                />
                                            </FormControl>
                                        )}
                                    </Field>

                                    <FormControl>
                                        <FormLabel>
                                            Signez l'attestation de dématérialisation
                                        </FormLabel>
                                        <SignModule
                                            value={values.acceptDigitalSalaryDocumentSign}
                                            firstName={values.firstName}
                                            lastName={values.lastName}
                                            documentUrl={settings.acceptDigitalSalaryDocumentUrl}
                                            handleSign={
                                                (value) => {
                                                    setFieldValue('acceptDigitalSalaryDocumentSign', value)
                                                }
                                            }
                                        />
                                    </FormControl>

                                </SimpleGrid>
                                : null}
                        </FieldBlock> : null}

                </Section>


                {
                    settings.idCardPictureFile.isEnabled
                        || settings.residencePermitFile.isEnabled
                        || settings.socialSecurityNumber.isEnabled
                        || settings.socialSecurityCardFile.isEnabled
                        || settings.proInsuranceCertificateFile.isEnabled
                        || settings.socialSecurityCertificateFile.isEnabled
                        || settings.urssafCertificateFile.isEnabled
                        ?

                        <Section id='documents'>
                            <SectionTitle>Documents et informations administratives</SectionTitle>
                            <SectionLegend>Nous avons besoin des documents ci-dessous dans le cadre de notre collaboration.</SectionLegend>
                            <SimpleGrid
                                columns={{ base: 1, lg: 2 }}
                                gap={{ base: 4, lg: 3 }}
                            >
                                {
                                    settings.idCardPictureFile.isEnabled ?
                                        <FieldBlock
                                            isValid={settings.idCardPictureFile.isValid}
                                            comment={[settings.idCardPictureFile.comment]}
                                        >
                                            <FormControl
                                                mt={2}
                                                isInvalid={false}
                                            >
                                                <FormLabel>
                                                    {settings.idCardPictureFile.label ? settings.idCardPictureFile.label : 'Pièce d\'identité'}
                                                </FormLabel>
                                                <BrowseAndJoinDocument
                                                    fieldName={`idCardPictureFile`}
                                                    uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                    value={values.idCardPictureFile}
                                                    previewUrl={settings.idCardPictureFile}
                                                    isDisabled={settings.idCardPictureFile.isValid}
                                                    isValid={settings.idCardPictureFile.isValid}
                                                    userId={id}
                                                    setFieldValue={(value) => {
                                                        setFieldTouched('idCardPictureFile', true, true);
                                                        setFieldValue('idCardPictureFile', value);
                                                        setHasUpdates(true);
                                                    }}
                                                />
                                                <Helper
                                                    label={settings.idCardPictureFile.helpLabel}
                                                    pictureFileUrl={settings.idCardPictureFile.helpPictureFileUrl}
                                                />
                                            </FormControl>
                                        </FieldBlock>
                                        : null
                                }
                                {settings.residencePermitFile.isEnabled && values.nationality !== 'FRANCE' ?
                                    <FieldBlock
                                        isValid={settings.residencePermitFile.isValid}
                                        comment={[settings.residencePermitFile.comment]}
                                    >
                                        <FormControl
                                            mt={2}
                                            isInvalid={false}
                                        >
                                            <FormLabel>
                                                {settings.residencePermitFile.label ? settings.residencePermitFile.label : 'Photocopie du titre de séjour en cours de validité et autorisation de travail'}
                                            </FormLabel>
                                            <BrowseAndJoinDocument
                                                fieldName={`residencePermitFile`}
                                                uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                value={values.residencePermitFile}
                                                previewUrl={settings.residencePermitFile}
                                                isDisabled={settings.residencePermitFile.isValid}
                                                isValid={settings.residencePermitFile.isValid}
                                                userId={id}
                                                setFieldValue={(value) => {
                                                    setFieldTouched('residencePermitFile', true, true);
                                                    setFieldValue('residencePermitFile', value);
                                                    setHasUpdates(true);
                                                }}
                                            />
                                            <Helper
                                                label={settings.residencePermitFile.helpLabel}
                                                pictureFileUrl={settings.residencePermitFile.helpPictureFileUrl}
                                            />
                                            <FormHelperText>Uniquement si vous n'êtes pas de nationalité française.</FormHelperText>
                                        </FormControl>
                                    </FieldBlock>
                                    : null}
                            </SimpleGrid>

                            {
                                settings.socialSecurityNumber.isEnabled
                                    || settings.socialSecurityCardFile.isEnabled ?

                                    <FieldBlock
                                        isValid={(
                                            settings.socialSecurityNumber.isValid
                                            && settings.socialSecurityCardFile.isValid
                                        )}
                                        comment={[
                                            settings.socialSecurityNumber.comment,
                                            settings.socialSecurityCardFile.comment
                                        ]}
                                    >
                                        <SimpleGrid
                                            columns={{ base: 1, lg: 2 }}
                                            gap={{ base: 2, lg: 6 }}
                                        >
                                            {settings.socialSecurityNumber.isEnabled ?
                                                <Field name='socialSecurityNumber'>
                                                    {({ field, form }) => (
                                                        <FormControl>
                                                            <FormLabel>
                                                                {settings.socialSecurityNumber.label ? settings.socialSecurityNumber.label : 'Numéro de sécurité sociale :'}
                                                            </FormLabel>
                                                            <Input {...field} placeholder='' maxW={{ base: '100%', lg: '75%' }} />
                                                            <Helper
                                                                label={settings.socialSecurityNumber.helpLabel}
                                                                pictureFileUrl={settings.socialSecurityNumber.helpPictureFileUrl}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </Field> : null}

                                            {settings.socialSecurityCardFile.isEnabled ?
                                                <FormControl
                                                    mt={2}
                                                    isInvalid={false}
                                                >
                                                    <FormLabel>
                                                        {settings.socialSecurityCardFile.label ? settings.socialSecurityCardFile.label : 'Carte vitale ou attestation de carte vitale '}
                                                    </FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName={`socialSecurityCardFile`}
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        value={values.socialSecurityCardFile}
                                                        previewUrl={settings.socialSecurityCardFile}
                                                        isDisabled={settings.socialSecurityCardFile.isValid}
                                                        isValid={settings.socialSecurityCardFile.isValid}
                                                        userId={id}
                                                        setFieldValue={(value) => {
                                                            setFieldTouched('socialSecurityCardFile', true, true);
                                                            setFieldValue('socialSecurityCardFile', value);
                                                            setHasUpdates(true);
                                                        }}
                                                    />
                                                    <Helper
                                                        label={settings.socialSecurityCardFile.helpLabel}
                                                        pictureFileUrl={settings.socialSecurityCardFile.helpPictureFileUrl}
                                                    />
                                                </FormControl> : null}
                                        </SimpleGrid>
                                    </FieldBlock>
                                    : null}

                            {
                                settings.proInsuranceCertificateFile.isEnabled
                                    || settings.socialSecurityCertificateFile.isEnabled
                                    || settings.urssafCertificateFile.isEnabled
                                    ?

                                    <FieldBlock
                                        isValid={
                                            settings.proInsuranceCertificateFile.isValid
                                            && settings.socialSecurityCertificateFile.isValid
                                            && settings.urssafCertificateFile.isValid
                                        }
                                        comment={[
                                            settings.proInsuranceCertificateFile.comment,
                                            settings.socialSecurityCertificateFile.comment,
                                            settings.urssafCertificateFile.comment,
                                        ]}
                                    >
                                        <SimpleGrid
                                            columns={{ base: 1, lg: 3 }}
                                            gap={{ base: 4, lg: 3 }}
                                        >
                                            {
                                                settings.proInsuranceCertificateFile.isEnabled ?
                                                    <FormControl
                                                        mt={2}
                                                        isInvalid={false}
                                                    >
                                                        <FormLabel minH={{ base: 'auto', lg: '48px' }}>
                                                            {settings.proInsuranceCertificateFile.label ? settings.proInsuranceCertificateFile.label : 'Attestion de Responsabilité professionnelle'}
                                                        </FormLabel>
                                                        <BrowseAndJoinDocument
                                                            fieldName={`proInsuranceCertificateFile`}
                                                            uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                            value={values.proInsuranceCertificateFile}
                                                            previewUrl={settings.proInsuranceCertificateFile}
                                                            isDisabled={settings.proInsuranceCertificateFile.isValid}
                                                            isValid={settings.proInsuranceCertificateFile.isValid}
                                                            userId={id}
                                                            setFieldValue={(value) => {
                                                                setFieldTouched('proInsuranceCertificateFile', true, true);
                                                                setFieldValue('proInsuranceCertificateFile', value);
                                                                setHasUpdates(true);
                                                            }
                                                            }
                                                        />
                                                        <Helper
                                                            label={settings.proInsuranceCertificateFile.helpLabel}
                                                            pictureFileUrl={settings.proInsuranceCertificateFile.helpPictureFileUrl}
                                                        />
                                                    </FormControl>
                                                    : null
                                            }

                                            {
                                                settings.socialSecurityCertificateFile.isEnabled ?
                                                    <FormControl
                                                        mt={2}
                                                        isInvalid={false}
                                                    >
                                                        <FormLabel minH={{ base: 'auto', lg: '48px' }}>
                                                            {settings.socialSecurityCertificateFile.label ? settings.socialSecurityCertificateFile.label : 'Statut ou attestation de sécurité sociale'}
                                                        </FormLabel>
                                                        <BrowseAndJoinDocument
                                                            fieldName={`socialSecurityCertificateFile`}
                                                            value={values.socialSecurityCertificateFile}
                                                            previewUrl={settings.socialSecurityCertificateFile}
                                                            isDisabled={settings.socialSecurityCertificateFile.isValid}
                                                            isValid={settings.socialSecurityCertificateFile.isValid}
                                                            uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                            userId={id}
                                                            setFieldValue={(value) => {
                                                                setFieldTouched('socialSecurityCertificateFile', true, true);
                                                                setFieldValue('socialSecurityCertificateFile', value);
                                                                setHasUpdates(true);
                                                            }
                                                            }
                                                        />
                                                        <Helper
                                                            label={settings.socialSecurityCertificateFile.helpLabel}
                                                            pictureFileUrl={settings.socialSecurityCertificateFile.helpPictureFileUrl}
                                                        />
                                                    </FormControl>
                                                    : null
                                            }

                                            {
                                                settings.urssafCertificateFile.isEnabled ?
                                                    <FormControl
                                                        mt={2}
                                                        isInvalid={false}
                                                    >
                                                        <FormLabel minH={{ base: 'auto', lg: '48px' }}>
                                                            {settings.urssafCertificateFile.label ? settings.urssafCertificateFile.label : 'L’attestation d’affiliation à l’URSSAF'}
                                                        </FormLabel>
                                                        <BrowseAndJoinDocument
                                                            fieldName={`urssafCertificateFile`}
                                                            uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                            isDisabled={settings.urssafCertificateFile.isValid}
                                                            isValid={settings.urssafCertificateFile.isValid}
                                                            value={values.urssafCertificateFile}
                                                            previewUrl={settings.urssafCertificateFile}
                                                            userId={id}
                                                            setFieldValue={(value) => {
                                                                setFieldTouched('urssafCertificateFile', true, true);
                                                                setFieldValue('urssafCertificateFile', value);
                                                                setHasUpdates(true);
                                                            }
                                                            }
                                                        />
                                                        <Helper
                                                            label={settings.urssafCertificateFile.helpLabel}
                                                            pictureFileUrl={settings.urssafCertificateFile.helpPictureFileUrl}
                                                        />
                                                    </FormControl>
                                                    : null
                                            }
                                        </SimpleGrid>

                                    </FieldBlock>
                                    : null}

                            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={{ base: 4, lg: 6 }}>

                                {settings.healthInsuranceFile.isEnabled ?
                                    <Field name="healthInsuranceFile">
                                        {({ field, form }) => (
                                            <FieldBlock
                                                isValid={settings.healthInsuranceFile.isValid}
                                                comment={[settings.healthInsuranceFile.comment]}
                                            >
                                                <FormControl>
                                                    <FormLabel>
                                                        {settings.healthInsuranceFile.label ? settings.healthInsuranceFile.label : 'Mutuelle'}
                                                    </FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName='healthInsuranceFile'
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        isDisabled={settings.healthInsuranceFile.isValid}
                                                        isValid={settings.healthInsuranceFile.isValid}
                                                        value={values.healthInsuranceFile}
                                                        previewUrl={settings.healthInsuranceFile}
                                                        setFieldValue={(value) => {
                                                            setFieldValue('healthInsuranceFile', value);
                                                            setHasUpdates(true);
                                                        }}
                                                        userId={id}
                                                    />
                                                    <Helper
                                                        label={settings.healthInsuranceFile.helpLabel}
                                                        pictureFileUrl={settings.healthInsuranceFile.helpPictureFileUrl}
                                                    />
                                                </FormControl>

                                            </FieldBlock>
                                        )}
                                    </Field>
                                    : null}

                                {settings.otherHealthInsuranceFile.isEnabled ?
                                    <Field name="otherHealthInsuranceFile">
                                        {({ field, form }) => (
                                            <FieldBlock
                                                isValid={settings.otherHealthInsuranceFile.isValid}
                                                comment={[settings.otherHealthInsuranceFile.comment]}
                                            >
                                                <FormControl>
                                                    <FormLabel>
                                                        {settings.otherHealthInsuranceFile.label ? settings.otherHealthInsuranceFile.label : 'Prévoyance'}
                                                    </FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName='otherHealthInsuranceFile'
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        value={values.otherHealthInsuranceFile}
                                                        previewUrl={settings.otherHealthInsuranceFile}
                                                        setFieldValue={(value) => {
                                                            setFieldValue('otherHealthInsuranceFile', value);
                                                            setHasUpdates(true);
                                                        }}
                                                        isDisabled={settings.otherHealthInsuranceFile.isValid}
                                                        isValid={settings.otherHealthInsuranceFile.isValid}
                                                        userId={id}
                                                    />
                                                    <Helper
                                                        label={settings.otherHealthInsuranceFile.helpLabel}
                                                        pictureFileUrl={settings.otherHealthInsuranceFile.helpPictureFileUrl}
                                                    />
                                                </FormControl>

                                            </FieldBlock>
                                        )}
                                    </Field>
                                    : null}

                                {settings.annualQuoteFile.isEnabled ?
                                    <Field name="annualQuote">
                                        {({ field, form }) => (
                                            <FieldBlock
                                                isValid={settings.annualQuoteFile.isValid}
                                                comment={[settings.annualQuoteFile.comment]}
                                            >
                                                <FormControl>
                                                    <FormLabel>
                                                        {settings.annualQuoteFile.label ? settings.annualQuoteFile.label : 'Devis annuel'}
                                                    </FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName='annualQuoteFile'
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        value={values.annualQuoteFile}
                                                        previewUrl={settings.annualQuoteFile}
                                                        isDisabled={settings.annualQuoteFile.isValid}
                                                        isValid={settings.annualQuoteFile.isValid}
                                                        setFieldValue={(value) => {
                                                            setFieldValue('annualQuoteFile', value);
                                                            setHasUpdates(true);
                                                        }}
                                                        userId={id}
                                                    />
                                                    <Helper
                                                        label={settings.annualQuoteFile.helpLabel}
                                                        pictureFileUrl={settings.annualQuoteFile.helpPictureFileUrl}
                                                    />
                                                </FormControl>

                                            </FieldBlock>
                                        )}
                                    </Field>
                                    : null}

                                {settings.urssafWarningCertificateFile.isEnabled ?
                                    <Field name="urssafWarningCertificateFile">
                                        {({ field, form }) => (
                                            <FieldBlock
                                                isValid={settings.urssafWarningCertificateFile.isValid}
                                                comment={[settings.urssafWarningCertificateFile.comment]}
                                            >
                                                <FormControl>
                                                    <FormLabel>
                                                        {settings.urssafWarningCertificateFile.label ? settings.urssafWarningCertificateFile.label : 'Attestation de vigilance urssaf si votre devis est supérieur à 5000€'}
                                                    </FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName='urssafWarningCertificateFile'
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        value={values.urssafWarningCertificateFile}
                                                        previewUrl={settings.urssafWarningCertificateFile}
                                                        setFieldValue={(value) => {
                                                            setFieldValue('urssafWarningCertificateFile', value);
                                                            setHasUpdates(true);;
                                                        }}
                                                        isDisabled={settings.urssafWarningCertificateFile.isValid}
                                                        isValid={settings.urssafWarningCertificateFile.isValid}
                                                        userId={id}
                                                    />
                                                    <Helper
                                                        label={settings.urssafWarningCertificateFile.helpLabel}
                                                        pictureFileUrl={settings.urssafWarningCertificateFile.helpPictureFileUrl}
                                                    />
                                                </FormControl>

                                            </FieldBlock>
                                        )}
                                    </Field>
                                    : null}

                                {settings.criminalRecordExtractFile.isEnabled ?
                                    <Field name="criminalRecordExtractFile">
                                        {({ field, form }) => (
                                            <FieldBlock
                                                isValid={settings.criminalRecordExtractFile.isValid}
                                                comment={[settings.criminalRecordExtractFile.comment]}
                                            >
                                                <FormControl>
                                                    <FormLabel>
                                                        {settings.criminalRecordExtractFile.label ? settings.criminalRecordExtractFile.label : 'Extrait de casier judiciaire n°3 récent (inférieur à 3 mois)'}
                                                    </FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName='criminalRecordExtractFile'
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        value={values.criminalRecordExtractFile}
                                                        previewUrl={settings.criminalRecordExtractFile}

                                                        setFieldValue={(value) => {
                                                            setFieldValue('criminalRecordExtractFile', value);
                                                            setHasUpdates(true);
                                                        }}
                                                        isDisabled={settings.criminalRecordExtractFile.isValid}
                                                        isValid={settings.criminalRecordExtractFile.isValid}
                                                        userId={id}
                                                    />
                                                    <Helper
                                                        label={settings.criminalRecordExtractFile.helpLabel}
                                                        // label='test'
                                                        // pictureFileUrl={null}
                                                        pictureFileUrl={settings.criminalRecordExtractFile.helpPictureFileUrl}
                                                    />
                                                </FormControl>

                                            </FieldBlock>
                                        )}
                                    </Field>
                                    : null}

                                {settings.agessaMdaAffiliationCertificateFile.isEnabled ?

                                    <Field name="agessaMdaAffiliationCertificateFile">
                                        {({ field, form }) => (
                                            <FieldBlock
                                                isValid={settings.agessaMdaAffiliationCertificateFile.isValid}
                                                comment={[settings.agessaMdaAffiliationCertificateFile.comment]}
                                            >
                                                <FormControl>
                                                    <FormLabel>
                                                        {settings.agessaMdaAffiliationCertificateFile.label ? settings.agessaMdaAffiliationCertificateFile.label : 'Document d’affiliation AGESSA / MDA'}
                                                    </FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName='agessaMdaAffiliationCertificateFile'
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        value={values.agessaMdaAffiliationCertificateFile}
                                                        previewUrl={settings.agessaMdaAffiliationCertificateFile}
                                                        setFieldValue={(value) => {
                                                            setFieldValue('agessaMdaAffiliationCertificateFile', value);
                                                            setHasUpdates(true);
                                                        }}
                                                        isDisabled={settings.agessaMdaAffiliationCertificateFile.isValid}
                                                        isValid={settings.agessaMdaAffiliationCertificateFile.isValid}
                                                        userId={id}
                                                    />
                                                    <Helper
                                                        label={settings.agessaMdaAffiliationCertificateFile.helpLabel}
                                                        pictureFileUrl={settings.agessaMdaAffiliationCertificateFile.helpPictureFileUrl}
                                                    />
                                                </FormControl>
                                            </FieldBlock>
                                        )}
                                    </Field>
                                    : null}

                                {settings.agessaMdaExemptionCertificateFile.isEnabled ?
                                    <Field name="agessaMdaExemptionCertificateFile">
                                        {({ field, form }) => (
                                            <FieldBlock
                                                isValid={settings.agessaMdaExemptionCertificateFile.isValid}
                                                comment={[settings.agessaMdaExemptionCertificateFile.comment]}
                                            >
                                                <FormControl>
                                                    <FormLabel>
                                                        {settings.agessaMdaExemptionCertificateFile.label ? settings.agessaMdaExemptionCertificateFile.label : 'Attestation de dispense MDA ou AGESSA (si concerné)'}</FormLabel>
                                                    <BrowseAndJoinDocument
                                                        fieldName='agessaMdaExemptionCertificateFile'
                                                        uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                        value={values.agessaMdaExemptionCertificateFile}
                                                        previewUrl={settings.agessaMdaExemptionCertificateFile}
                                                        setFieldValue={(value) => {
                                                            setFieldValue('agessaMdaExemptionCertificateFile', value);
                                                            setHasUpdates(true);
                                                        }}
                                                        isDisabled={settings.agessaMdaExemptionCertificateFile.isValid}
                                                        isValid={settings.agessaMdaExemptionCertificateFile.isValid}
                                                        userId={id}
                                                    />
                                                    <Helper
                                                        label={settings.agessaMdaExemptionCertificateFile.helpLabel}
                                                        pictureFileUrl={settings.agessaMdaExemptionCertificateFile.helpPictureFileUrl}
                                                    />
                                                </FormControl>

                                            </FieldBlock>
                                        )}
                                    </Field>
                                    : null}

                                {settings.nonUniqueEmployerCertificateFile && settings.nonUniqueEmployerCertificateFile.isEnabled ?

                                    <FieldBlock
                                        isValid={settings.nonUniqueEmployerCertificateFile.isValid}
                                        comment={[settings.nonUniqueEmployerCertificateFile.comment]}
                                    >
                                        <FormControl>
                                            <FormLabel>
                                                {settings.nonUniqueEmployerCertificateFile.label ? settings.nonUniqueEmployerCertificateFile.label : 'Attestation de non unique employeur'}
                                            </FormLabel>

                                            <BrowseAndJoinDocument
                                                fieldName='nonUniqueEmployerCertificateFile'
                                                uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                                value={values.nonUniqueEmployerCertificateFile}
                                                previewUrl={settings.nonUniqueEmployerCertificateFile}
                                                setFieldValue={(value) => {
                                                    setFieldValue('nonUniqueEmployerCertificateFile', value);
                                                    setHasUpdates(true);
                                                }}
                                                isDisabled={settings.nonUniqueEmployerCertificateFile.isValid}
                                                isValid={settings.nonUniqueEmployerCertificateFile.isValid}
                                                userId={id}
                                            />
                                        </FormControl>
                                    </FieldBlock>
                                    : null}

                            </SimpleGrid>

                        </Section>
                        : null}



                <Section id='charts'>
                    <SectionTitle>Documents à signer</SectionTitle>
                    <SectionLegend>Veuillez trouver ci-après les chartes à signer.</SectionLegend>
                    <SimpleGrid
                        columns={{ base: 1, lg: 3 }}
                        gap={{ base: 1, lg: 3 }}
                    >

                        {settings.schoolChartSign && settings.schoolChartSign.isEnabled ?
                            <FieldBlock
                                isValid={settings.schoolChartSign.isValid}
                                comment={[settings.schoolChartSign.comment]}
                            >
                                <FormControl>
                                    <FormLabel>
                                        Règlement intérieur
                                    </FormLabel>
                                    <SignModule
                                        value={values.schoolChartSign}
                                        firstName={values.firstName}
                                        lastName={values.lastName}
                                        documentUrl={settings.schoolChartUrl}
                                        handleSign={
                                            (value) => {
                                                setFieldValue('schoolChartSign', value)
                                            }
                                        }
                                    />
                                </FormControl>
                            </FieldBlock>
                            : null}

                        {settings.qualityChartSign.isEnabled ?
                            <FieldBlock
                                isValid={settings.qualityChartSign.isValid}
                                comment={[settings.qualityChartSign.comment]}
                            >
                                <FormControl>
                                    <FormLabel>
                                        Charte qualité
                                    </FormLabel>
                                    <SignModule
                                        value={values.qualityChartSign}
                                        firstName={values.firstName}
                                        lastName={values.lastName}
                                        documentUrl={settings.qualityChartDocumentUrl}
                                        handleSign={
                                            (value) => {
                                                setFieldValue('qualityChartSign', value)
                                            }
                                        }
                                    />
                                </FormControl>
                            </FieldBlock>
                            : null}
                        {settings.itChartSign.isEnabled ?
                            <FieldBlock
                                isValid={settings.itChartSign.isValid}
                                comment={[settings.itChartSign.comment]}
                            >
                                <FormControl>
                                    <FormLabel>
                                        Charte informatique
                                    </FormLabel>
                                    <SignModule
                                        value={values.itChartSign}
                                        firstName={values.firstName}
                                        lastName={values.lastName}
                                        qualityChartSignDocumentUrl
                                        documentUrl={settings.itChartSignDocumentUrl}
                                        handleSign={
                                            (value) => {
                                                setFieldValue('itChartSign', value)
                                            }
                                        }
                                    />
                                </FormControl>
                            </FieldBlock>
                            : null}

                        {settings.imageRightsSign.isEnabled ?
                            <FieldBlock
                                isValid={settings.imageRightsSign.isValid}
                                comment={[settings.imageRightsSign.comment]}
                            >
                                <FormControl>
                                    <FormLabel>
                                        Droit à l'image
                                    </FormLabel>
                                    <SignModule
                                        value={values.imageRightsSign}
                                        firstName={values.firstName}
                                        lastName={values.lastName}
                                        allowRefuse={true}
                                        documentUrl={settings.imageRightsSignDocumentUrl}
                                        handleSign={
                                            (value) => {
                                                setFieldValue('imageRightsSign', value)
                                            }
                                        }
                                    />
                                </FormControl>
                            </FieldBlock>
                            : null}

                    </SimpleGrid>

                </Section>

                {/* {settings.courseMapFile.isEnabled ?
                    <Section id='coursemap'>
                        <Field name="courseMapFile">
                            {({ field, form }) => (
                                <FieldBlock
                                    isValid={settings.courseMapFile.isValid}
                                    comment={[settings.courseMapFile.comment]}
                                >
                                    <FormControl>
                                        <FormLabel>
                                            {settings.courseMapFile.label ? settings.courseMapFile.label : 'Syllabus / Progression pédagogique / Plan du cours'}
                                        </FormLabel>
                                        <Helper
                                            label={settings.courseMapFile.helpLabel}
                                            pictureFileUrl={settings.courseMapFile.helpPictureFileUrl}
                                        />
                                        <BrowseAndJoinManyDocuments
                                            fieldName='courseMapFile'
                                            uploadEndpoint='/inscription_farce.php/teacher/attach/'
                                            values={values.courseMapFile}
                                            setFieldValue={(value) => setFieldValue('courseMapFile', value)}
                                            isDisabled={settings.courseMapFile.isValid}
                                            userId={id}
                                        />
                                    </FormControl>
                                </FieldBlock>
                            )}
                        </Field>
                    </Section>
                    : null} */}
            </Form>
        )}

        </Formik>
    )
}

export default TeacherForm