import * as React from 'react'

export const AlumniFormulas = ({ schoolCode }) => {
    const formulas = {
        "edc": [
            "Je renonce à adhérer",
            "CondéConnect",
            "CondéConnect Premium"
        ],
        "eac": [
            "Je renonce à adhérer",
            "Luxury.Careers",
            "Luxury.Careers Premium"
        ],
        "ing": [
            "Je renonce à adhérer",
            "Luxury.Careers",
            "Luxury.Careers Premium"
        ],
        "esparf": [
            "Je renonce à adhérer",
            "Luxury.Careers",
            "Luxury.Careers Premium"
        ],
        "esp": [
            "Je renonce à adhérer",
            "NextGen",
            "NextGen Plus"
        ],
        "esd": [
            "Je renonce à adhérer",
            "Link",
            "Link Plus"
        ],
        "ecv": [
            "Simplicity",
            "Connectivity",
            "Infinity"
        ]
    }

    const options = () => {
        let result = [];
        for (let index = 0; index < formulas[schoolCode].length; index++) {
            const formula = formulas[schoolCode][index];
            result.push(<option key={`alumni-formula-${index}`} value={index}>{formula}</option>)
        }
        return result.reverse();
    }

    if (!formulas[schoolCode]) return <></>;

    return (
        <>
            {options()}
        </>
    )
}