import LogoECV from '../Images/logo-ecv.svg'
import LogoING from '../Images/logo-ing.png'
import LogoEDC from '../Images/logo-edc.svg'
import LogoESP from '../Images/logo-esp.png'
import LogoESD from '../Images/logo-esd.png'
import LogoESPA from '../Images/logo-espa.svg'


const getLogo = ( id ) =>{
    // console.log( 'id', id )
    let logo
    switch (id) {
        case 'edc':
            logo = LogoEDC
            break;
        case 'ecv':
            logo = LogoECV
            break;
        case 'esd':
            logo = LogoESD
            break;
        case 'ing':
            logo = LogoING
            break;
        case 'esp':
            logo = LogoESP
            break;
        case 'esparf':
            logo = LogoESPA
            break;
        default :
            logo = LogoEDC
        break;
    }
    return( logo )
}

export default getLogo