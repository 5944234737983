import { Box, Center, Text } from '@chakra-ui/react';
import { navigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import GenericLayout from '../Layouts/generic-layout'


const TeacherProfileThankyou = ({
    lang, step, id
}) => {

    const [data, setData] = useState(null);
    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/inscription_farce.php/teacher/${id}`
        )
            .then( (response)=>{
                if( response.data.status !== 'completed'){
                    navigate(`/${lang}/teacher/profile/${id}`)
                }
            })
    }, []);

    return(
        <GenericLayout
            locale={lang}
            displayNav={step === 1}
        >
            <Center minH='100vh'>
                <Text color='gray.500'>
                Votre profil est complet, merci beaucoup.

                </Text>
            </Center>
        </GenericLayout>
    )
}

export default TeacherProfileThankyou