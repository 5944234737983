import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { Box, Center, Flex, List, ListItem, Text } from '@chakra-ui/layout'
import React from 'react'


const AdminComment = ({ children, isVisible }) => (
    isVisible ?
        <Box
            mt={10}
        >
            <Text textDecoration='underline' mb={2} fontWeight='bold' fontSize='sm'>Commentaire de l'école&nbsp;:</Text>
            <Box>
                <Box
                    bg={
                        `linear-gradient(270deg,rgba(255,255,255,0.48) 0%,rgba(255,255,255,0.221538) 53.85%,rgba(255,255,255,0) 100%),rgba(253,255,148,0.69)`
                    }
                    display='inline-block'
                >

                    {children && children.length ?
                        <List>
                            {children.map(item =>
                                <ListItem>
                                    {item}
                                </ListItem>
                            )}
                        </List> : null}
                </Box>
            </Box>
        </Box>
        : null
)


const FieldBlock = ({
    children,
    isValid,
    comment
}) => {
    return (
        <Box
            p={{ base: 4, lg: 8 }}
            border='solid 1px'
            borderRadius='md'
            spacing={3}
            position='relative'

            borderColor={isValid === false ? 'yellow.200' : isValid === true ? 'green.100' : 'gray.100'}

            bg={isValid === false ? 'yellow.50' : isValid === true ? 'green.50' : 'white'}

        >
            <Box
                display={isValid === false || isValid === true ? 'block' : 'none'}
                bg={isValid === false ? 'yellow.400' : isValid === true ? 'green.400' : 'transparent'}

                position='absolute'

                top={-3}
                right={4}
                borderRadius='full'
                color='white'
            >

                <Flex
                    px={2}
                    py={{ base: 2, lg: 1 }}
                    fontSize={{ base: 'sm' }}
                >
                    <Center>
                        <Text
                            fontWeight='bold'
                            fontSize={{ base: 'xs' }}
                            display={{ base: 'none', lg: 'block' }}
                        >
                            {isValid === true ? 'Valide' : isValid === false ? 'Invalide' : ''}
                        </Text>
                        {isValid ?
                            <CheckCircleIcon
                                ml={{ base: 0, lg: 1 }}
                                w={{ base: 4, lg: 3 }}
                                h={{ base: 4, lg: 3 }}
                            />
                            : null}

                        {isValid === false ?
                            <SmallCloseIcon
                                ml={{ base: 0, lg: 1 }}
                                w={{ base: 4, lg: 3 }}
                                h={{ base: 4, lg: 3 }}
                            />
                            : null}
                    </Center>

                </Flex>

            </Box>
            {children}

            {comment && comment != '' ?
                <AdminComment isVisible={isValid === false && comment.length > 0}>
                    {comment}
                </AdminComment>
                : null}
        </Box>
    )
}

export default FieldBlock