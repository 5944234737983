import { Button } from '@chakra-ui/button'
import { AttachmentIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { Input } from '@chakra-ui/input'
import { Box, Center, Flex, Link, Text } from '@chakra-ui/react'
import axios from 'axios'
import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { BiX, BiFile } from 'react-icons/bi'

import Debug from './debug'

const BrowseAndJoinDocument = ({
    fieldName,
    value,
    setFieldValue,
    isDisabled,
    isValid,
    userId,
    uploadEndpoint,
    previewUrl
}) => {

    const [uploadProgress, setUploadProgress] = useState(false);
    const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
    const [uploadAttempt, setUploadAttempt] = useState(false);
    const [isTooHeavy, setIsToHeavy] = useState(false);
    const [fileSize, setFileSize] = useState(false);

    const inputFile = React.createRef();


    const [hotPreviewUrl, setHotPreviewUrl] = useState(undefined)

    const shorten = (string, limite) => (
        string.length > limite ? string.slice(0, limite) + '...' : string
    )

    const handleUpload = e => {

        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setUploadProgress(percentCompleted);
            },
        }

        const fileToUpload = e.target.files[0];

        if (fileToUpload.size > 20 * 1000000) {
            setIsToHeavy(true);
            setFileSize(fileToUpload.size / 1000000);
            inputFile.current.value = "";
            return
        } else {
            setIsToHeavy(false)
        }

        const data = new FormData();

        data.append('file', fileToUpload);
        data.append('fieldId', fieldName);
        data.append('userId', userId);
        data.append('studentId', userId);

        setUploadAttempt(fileToUpload.name)

        axios.post(
            // `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/attach/`,

            `${process.env.REACT_APP_API_URL}${uploadEndpoint}`,

            data,
            config
        )
            .then(res => { // then print response status
                setFieldValue(res.data)
                setUploadProgress(false)
                setHasBeenUpdated(true)
                setHotPreviewUrl(res.data[0].previewUrl)
            });
    }

    const handleDelete = (value) => {

        const doc = value.length > 0 ? value[0] : value; // Because unique field upload

        axios.delete(
            `${process.env.REACT_APP_API_URL}${uploadEndpoint}`,
            {
                data: {
                    fileName: doc,
                    fieldId: fieldName,
                    userId: userId
                }
            }
        ).then(res => {
            setFieldValue(null)
        })

        setFieldValue(null)
    }

    // return (
    //     <div>
    //         { JSON.stringify( value , null, 1 )}
    //     </div>
    // )

    // return (<Debug data={value} />)

    return (
        !value || value.length === 0 || value.value === null ?
            <>
                <Flex>
                    <Box>
                        <Button
                            as='label'
                            size="sm"
                            htmlFor={fieldName}
                            cursor='pointer'
                            isLoading={uploadProgress > 0}
                        >
                            {/* Browse and join */}
                            <AttachmentIcon mr={1} />
                            Parcourir et joindre
                        </Button>
                        {isTooHeavy && fileSize ?
                            <Text fontSize={`sm`} color={`red.500`}>
                                Votre fichier est trop gros ({fileSize.toFixed(2)} Mo), veuillez le réduire en dessous de 20 Mo svp.
                                {/* Votre fichier est trop lourd, veuillez le réduire en dessous de 20 Mo svp. */}
                            </Text>
                            : null}
                    </Box>
                    <Input
                        id={fieldName ? fieldName : 'id'}
                        accept="image/jpeg,application/pdf"
                        display='none'
                        type='file'
                        onChange={(e) => handleUpload(e)}
                        ref={inputFile}
                    />
                    <Center>
                        <Text
                            ml={1}
                            color='gray.500'
                            fontSize='sm'
                            opacity={uploadProgress ? 1 : 0}
                            transition='opacity 200ms ease-in-out'
                        >
                            {`${uploadProgress} %`}
                        </Text>
                    </Center>

                </Flex>
            </>
            :
            <>
                <Flex>
                    <Flex
                        bgColor='gray.200'
                        rounded={'md'}
                        alignItems='center'
                        shadow={`sm`}
                    >
                        <Button
                            size='sm'
                            // onClick={() => handleDelete(value)}
                            as={`a`}
                            href={
                                hotPreviewUrl
                                    ?
                                    `${hotPreviewUrl}&userId=${userId}`
                                    : previewUrl && previewUrl.value && previewUrl.value[0] ?
                                        `${previewUrl?.value[0]?.previewUrl}&userId=${userId}`
                                        : ``
                            }
                            // isDisabled={isDisabled}
                            colorScheme={
                                hasBeenUpdated ? 'green'
                                    : isValid === false ? 'orange'
                                        : isDisabled || value ? 'green'
                                            : 'gray'
                            }
                        >
                            {
                                hasBeenUpdated ? shorten(uploadAttempt, 20)
                                    : isValid === false ? `Document invalide`
                                        : value && value[0] && value[0].name ? shorten(value[0].name, 30)
                                            : `Document enregistré`
                            }

                            <Box ml={2}>
                                <BiFile />
                            </Box>
                        </Button>
                        {
                            !isDisabled
                                ?
                                <Box
                                    px={2}
                                // display={`none`}
                                >
                                    <Link
                                        isDisabled={isDisabled}
                                        onClick={() => handleDelete(value)}
                                        target='_blank'
                                        color='gray.700'
                                        fontSize={`xs`}
                                        title={value[0].name}
                                        display={`flex`}
                                        alignItems='center'
                                        alignContents={`center`}
                                    >
                                        Supprimer mon fichier
                                        <Text
                                            as='span'
                                            ml={1}
                                        >
                                            <BiX />
                                        </Text>
                                    </Link>
                                </Box>
                                : null}
                    </Flex>
                </Flex>
                {/* previewUrl : {previewUrl}&userId={userId} */}
            </>
    )
}

export default BrowseAndJoinDocument