export const handleRecordAdressComponents = (
    adressObject,
    setFieldValue,
    setFieldTouched,
    prefix,
    addressAloneField
) => {

    console.log('adressObject', adressObject)

    setFieldValue(
        `${prefix}${'postalCode'}`,
        '',
        true,
        true
    )

    for (let component in adressObject) {

        setFieldTouched(
            `${prefix}${component}`,
            true,
            true
        )

        if (
            !adressObject[component]
            || adressObject[component] === ''
            || adressObject[component] === undefined) continue;

        // console.log(
        //     `${prefix}${component}`,
        //     adressObject[component]
        // )

        setFieldValue(
            `${prefix}${component}`,
            adressObject[component],
            true
        )

        if (addressAloneField) {
            setFieldValue(
                `${addressAloneField}`,
                `${adressObject['number'] ? adressObject['number'] : ''} ${adressObject['route']}`.trim(),
                true
            )
        }
    }
}