import React, { Component } from 'react'
import Helmet from 'react-helmet'
// import AdmissionFormik from './AdmissionFormik'
import axios from 'axios'
// import FormLoading from './FormLoading'
import {
    Box,
    Heading,
    List,
    ListItem,
    Stack,
    Text

} from '@chakra-ui/react'
import getColorTheme from '../Utils/getColorTheme'
import getLogo from '../Utils/getLogo'
import getFavicon from '../Utils/getFavicon'
import Fonts from '../Utils/Fonts'
import StripeButton from './StripeButton'
import Layout from './Layouts/student-funnel'
import { PhoneIcon, AddIcon, WarningIcon, CheckCircleIcon } from '@chakra-ui/icons'
import { navigate } from '@reach/router'
import ProgressBar from '../Components/Form/ProgressBar'
import { FormattedMessage } from 'react-intl'

class Payment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            student: null
        }
    }
    componentDidMount() {
        const _this = this
        // console.log('componentDidMount', this.props);
        axios.get(`${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${this.props.id}`)
            .then(function (response) {
                // alert( JSON.stringify(response.data))
                _this.setState({
                    student: response.data
                })
                if (response.data.status !== 'updated') {
                    navigate(`/${_this.props.lang}/profile/${_this.props.id}`)
                }
            })
    }
    render() {
        const { student } = this.state
        return (
            student ?
                <Layout
                    school={student ? student.school : {}}
                    locale={this.props.lang}
                    apiData={student}
                >
                    {/* <pre>
                    { JSON.stringify( student, null, 2 )}
                </pre> */}
                    <Helmet>
                        <title>
                            {
                                this.props.lang === 'fr' ?
                                    `Règlement des frais ¬ ${student.school.title}`
                                    :
                                    `Payment of registration fees ¬ ${student.school.title}`
                            }
                        </title>
                        <link rel='icon' href={getFavicon(student.school.code)} />
                    </Helmet>
                    <ProgressBar step={4} />
                    <Stack
                        spacing={6}
                        px={{ base: 6, lg: 0 }}
                        mt={{ base: 10 }}
                    >
                        <Heading
                            fontSize={{ base: 32, lg: 38 }}
                            color='gray.700'
                        >
                            <FormattedMessage id="thankyou" /> {student.firstName}&nbsp;!
                        </Heading>

                        <Heading
                            fontSize={{ base: 20, lg: 26 }}
                            fontWeight='normal'
                            color='gray.700'
                        >
                            <FormattedMessage id="payment.notice.1" />.
                        </Heading>
                        <Heading
                            fontSize={{ base: 18, lg: 20 }}
                            fontWeight='normal'
                            color='gray.700'
                        >
                            {
                                student.type === 'new'
                                    ?
                                    <FormattedMessage id="payment.notice.2.new" values={{ period: student.teachingPeriod }} />
                                    :
                                    <FormattedMessage id="payment.notice.2.renew" values={{ period: student.teachingPeriod }} />
                            }
                            {' '}<Box p={0} as='span' color='brand.1.600'>{student.diplomaName}</Box>
                            {' '}<FormattedMessage id="step0.at.campus" />{' '}
                            <Box p={0} as='span' color='brand.1.600'>{student.campus}</Box>.
                        </Heading>

                        <Stack bg={{ base: 'none', lg: 'white' }} spacing={5} p={{ base: 0, lg: 4 }}>
                            <List spacing={4}>
                                <ListItem color='green.400'>
                                    <CheckCircleIcon mr={2} w={5} h={5} />
                                    Admission
                                </ListItem>
                                <ListItem color='green.400'>
                                    <CheckCircleIcon mr={2} w={5} h={5} />
                                    <FormattedMessage id="entering.your.informations" />
                                </ListItem>
                                <ListItem color='gray.700'>
                                    <CheckCircleIcon mr={2} w={5} h={5} />
                                    {
                                        student.type === 'new'
                                            ?
                                            student.school.code === 'eac' || student.school.code === 'ing' ? <FormattedMessage id="payment.administrative.fees" /> : <FormattedMessage id="payment.administrative.fees" />
                                            :
                                            student.school.code === 'eac' || student.school.code === 'ing' ? <FormattedMessage id="payment.administrative.fees" /> : <FormattedMessage id="payment.administrative.fees.renew" />
                                    }
                                </ListItem>
                            </List>
                            <Text>
                                {this.props.lang === 'fr' ?
                                    `Vous n'avez plus qu'à régler vos
                                ${student.type === 'new'
                                        ?
                                        ` frais de dossier`
                                        :
                                        student.school.code === 'eac' || student.school.code === 'ing' ? ` frais de réinscription` : ` arrhes de réinscription`
                                    }
                                pour qu'il soit complet. Pour cela, munissez-vous d'un moyen de paiement et cliquez sur le bouton ci-dessous pour accéder à la page de paiement : il se déroule sur Stripe, notre plateforme sécurisée de gestion des règlements.`
                                    :
                                    `All you have to do is pay your ${student.type === 'new' ? `registration fee` : `re-enrolment deposit`} for your application to be complete. To do this, please have a means of payment ready and click on the button below to access the secured payment page.`
                                }
                            </Text>

                            <Box>
                                <StripeButton
                                    id={this.props.id}
                                    school={student.school}
                                    student={student}
                                    email={student.email}
                                    type={student.type}
                                    locale={this.props.lang}
                                />
                            </Box>

                            <Text>
                                {this.props.lang === 'fr' ?
                                    `Vous pouvez également payer vos 
                                    ${student.type === 'new'
                                        ?
                                        ` frais de dossier`
                                        :
                                        student.school.code === 'eac' || student.school.code === 'ing' ? ` frais de réinscription` : ` arrhes de réinscription`
                                    }
                                     par virement ou par chèque : merci de prendre contact avec l’école (coordonnées ci-dessous). `
                                    :
                                    `You can also pay your application fee by bank transfer or cheque: please contact the school (contact details below).`}
                            </Text>
                        </Stack>
                    </Stack>
                </Layout>
                : null
        )
    }
}

export default Payment