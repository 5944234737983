const getColorTheme = (id) => {
    let colors = {}
    switch (id) {
        case 'edc': // école de condé
            colors = {
                brand: {
                    1: {
                        500: "#c43683",
                        600: "#A82D70"
                    }
                }
            }
            break;
        case 'ecv': // école de communication visuelle
            colors = {
                brand: {
                    1: {
                        500: "#8c74d9",
                        600: "#7761BD"
                    }
                }
            }
            break;

        case 'eac': // école art et culture
            colors = {
                brand: {
                    1: {
                        500: "#f1313f",
                        600: "#ce2e3a"
                    }
                }
            }
            break;

        case 'esd': // es digital
            colors = {
                brand: {
                    1: {
                        500: "#4ac3b5",
                        600: "#3DAC9F"
                    }
                }
            }
            break;
        case 'ing': // in gemologie
            colors = {
                brand: {
                    1: {
                        500: "#9d7b52",
                        600: "#886944"
                    }
                }
            }
            break;
        case 'esp': // es pub
            colors = {
                brand: {
                    1: {
                        500: "#29387d",
                        600: "#2F3B75"
                    }
                }
            }
            break;
        case 'esparf': // es parfum
            colors = {
                brand: {
                    1: {
                        500: "#cdab6c",
                        600: "#B1945C"
                    }
                }
            }
        case 'sae': // es parfum
            colors = {
                brand: {
                    1: {
                        500: "#333333",
                        600: "#111111"
                    }
                }
            }
            break;

        // esd : #4edabd
        // ecv : #4bacef
        // ing : #86654e
        // esp : #292e7c
        // espa : #000000


        default:
            colors = {
                brand: {
                    1: {
                        500: "#3bac8f",
                        600: "#4bd0ae"
                    }
                }
            }
            break;
    }
    return (
        colors
    )
}

export default getColorTheme