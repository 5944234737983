import React from 'react'
import {
    ChakraProvider,
    theme,
    Box,
    Flex,
    Text
} from '@chakra-ui/react'

const AdmissionError = () => {
    return (
        <ChakraProvider theme={theme}>
            <Flex
                justify='center'
                align='center'
                h='100vh'
            >
                <Text
                    color='gray.500'
                >
                    Erreur : le lien que vous avez utilisé semble erroné.
                </Text>
            </Flex>
        </ChakraProvider>
    )
}

export default AdmissionError