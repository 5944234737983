import { Box, Divider, Heading, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'

export const SchoolBankInformations = ({ iban, bic, firmLabel, htmlDescription }) => (
    <Stack spacing={4}>
        <Divider />
        <div dangerouslySetInnerHTML={{ __html: htmlDescription }} />
        <Text>
            <Text as='strong'>Iban :</Text> {iban}
        </Text>
        <Text>
            <Text as='strong'>Bic :</Text> {bic}
        </Text>
        <Text>
            <Text as='strong'>Titulaire du Compte :</Text> {firmLabel}
        </Text>
    </Stack>
)