const countries = [
"France",
"Danemark",
"Islande",
"Norvege",
"Suede",
"Finlande",
"Estonie",
"Lettonie",
"Lituanie",
"Allemagne",
"Autriche",
"Bulgarie",
"Hongrie",
"Liechtenstein",
"Roumanie",
"Tcheque, republique",
"Slovaquie",
"Bosnie-herzegovine",
"Croatie",
"Yougoslavie (serbie et montenegro)",
"Pologne",
"Russie, federation de",
"Albanie",
"Grece",
"Italie",
"Saint-marin",
"Saint-siege (etat de la cite du vatican)",
"Andorre",
"Belgique",
"Royaume-uni",
"Gibraltar",
"Espagne",
"Pays-bas",
"Irlande",
"Luxembourg",
"Monaco",
"Portugal",
"Suisse",
"Malte",
"Slovenie",
"Bielorussie",
"Moldova, republique de",
"Ukraine",
"Macedoine, ex-republique yougoslave de",
"Arabie saoudite",
"Iraq",
"Iran, republique islamique d",
"Liban",
"Syrienne, republique arabe",
"Israel",
"Turquie",
"Afghanistan",
"Pakistan",
"Bhoutan",
"Nepal",
"Chine",
"Japon",
"Thailande",
"Philippines",
"Jordanie",
"Inde",
"Birmanie",
"Brunei darussalam",
"Singapour",
"Malaisie",
"Maldives",
"Indonesie",
"Macao",
"Cambodge",
"Sri lanka",
"Taiwan, province de chine",
"Coree, republique populaire democratique de",
"Coree, republique de",
"Koweit",
"Lao, republique democratique populaire",
"Mongolie",
"Vietnam",
"Bangladesh",
"Emirats arabes unis",
"Qatar",
"Bahrein",
"Oman",
"Yemen",
"Armenie",
"Azerbaidjan",
"Chypre",
"Georgie",
"Kazakhstan",
"Kirghizistan",
"Ouzbekistan",
"Tadjikistan",
"Turkmenistan",
"Palestinien occupe, territoire",
"Egypte",
"Liberia",
"Afrique du sud",
"Gambie",
"Tanzanie, republique-unie de",
"Namibie",
"Congo, la republique democratique du",
"Presides, canaries",
"Guinee equatoriale",
"Ethiopie",
"Libyenne, jamahiriya arabe",
"Erythree",
"Somalie",
"Acores, madere",
"Burundi",
"Cameroun",
"Centrafricaine, republique",
"Congo",
"Cote d ivoire",
"Benin",
"Gabon",
"Ghana",
"Guinee",
"Burkina faso",
"Kenya",
"Madagascar",
"Malawi",
"Mali",
"Mauritanie",
"Niger",
"Nigeria",
"Ouganda",
"Rwanda",
"Senegal",
"Sierra leone",
"Soudan",
"Tchad",
"Togo",
"Zambie",
"Botswana",
"Lesotho",
"Maroc",
"Tunisie",
"Algerie",
"Sahara occidental",
"Maurice",
"Swaziland",
"Guinee-bissau",
"Mozambique",
"Sao tome-et-principe",
"Angola",
"Cap-vert",
"Comores",
"Seychelles",
"Djibouti",
"Canada",
"Etats-unis",
"Mexique",
"Costa rica",
"Cuba",
"Dominicaine, republique",
"Guatemala",
"Haiti",
"Honduras",
"Nicaragua",
"Panama",
"El salvador",
"Argentine",
"Bresil",
"Chili",
"Bolivie, etat plurinational de",
"Colombie",
"Equateur",
"Paraguay",
"Perou",
"Uruguay",
"Venezuela, republique bolivarienne du",
"Jamaique",
"Guyana",
"Belize",
"Groenland",
"Trinite-et-tobago",
"Barbade",
"Grenade",
"Bahamas",
"Suriname",
"Dominique",
"Sainte-lucie",
"Saint-vincent-et-les grenadines",
"Antigua-et-barbuda",
"Saint-christophe-et-nieves",
"Australie",
"Nouvelle-zelande",
"Pitcairn",
"Terr. des usa en oceanie",
"Samoa",
"Nauru",
"Fidji",
"Tonga",
"Papouasie-nouvelle-guinee",
"Tuvalu",
"Salomon, iles",
"Kiribati",
"Vanuatu",
"Marshall, iles",
"Micronesie, etats federes de",
"Palaos",
"Kosovo",
"Autres pays",
"Polynesie francaise",
"Etranger sans autre indication"
]

export default countries.sort( )