import React, { useState } from 'react'
import {
    Box,
    Button,
    List,
    ListItem,
    FormControl,
    FormErrorMessage,
    Text,
    Stack,
    Checkbox,
} from '@chakra-ui/react'

import { Field } from "formik";
import DocumentReadingModal from '../DocumentReadingModal'
import { FormattedMessage, useIntl } from 'react-intl';
import { DataSummary } from '../Summary';
require('typeface-caveat')

const Step3 = props => {
    const translations = useIntl()['messages']
    const locale = useIntl()['locale']
    const { errors, touched, isValid, values, setFieldValue, setFieldTouched, setFieldError, student, school, initialValues, isSubmitting } = props;

    // const [schoolChartModal, setSchoolChartModal] = useState( false )
    // const [wifiChartModal, setWifiChartModal] = useState( false )
    const [currentModal, setCurrentModal] = useState(false)
    const [currentFieldSign, setCurrentFieldSign] = useState(false)
    const [currentFieldImageSign, setCurrentFieldImageSign] = useState(false)
    const [modalData, setModalData] = useState(false)
    let fieldsGroupOne = [
        {
            name: "schoolChart",
            label: translations['internal.regulation'],
            btnLabel: translations['read.sign'],
            signImageField: 'schoolChartSign',
            modal: 'school',
            mandatory: true,
            readOnly: false
        },
        {
            name: "wifiChart",
            label: translations['internal.wifi.chart'],
            btnLabel: translations['read.sign'],
            signImageField: 'wifiChartSign',
            modal: 'wifi',
            mandatory: true,
            readOnly: false
        },
        {
            name: 'imageRights',
            label: translations['image.rights'],
            btnLabel: translations['read.chart'],
            signImageField: 'imageRightsSign',
            modal: 'picture',
            mandatory: false,
            readOnly: false
        },
        {
            name: 'personnalDataChart',
            label: translations['personnal.data.chart'],
            btnLabel: translations['read.chart'],
            signImageField: 'personnalDataChartSign',
            modal: 'personnalDataChart',
            mandatory: true,
            readOnly: true
        },
    ]
    if (initialValues.school.sandwishTraining && initialValues.school.isCgvChartEnabled) {
        fieldsGroupOne.push(
            {
                name: "cgv",
                label: translations['sandwish.training.cgv'],
                btnLabel: translations['read.sign'],
                signImageField: 'cgvSign',
                modal: 'cgv',
                mandatory: true
            }
        )
    } else if (initialValues.school.isCgvChartEnabled) {
        fieldsGroupOne.push(
            {
                name: "cgv",
                label: translations['cgv'],
                btnLabel: translations['read.sign'],
                signImageField: 'cgvSign',
                modal: 'cgv',
                mandatory: true
            }
        )
    }

    if (initialValues.school.code === 'esparf') {
        fieldsGroupOne.push(
            {
                name: "otherChart",
                label: translations['laboratory.regulation'],
                btnLabel: translations['read.sign'],
                signImageField: 'otherChartSign',
                modal: 'other',
                mandatory: true
            }
        )
    }


    return (
        <Box>
            {currentModal ?
                <DocumentReadingModal
                    firstName={values.firstName}
                    lastName={values.lastName}
                    isOpen={currentModal ? true : false}
                    // title={ <FormattedMessage id="internal.regulation" /> }
                    onClose={() => { setCurrentModal(false) }}
                    url={currentModal === 'school' ? student.school.schoolChartUrl
                        : currentModal === 'wifi' ? student.school.wifiChartUrl
                            : currentModal === 'cgv' ? student.school.cgvChartUrl
                                : currentModal === 'personnalDataChart' ? student.school.personalDataChartUrl
                                    : currentModal === 'other' ? student.school.otherChartUrl
                                        : student.school.imageRightChartUrl
                    }
                    handleSign={(signFile) => {
                        // console.log( signFile )
                        setFieldValue(currentFieldSign, true)
                        setFieldValue(currentFieldImageSign, signFile)
                        setCurrentModal(false)
                    }}
                    handleDecline={() => {
                        setFieldValue(currentFieldSign, false)
                        setCurrentModal(false)
                    }}
                    translations={translations}
                    key={modalData}
                    modalData={modalData}
                    locale={locale}

                />
                : null}

            <Stack spacing={5}>
                <Text>
                    {locale === 'fr' ?
                        `Merci de prendre connaissance des différents documents nécessaires à votre inscription à ${initialValues.school.title}.
                        Merci de les ouvrir, les lire intégralement et les signer électroniquement.
                        Une copie de ces documents signés vous sera envoyée ultérieurement par e-mail.`
                        :
                        `Please read the various documents required for your registration at ${initialValues.school.title}.
                        Please open them, read them in full and sign them electronically.
                        A copy of these signed documents will be sent to you by e-mail at a later date.`
                    }
                </Text>
                <Stack spacing={5}>
                    {fieldsGroupOne.map((item, index) =>
                        <Field
                            name={item.name}
                            key={`${item}-${index}`}
                        >
                            {({ field, form }) => (
                                <FormControl
                                    isInvalid={touched[item.name] && errors[item.name]}
                                    pointerEvents={values[item.name] ? 'none' : 'auto'}
                                >
                                    <Box
                                        as='label'
                                        display='flex'
                                        bg='white'
                                        borderRadius='3px'
                                        border='solid 1px'
                                        borderColor='gray.100'
                                        p={3}
                                        radius={1}
                                        htmlFor={item.name}
                                        justifyContent='space-between'
                                        onClick={
                                            (e) => {
                                                if (item.modal) {
                                                    e.preventDefault();
                                                    setCurrentModal(item.modal);
                                                    setModalData(item)
                                                    setCurrentFieldSign(item.name);
                                                    setCurrentFieldImageSign(item.signImageField)
                                                    setFieldTouched(item.name, true, true)
                                                }

                                            }
                                        }
                                    >
                                        <Checkbox
                                            {...field}
                                            id={item.name}
                                            defaultIsChecked={values[item.name]}
                                            isChecked={values[item.name]}
                                            w='100%'
                                            flexShrink='1'
                                            w={{ base: '50%', lg: '50%' }}

                                        >
                                            <Text>{item.label}</Text>
                                        </Checkbox>
                                        {item.btnLabel && item.modal && !values[item.name] ?
                                            <Button whiteSpace='pre-wrap' h='auto' flexGrow='0' flexShrink='1' px={5} onClick={() => { setCurrentModal(item.modal) }} px={4} size="sm">{item.btnLabel}</Button>
                                            : null}
                                    </Box>
                                    <FormErrorMessage>{errors[item.name]}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                    )}

                    <Box
                        bg='yellow.50'
                        border='solid 1px'
                        borderLeftWidth='5px'
                        borderColor='yellow.200'
                        borderRadius={{ base: 'sm' }}
                        padding={{ base: '1rem', lg: '2rem' }}

                    >
                        {locale === 'fr' ?
                            <>
                                <Text mb={2}><strong>Merci de noter que :</strong></Text>
                                <List spacing={3} listStyleType='disc' listStylePos='inside' fontSize='14px'>
                                    <ListItem display={`none`}>
                                        L’inscription n’est définitive qu’après réception du dossier complet assorti des
                                        {
                                            props.student.type === 'new'
                                                ?
                                                ` frais de dossier`
                                                :
                                                props.student.school.code === 'eac' || props.student.school.code === 'ing' ? ` frais de réinscription` : ` arrhes de réinscription`
                                        }.
                                        {initialValues.type === 'new' ? ` Les frais de dossier ne sont en aucun cas remboursables et resteront définitivement acquis à l’établissement.` : ''}
                                    </ListItem>
                                    <ListItem>Toute scolarité commencée est due en totalité.</ListItem>
                                    <ListItem>L'admission est prononcée sous réserve de validation de votre année en cours.</ListItem>
                                </List>
                            </>
                            :
                            <>
                                <Text mb={3}><strong>Please take note that :</strong></Text>
                                <List spacing={2} listStyleType='disc' listStylePos='inside' fontSize='14px'>
                                    <ListItem>The registration is only definitive after receipt of the complete file and the application fee. In the event of withdrawal, this must be made no later than 15 days after registration. The application fee is non-refundable and will remain the property of the school.</ListItem>
                                    <ListItem>Any schooling commenced is due in full.</ListItem>
                                    <ListItem>Admission is subject to validation of your current year.</ListItem>
                                </List>
                            </>
                        }
                    </Box>

                    <DataSummary data={values} />

                    <Box>
                        <Button
                            type='submit'
                            mt={4}
                            bg={'brand.1.500'}
                            color='white'
                            _hover={{ bg: 'brand.1.600' }}
                            // isLoading={isSubmitting}
                            type="submit"
                            isDisabled={!isValid}
                            size='lg'
                            whiteSpace='pre-wrap'
                            p={{ base: 2, lg: 3 }}
                            h={'auto'}
                            isLoading={isSubmitting}
                        >
                            {
                                !student.isAlreadyPaid
                                    ? <FormattedMessage id="button.confirm.and.pay" />
                                    : <FormattedMessage id="button.confirm.and.follow" />
                            }
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}

export default Step3