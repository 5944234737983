import { useAuth } from "../Context/Auth"

export const LoginRoute = () => {
    const { googleSignIn, logOut, adeSignIn, user } = useAuth();

    const handleSignInGoogle = async () => {
        try {
            const signin = await googleSignIn();
        } catch (e) {
            console.log(e);
        }
    }

    const handleSignInAde = async () => {
        try {
            const signin = await adeSignIn();
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div>
            {!user ?
                <div>
                    Login :
                    <button
                        onClick={
                            () => handleSignInGoogle()
                        }
                    >
                        Google Sign In
                    </button>
                    <button
                        onClick={
                            () => handleSignInAde()
                        }
                    >
                        Ade Sign in
                    </button>
                </div>
                : null}
            <div>
                Signout :
                <button
                    onClick={
                        () => logOut()
                    }
                >
                    Sign out
                </button>
            </div>
        </div>

    )
}