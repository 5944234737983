import Debug from "../Components/Form/debug"
import { AuthContextProvider, useAuth } from "../Context/Auth"

export const ProtectedRoute = () => {
    const { user } = useAuth()
    return (
        user ?
            <div>
                <div>PROTECTED ROUTE</div>
                {/* <Debug data={user} /> */}
                <pre>
                    {JSON.stringify(user, null, 2)}
                </pre>
            </div>
            :
            <div>
                Your are not authorized to access to this page
            </div>
    )
}