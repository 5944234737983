import React from 'react'
import {
    ChakraProvider,
    CSSReset,
    Box,
    Image,
    Flex,
    Spinner,
    Text
} from '@chakra-ui/react'

const FormLoading = ( props ) => {
    return (
        <ChakraProvider>
            <CSSReset />
            <Flex
                h={'100vh'}
                justifyContent='center'
                alignItems='center'
            >
                <Flex
                    justifyContent='center'
                    alignItems='center'
                    wrap='wrap'
                >
                    <Box w='100%'>
                        <Image mx='auto' h='auto' w='auto' src="https://static.wixstatic.com/media/461157_bb44ba9a3f1047e3874a29e924f658e7~mv2.jpg/v1/crop/x_106,y_0,w_1543,h_1241/fill/w_194,h_155,al_c,q_80,usm_0.66_1.00_0.01/ad-education.webp" />
                    </Box>
                    { !props.isError ?
                    <>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.300"
                            size="md"
                            mx='auto'
                        />
                        <Text
                            mt={4}
                            w='100%'
                            textAlign='center'
                            px={30}
                        >
                            Chargement de votre profil en cours, veuillez patienter, merci.
                        </Text>
                    </>
                    :
                    <Text
                        mt={4}
                        w='100%'
                        textAlign='center'
                        color='red.500'
                        px={30}
                    >
                        Une erreur s'est produite au chargement de votre profil, vérifiez que le lien que vous avez utilisé est bien correct et entier.
                    </Text>
                    }
                </Flex>
            </Flex>
        </ChakraProvider>
    )
}

export default FormLoading 