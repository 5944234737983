import { Box, Heading, List, ListItem, Text } from '@chakra-ui/react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Debug from './debug'

export const Step2ErrorNotifications = ({ touched, errors }) => {

    const isFieldError = (key) => {

        if (
            (
                errors.responsibles[0]
                && errors.responsibles[0][key]
            )
            ||
            (
                errors.responsibles[1]
                && errors.responsibles[1][key]
            )
        ) {
            return true
        } else {
            return false
        }

    }

    return (
        <Box>
            <Debug data={errors.responsibles} />
            {
                // Object.keys(touched).length > 0
                //     &&
                (
                    errors.responsibles && errors.responsibles.length > 0

                    // || errors.responsibles[1].firstName
                    // || errors.responsibles[0].lastName
                    // || errors.responsibles[1].lastName
                    // || errors.responsibles[0].email
                    // || errors.responsibles[1].email

                    // || errors.responsibles[0].address
                    // || errors.responsibles[1].address

                    // || errors.responsibles[0].city
                    // || errors.responsibles[1].city
                    // || errors.responsibles[0].postalCode
                    // || errors.responsibles[1].postalCode
                    // || errors.responsibles[0].country
                    // || errors.responsibles[1].country
                    // || errors.responsibles[0].phone
                    // || errors.responsibles[1].phone

                    // || errors.responsibles[0].relationshipType
                    // || errors.responsibles[1].relationshipType


                )
                    ?
                    <Box
                        border='solid 2px'
                        borderColor='red.300'
                        bgColor='orange.50'
                        p={4}
                        rounded='md'
                    >

                        <Text>
                            <Heading
                                color='red.500'
                                fontSize={'md'}
                                textTransform='uppercase'
                                letterSpacing={'wide'}
                                mb={2}
                            >
                                <FormattedMessage id='missing.or.wrong.data' />:
                            </Heading>
                            <List
                                listStyleType={'disc'}
                                listStylePos='inside'
                            >
                                {isFieldError('firstName') ? <ListItem><FormattedMessage id='notice.missing.responsible.firstName' /></ListItem> : null}
                                {isFieldError('lastName') ? <ListItem><FormattedMessage id='notice.missing.responsible.lastName' /></ListItem> : null}
                                {isFieldError('email') ? <ListItem><FormattedMessage id='notice.missing.responsible.email' /></ListItem> : null}
                                {isFieldError('phone') ? <ListItem><FormattedMessage id='notice.missing.responsible.phone' /></ListItem> : null}
                                {isFieldError('address') ? <ListItem><FormattedMessage id='notice.missing.responsible.address' /></ListItem> : null}
                                {isFieldError('postalCode') ? <ListItem><FormattedMessage id='notice.missing.responsible.postalCode' /></ListItem> : null}
                                {isFieldError('city') ? <ListItem><FormattedMessage id='notice.missing.responsible.city' /></ListItem> : null}
                                {isFieldError('country') ? <ListItem><FormattedMessage id='notice.missing.responsible.country' /></ListItem> : null}
                                {isFieldError('relationshipType') ? <ListItem><FormattedMessage id='notice.missing.responsible.relationship.type' /></ListItem> : null}
                                {isFieldError('isStudiesPayer') ? <ListItem><FormattedMessage id='notice.missing.responsible.relationship.studyPayer' /></ListItem> : null}
                            </List>
                        </Text>
                    </Box>
                    : null}
        </Box >
    )
}