import React from 'react'
import {
    Box,
    Heading,
    Image,
    Stack,
    Text
} from '@chakra-ui/react'
import Illustration from '../../Images/welcome-illustration.png'
import { FormattedMessage } from 'react-intl'


const WelcomeScreen = (props) => {

    const welcomeImage = () => (
        props.school.welcomeImageUrl ? props.school.welcomeImageUrl
            : props.school.code === 'eac' ? 'https://ecole-eac.com/wp-content/uploads/2021/01/actu-session-dadmission_mars21-lyon-eac-800x520.jpg'
                : props.school.code === 'edc' ? 'https://ecoles-conde.com/wp-content/uploads/2020/04/ecole-de-conde-home-2-1920x1080.jpg'
                    : props.school.code === 'ecv' ? 'https://www.ecv.fr/app/uploads/2019/09/banniere-prepa-ecv-design.jpg'
                        : props.school.code === 'esd' ? 'https://ecole-du-digital.com/wp-content/uploads/2020/12/IMG_20201121_150212-copie.jpg'
                            : props.school.code === 'ing' ? 'https://ingemmologie.com/wp-content/uploads/2021/01/session-dadmission-ing-site-27013-1.jpg'
                                : props.school.code === 'esp' ? 'https://espub.org/wp-content/uploads/2021/01/Ecole-Superieure-Publicite_egalite-diversite-laicite.jpg'
                                    : props.school.code === 'esparf' ? 'https://ecole-parfum.com/wp-content/uploads/2015/09/etudiantes.jpg'
                                        : props.school.code === 'imaat' ? 'https://static.wixstatic.com/media/98d9af_6f97ec86701b4f4ab6ab4272a616200a~mv2.png/v1/fill/w_1962,h_934,al_c,usm_0.66_1.00_0.01,enc_auto/98d9af_6f97ec86701b4f4ab6ab4272a616200a~mv2.png'
                                            : Illustration

    )

    const { student } = props

    return (
        <Box>
            <Stack
                color='gray.900'
                mb={{ base: 5, lg: 10 }}
                as='hgroup'
                spacing={4}
            >
                <Heading
                    fontSize={{ base: 32, lg: 38 }}
                    color='gray.700'
                >
                    <FormattedMessage id="step0.hi.firstname" values={{ firstName: props.firstName }} />,
                </Heading>
                <Heading
                    fontSize={{ base: 20, lg: 26 }}
                    fontWeight='normal'
                    color='gray.700'
                >
                    {props.locale === 'fr' ?
                        `Bienvenue sur la plateforme d’admission de ${props.school.title}.`
                        : `Welcome on ${props.school.title}'s admission plateform.`}
                </Heading>
                <Text
                    fontSize={{ base: 18, lg: 20 }}
                    fontWeight='normal'
                    color='gray.700'
                    lineHeight={8}
                >

                    {student.type === 'new' ?
                        props.locale === 'fr' ?
                            `Vous êtes admissible en ${props.diploma} pour l'année scolaire ${props.student.teachingPeriod} sur le campus de ${props.campus}. Laissez-vous guider par les différentes étapes pour finaliser votre inscription.`
                            :
                            `You have been accepted for the academic year ${props.student.teachingPeriod} of ${props.diploma} on the campus of ${props.campus}. You will be guided to finalise your registration.`
                        :
                        props.locale === 'fr' ?
                            `Sous réserve de validation de votre année, vous êtes admissible en ${props.diploma} pour l'année scolaire ${props.student.teachingPeriod} sur le campus de ${props.campus}. Laissez-vous guider par les différentes étapes pour finaliser votre réinscription.`
                            :
                            `Subject to validation of your year, you are eligible to enrol in ${props.diploma} for the academic year ${props.student.teachingPeriod} on the campus of ${props.campus}. Let us guide you through the differents steps to finalise your registration.`
                    }

                </Text>
            </Stack>
            <Box
                boxShadow='lg'
                borderRadius='lg'
                color='gray.700'
                bgSize='100%'
                bgPos='top center'
                overflow='hidden'
            >
                {props.locale === 'fr' ?
                    <Stack
                        spacing={2}
                        bg='rgba(255,255,255,0.95)'
                        p={7}
                        borderBottom='solid 1px'
                        borderBottomColor='gray.200'
                        borderRadius='lg'
                        borderBottomRadius='none'
                    >
                        <Text
                            display={`none`}
                        >Parmi les informations à renseigner, une photo et une pièce d'identité seront nécessaires. Vous pourrez également régler les{' '}
                            {props.student.type === 'new'
                                ?
                                ` frais de dossier`
                                :
                                props.student.school.code === 'eac' || props.student.school.code === 'ing' ? ` frais de réinscription` : ` arrhes de réinscription`
                            }
                            {' '}par carte bancaire.
                        </Text>
                        <Text>Finaliser votre {student.type === 'new' ? 'inscription' : 'réinscription'} ne vous prendra que quelques minutes.</Text>
                        <Text>Si vous rencontrez le moindre problème, n’hésitez pas à contacter l’équipe admission de l’école dont les coordonnées figurent en bas de page.</Text>
                    </Stack>

                    :
                    <Stack
                        spacing={1}
                        bg='rgba(255,255,255,0.95)'
                        p={7}
                        borderBottom='solid 1px'
                        borderBottomColor='gray.200'
                        borderRadius='lg'
                        borderBottomRadius='none'
                    >
                        <Text>Among the information to be filled in, a photo of an ID or a passport photo will be required.</Text>
                        <Text>You will also have to pay a handling fee.</Text>
                        <Text>Make sure you have the necessary documents and a means of payment before starting the process.</Text>
                    </Stack>
                }
                <Image
                    src={
                        welcomeImage()
                    }
                    alt={`Admission ${props.student.school.title}`}
                    objectFit='cover'
                    minW='100%'
                />
            </Box>
        </Box>)
}

export default WelcomeScreen