import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller, Helpers } from 'react-scroll'
import { Box, Button, Center, Divider, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/layout'
import { ArrowForwardIcon, CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons'

export const FormSectionTitle = ({ children }) => {
    return (
        <Heading
            fontSize='lg'
            mb={2}
            pt={4}
        >{children}</Heading>
    )
}

export const FormSection = ({ children, id, isActive }) => {
    return (
        <Element name={id}>
            <Box
                position='relative'
                id={id}
                opacity={!isActive === false ? .5 : 1}
            >
                <Stack
                    spacing={5}
                >
                    {children}
                </Stack>

                <Divider my={10} />
            </Box>
        </Element>
    )
}

export const FormSectionLegend = ({ children }) => {
    return (
        <Box>
            <Text
                color='gray.700'
                fontSize='sm'
            >
                {children}
            </Text>
        </Box>
    )
}

export const FieldBlock = ({ children, isValid, id, isActive }) => {
    return (
        <Element name={id}>
            <Box
                opacity={!isActive ? .5 : 1}
                pointerEvents={!isActive ? 'none' : 'auto'}

                p={{ base: 4, lg: 10 }}
                border='solid 1px'
                borderRadius='md'
                position='relative'

                borderColor={isValid === false ? 'yellow.200' : isValid === true ? 'green.100' : 'gray.100'}

                bg={isValid === false ? 'yellow.50' : isValid === true ? 'green.50' : 'white'}

            >
                <Box
                    display={isValid === false || isValid === true ? 'block' : 'none'}
                    bg={isValid === false ? 'yellow.400' : isValid === true ? 'green.400' : 'transparent'}

                    position='absolute'

                    top={-3}
                    right={4}
                    borderRadius='full'
                    color='white'
                >
                    <Flex
                        px={2}
                        py={{ base: 2, lg: 1 }}
                        fontSize={{ base: 'sm' }}
                    >
                        <Center>
                            <Text
                                fontWeight='bold'
                                fontSize={{ base: 'xs' }}
                                display={{ base: 'none', lg: 'block' }}
                            >
                                {isValid === true ? 'Valide' : isValid === false ? 'Invalide' : ''}
                            </Text>
                            {isValid ?
                                <CheckCircleIcon
                                    ml={{ base: 0, lg: 1 }}
                                    w={{ base: 4, lg: 3 }}
                                    h={{ base: 4, lg: 3 }}
                                />
                                : null}

                            {isValid === false ?
                                <SmallCloseIcon
                                    ml={{ base: 0, lg: 1 }}
                                    w={{ base: 4, lg: 3 }}
                                    h={{ base: 4, lg: 3 }}
                                />
                                : null}
                        </Center>

                    </Flex>

                </Box>
                {children}
            </Box>
        </Element>
    )
}