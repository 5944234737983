import React, { createRef, Component } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import { Box, Button, Stack } from '@chakra-ui/react'
import getFavicon from '../../Utils/getFavicon'
import {
    ChakraProvider,
    CSSReset,
    Container,
    FormControl,
    FormLabel,
    FormHelperText,
    Heading,
    Image,
    Input,
    Select,
    Text
} from '@chakra-ui/react'

class CreateStudent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            // lastName:'',
            email: '',
            campus: '',
            school: '',
            diplomaName: '',
            type: '',
            schools: [],
            error: false
        }
        this.form = React.createRef();

    }
    componentDidMount() {
        this.getData(this.props.id)
    }
    getData(id) {
        var _this = this

        axios.get(`${process.env.REACT_APP_API_URL}/school/`)
            .then(function (response) {
                _this.setState({ schools: response.data })
            })
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })

    }
    render() {
        const handleSubmit = (e) => {
            var _this = this
            e.preventDefault();
            // alert( JSON.stringify( this.state, null, 2 ))
            axios.post(`${process.env.REACT_APP_API_URL}/student`, this.state)
                .then(
                    (response) => {
                        // console.log('response', response)
                        // _this.setState({ errorMessage:response})
                        _this.form.current.reset()
                        _this.setState({
                            firstName:'',
                            campus:'',
                            email:'',
                            diplomaName:'',
                            school:'',
                            type:'',
                            error: false
                        })
                    },
                    (error) => {
                        // alert('error')
                        // console.log( error )
                        _this.setState({ error: true })
                    }
                )
        }

        const { firstName, lastName, email, schools, diplomaName, campus, error } = this.state
        return (
            <ChakraProvider>
                <Helmet>
                    <link rel='icon' href={ getFavicon('default')} />
                    <title>Création d'un profil étudiant</title>
                </Helmet>
                <CSSReset />
                <Container>
                    
                        <Stack
                            as='form'
                            spacing={8}
                            onSubmit={(e) => { handleSubmit(e) }}
                            my={4}
                            ref={ this.form }
                        >
                            <Box>
                                <Image mx='auto' src='https://static.wixstatic.com/media/461157_bb44ba9a3f1047e3874a29e924f658e7~mv2.jpg/v1/crop/x_106,y_0,w_1543,h_1241/fill/w_194,h_155,al_c,q_80,usm_0.66_1.00_0.01/ad-education.webp' alt='Group Ade' />
                            </Box>
                            <Heading as='legend'>Ajout d'un étudiant</Heading>
                            {this.state.errorMessage ? JSON.stringify(this.state.errorMessage, null, 2) : null}
                            <FormControl id="firstName">
                                <FormLabel>Prénom :</FormLabel>
                                <Input required name='firstName' onChange={(e) => { this.handleChange(e) }} value={firstName} type="text" />
                            </FormControl>
                            <FormControl id="email">
                                <FormLabel>Email de l'étudiant :</FormLabel>
                                <Input required name='email' onChange={(e) => { this.handleChange(e) }} value={email} inputMode="email" type="email" />
                                <FormHelperText>Attention, une erreur de saisie empêcherait la bon déroulé de l'inscription.</FormHelperText>
                            </FormControl>
                            <FormControl id="school">
                                <FormLabel>École concernée :</FormLabel>
                                <Select
                                    required
                                    onChange={(e) => { this.handleChange(e) }}
                                    name='school'
                                    placeholder="Choisir l'école de cet étudiant">
                                    {schools.map(school =>
                                        <option key={school._id} value={school._id}>{school.title}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl id="diplomaName">
                                <FormLabel>Intitulé du diplôme préparé :</FormLabel>
                                <Input required name='diplomaName' onChange={(e) => { this.handleChange(e) }} value={diplomaName} type="text" />
                                {/* <FormHelperText>Email de l'étudiant (pas d'erreur sinon il ne recevra pas le lien d'inscription).</FormHelperText> */}
                            </FormControl>
                            <FormControl id="campus">
                                <FormLabel>Campus :</FormLabel>
                                <Input required name='campus' onChange={(e) => { this.handleChange(e) }} value={campus} type="text" />
                            </FormControl>
                            <FormControl id="type">
                                <FormLabel>Type d'incription :</FormLabel>
                                <Select
                                    required
                                    onChange={(e) => { this.handleChange(e) }}
                                    name='type'
                                    placeholder="Choisir le type d'inscription"
                                >
                                    <option value='new'>Nouvelle inscription</option>
                                    <option value='renew'>Réinscription</option>
                                </Select>                            
                            </FormControl>
                            <Stack isInline spacing={ 4 } alignItems='center'>
                                <Button colorScheme='teal' type="submit">Enregistrer l'étudiant</Button>
                                <Text color='red.500'>
                                    {error ? 'Erreur, cet étudiant existe sans doute déjà' : ''}
                                </Text>
                            </Stack>
                            <Text>
                                {JSON.stringify(this.state.errorMessage, null, 2)}
                            </Text>
                        </Stack>
                        
                </Container>
            </ChakraProvider>
        )
    }
}

export default CreateStudent