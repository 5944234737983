import React from 'react';
import Helmet from 'react-helmet';
import {
  ChakraProvider,
  CSSReset,
  Box,
  Text,
  Flex,
  Image,
  Link,
  VStack,
  Code,
  Grid,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import { Router, Link as RouterLink } from "@reach/router"
import FormCreateStudent from './Components/Form/CreateStudent'
// import FormCreateSchool from './Components/Form/CreateSchool'
// import Admin from './Admin/index'
import FormLoader from './Components/Form/FormLoader'
import QuotaExceeded from './Components/QuotaExceeded'
import AdmissionError from './Components/AdmissionError'
import Payment from './Components/Payment'
import PaymentSuccess from './Components/PaymentSuccess'
import getFavicon from './Utils/getFavicon'
import StudentProfile from './Components/Profile/Loader'
import StudentProfileSuccess from './Components/Profile/Sucess'

import TeacherProfile from './Components/Teacher/Loader'
import TeacherProfileThankYou from './Components/Teacher/Thankyou'
import { AddressField } from './Components/Address/AddressField';
import { ProtectedRoute } from './Protected';
import { LoginRoute } from './Login';
import { AuthContextProvider } from './Context/Auth';
import { AccountCreation } from './Login/AccountCreation';
import { AccountLogin } from './Login/AccountLogin';

function App() {

  console.log('Release 13.12.2023 #3');

  const Home = (props) => {
    return (
      <ChakraProvider>
        <Helmet>
          <title>Application des admission ADE</title>
          <link rel='icon' href={getFavicon('default')} />
        </Helmet>
        <CSSReset />
        <Flex
          justify='center'
          alignItems='center'
          h='100vh'
        >
          <Box>
            <Image mx='auto' h='auto' w='auto' src="https://static.wixstatic.com/media/461157_bb44ba9a3f1047e3874a29e924f658e7~mv2.jpg/v1/crop/x_106,y_0,w_1543,h_1241/fill/w_194,h_155,al_c,q_80,usm_0.66_1.00_0.01/ad-education.webp" />
            <Text textAlign='center' color='red.500'>
              Une erreur s'est produite au chargement de votre profil, vérifiez que le lien que vous avez utilisé est bien correct et entier.
            </Text>
            <Text textAlign='center' color='gray.400'>Env : {process.env.REACT_APP_ENV}</Text>
          </Box>
        </Flex>
      </ChakraProvider>

    )
  }
  const Error = () => {
    return (
      <ChakraProvider>
        <Helmet>
          <title>Application des admission ADE</title>
          <link rel='icon' href={getFavicon('default')} />
        </Helmet>
        <CSSReset />
        <Flex
          justify='center'
          alignItems='center'
          h='100vh'
        >
          <Flex
            wrap='wrap'
          >
            <Box w='100%'>
              <Image mx='auto' h='auto' w='auto' src="https://static.wixstatic.com/media/461157_bb44ba9a3f1047e3874a29e924f658e7~mv2.jpg/v1/crop/x_106,y_0,w_1543,h_1241/fill/w_194,h_155,al_c,q_80,usm_0.66_1.00_0.01/ad-education.webp" />
            </Box>
            <Text
              textAlign='center'
              w='100%'
              color='red.500'
            >
              Erreur : cette adresse ne correspond à aucune fonctionnalité. Si vous arrivez ici depuis un lien reçu par email, vérifiez qu'il n'a pas été tronqué.
            </Text>
          </Flex>
        </Flex>
      </ChakraProvider>
    )
  }
  return (
    <>
      <AuthContextProvider>
        <Router>
          <Home path='/' />
          {/* misc */}
          <FormCreateStudent path="create-student" />
          <AdmissionError path="admission" />

          {/* Student profile form base : without Auth */}
          <FormLoader path="/:lang/admission/:id" />
          <QuotaExceeded path="/:lang/admission/quota/:id" />
          <Payment path="/:lang/payment/:id" />
          <PaymentSuccess path="/:lang/payment/success/:id" />

          {/* Ad account creation : without Auth */}

          <AccountCreation path="/:lang/account-creation/:id" />
          <AccountLogin path="/:lang/account-login/:id" />

          {/* Student profile form detail : with Auth */}
          <StudentProfile path='/:lang/profile/:id' />
          <StudentProfileSuccess path='/:lang/profile/success/:id' />


          {/* farce */}
          <TeacherProfile path="/:lang/teacher/profile/:id" />
          {/* <TeacherProfile step={2} path="/:lang/teacher/profile/details/:id" /> */}
          <TeacherProfileThankYou step={2} path="/:lang/teacher/profile/thankyou/:id" />

          {/* Auth experiment */}
          <ProtectedRoute path="protected-route" />
          <LoginRoute path='login' />

          <Error path="/*" />
        </Router>
      </AuthContextProvider>
    </>
  );
}

export default App;
