
import React, { useState } from 'react'
import {
    Box,
    Button,
    Flex,
    Text,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    HStack
} from '@chakra-ui/react'
import { CheckIcon, EditIcon } from '@chakra-ui/icons'
import DocumentViewer from './DocumentViewer'
import SignBox from './SignBox'

const SignModule = ({
    firstName,
    lastName,
    value,
    documentUrl,
    handleSign,
    allowRefuse
}) => {

    const [modalOpen, setModalOpen] = useState(false)

    if (!firstName || !lastName) {
        return (<Text color='gray.700' fontSize='sm'>Veuillez renseigner vos nom et prénom avant de pouvoir signer.</Text>)
    }

    return (
        <>
            {!value ?
                <Button
                    size='sm'
                    onClick={() => setModalOpen(true)}
                >
                    <EditIcon mr={1} />
                    Lire et signer</Button>
                :
                <Flex>
                    <Box
                        bg='green.400'
                        color='green'
                        py={1}
                        px={2}
                        color='white'
                        fontSize='sm'
                        borderRadius='md'
                        fontWeight={600}

                    >
                        {value === 'deny' ? "Information prise en compte" : "Signature enregistrée"}

                        <CheckIcon ml={2} />
                    </Box>
                </Flex>}

            {modalOpen ?
                <Modal
                    isOpen={!value && modalOpen}
                    onClose={() => setModalOpen(false)}
                    size='xl'
                >
                    <ModalOverlay />
                    <ModalBody
                    >
                        <ModalContent
                            pb={12}
                        >
                            <ModalHeader
                                p={8}
                                bg={'gray.100'}
                            >
                                Prenez connaissance du document et signez dans le cadre en bas de page, merci.
                            </ModalHeader>
                            <Box p={8}>
                                <DocumentViewer
                                    url={documentUrl}
                                />
                                <SignBox
                                    firstName={firstName}
                                    lastName={lastName}
                                    handleSign={handleSign}
                                />
                                {allowRefuse ?
                                    <HStack>
                                        <Text
                                            fontSize='sm'
                                        >
                                            Si vous ne souhaitez pas signer, vous pouvez
                                        </Text>
                                        <Button variant='link' mt={1} size='sm' onClick={() => {
                                            handleSign("deny");
                                            setModalOpen(false)
                                        }}>refuser</Button>
                                        <Text>.</Text>
                                    </HStack>
                                    : null}
                            </Box>
                        </ModalContent>
                    </ModalBody>
                </Modal>
                : null}

        </>
    )
}

export default SignModule