import {
    GoogleAuthProvider,
    getAuth,
    SAMLAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
    // signInWithEmailLink,
    // FacebookAuthProvider,
    signOut,
    // EmailAuthCredential,
    signInWithRedirect
} from "firebase/auth";
import * as React from "react"

import { auth } from '../Firebase/config.js'


const {
    createContext,
    useContext,
    useEffect,
    useState
} = React

const AuthContext = createContext({})


export const AuthContextProvider = (props) => {

    const [user, setUser] = useState({ user: null })

    const { children } = props

    const adeSignIn = () => {
        console.log('ade sign in');
        // const provider = new GoogleAuthProvider();
        // const googleSign = signInWithPopup(auth, provider);
        const provider = new SAMLAuthProvider(`saml.ad-education`);
        // const provider = new SAMLAuthProvider(`https://fs.ad-education.com/adfs/ls/`);
        const adeSign = signInWithRedirect(auth, provider);
    }

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        const googleSign = signInWithPopup(auth, provider);
        // console.log('googleSign', googleSign);
    }

    const logOut = () => {
        signOut(auth);
        setUser(null);
    }

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            console.log('currentuser', currentuser)
            setUser({ user: currentuser });
            let tmpUser = currentuser
        })
        return () => unsubscribe()
    }, [])

    return (<AuthContext.Provider value={{ ...user, googleSignIn, adeSignIn, logOut }}>{children}</AuthContext.Provider>)
}

export const useAuth = () => {
    return useContext(AuthContext)
}