import React, { useState, useEffect } from 'react'
import { Box, Input, InputGroup, InputLeftAddon, Icon, Select, Text } from '@chakra-ui/react'
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { phoneIntPrefixes } from '../../Utils/phone-prefix'
const IntPhoneInput = (props) => {
  // const [value, setValue] = useState(props.value ? props.value.substr(7,12) : '+33')
  const translations = useIntl()['messages']
  const [isValid, setIsValid] = useState(false)

  if (props.value !== null) {
    // setIndicatif( props.value.substr(0,3));
    // setValue( `0${props.value.substr(4,12)}`)
  }


  useEffect(() => {
    if (!props.phonePrefix) {
      // props.setPhonePrefixValue( '+33')
    }
  }, []);

  // let formatPhoneNumber = (str, updateComponent = true ) => {
  //   // props.setFieldValue(str)
  //   var value
  //   if( typeof str === null ) { return }
  //   let cleaned = str.replace(/ /g, "");
  //   if (cleaned.length === 10) {
  //     setIsValid(true)
  //   } else if (cleaned.length > 10) {
  //     cleaned = cleaned.substr(0, 10)
  //   } else {
  //     cleaned = cleaned
  //   }

  //   // Handle 0 missing
  //   let zeroMissingMatch = cleaned.match(/^([1-9]{1})(\d)+$/);
  //   if (zeroMissingMatch) {
  //     value = '0'+cleaned
  //   } else {
  //     value = cleaned
  //   }

  //   let validNumberMatch = cleaned.match(/^([0-0]{1})(\d{9})$/);
  //   if (validNumberMatch) {
  //     setIsValid(true)
  //     let intlCode = indicatif
  //     let intNumber = [intlCode, ' (', validNumberMatch[1], ') ', validNumberMatch[2]].join('')
  //     setIntlNumber(intNumber)
  //     if ( updateComponent ){
  //       props.setFieldValue( intNumber )
  //     }
  //   } else {
  //     setIsValid(false)
  //   }
  //   return value;
  // }
  //   const { touched, errors } = props
  return (
    <Box>
      {/* <pre>
        {JSON.stringify(phoneIntPrefixes, null, 1)}
      </pre> */}
      <InputGroup bg='white'>
        <Select
          onChange={(event) => {
            props.setPhonePrefixValue(event.target.value)
          }}
          value={props.phonePrefix}
          maxW='85px'
          fontSize='13px'
          borderTopRightRadius='0' borderBottomRightRadius='0'
        >
          {phoneIntPrefixes.map(item =>
            <option key={`phonePrefix${item}`} value={`+${item}`}>{`+${item}`}</option>

          )}
        </Select>
        {/* <InputLeftAddon borderTopLeftRadius='0' borderBottomLeftRadius='0' children={indicatif} /> */}
        <Input
          type="tel"
          roundedLeft="0"
          placeholder={translations['placeholder.phone.number']}
          inputMode="phone"
          value={props.phone}
          onChange={(event) => {
            props.setPhoneValue(event.target.value.replace(/ /g, "").replace(/\D/g, '').slice(0, 10))
          }}
        />
      </InputGroup>
      {props.prefix && props.phone.length >= 9 ?
        <Text color='green.400' textAlign="right" fontSize="14px">
          <PhoneIcon mr='.25rem' /><FormattedMessage id="phone" />&nbsp;: {props.prefix} {props.phone}
        </Text>
        : null}
    </Box>
  )
}

export default IntPhoneInput