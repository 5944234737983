import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { Stack, Heading, Box, Button, Flex, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Text, VStack, Image } from '@chakra-ui/react';
import React, { Component } from 'react'

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { FormattedMessage } from 'react-intl';
import ImageRotate from './ImageRotate';

class UploaderWithCrop extends Component {
    state = {
        src: null,
        srcBase64: null,
        isCropperVisible: undefined,
        rotateIndex: 0,
        crop: {
            unit: '%',
            width: 35,
            aspect: 1 / 1.33,
        },
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result },
                    this.setState({ isCropperVisible: true })
                )
            );

            // console.log(e.target.files[0]);
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        // console.log('onCropComplete');
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        // console.log('make client crop');
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {


        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        //console.log('image.naturalWidth', image.naturalWidth, 'image.width', image.width);
        //console.log('image.naturalHeight', image.naturalHeight, 'image.height', image.height);

        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // console.log('crop.width', crop.width )
        // console.log('crop.height', crop.height )

        const base64Image = canvas.toDataURL('image/jpeg');

        this.resizedataURL(base64Image, 150, 200)

    }

    resizedataURL(datas, wantedWidth, wantedHeight) {
        // We create an image to receive the Data URI
        var img = document.createElement('img');

        var _this = this;

        // When the event "onload" is triggered we can resize the image.
        img.onload = function () {
            // We create a canvas and get its context.
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            // We set the dimensions at the wanted size.
            canvas.width = wantedWidth;
            canvas.height = wantedHeight;

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

            var dataURI = canvas.toDataURL();

            /////////////////////////////////////////
            // Use and treat your Data URI here !! //
            /////////////////////////////////////////

            // console.log('dataURI', dataURI)
            _this.props.setFieldValue(dataURI);
            // return dataURI
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;
    }


    handleDelete() {
        document.getElementById("profilPict").value = "";
        this.setState({
            croppedImageUrl: null,
            src: null,
            srcBase64: null
        })
        this.props.setFieldValue(null)
    }


    render() {
        const {
            crop,
            src,
            isCropperVisible,
            srcBase64
        } = this.state;

        const {
            errors,
            touched
        } = this.props;

        let { croppedImageUrl } = this.state

        croppedImageUrl = croppedImageUrl ? croppedImageUrl : this.props.initialValue;

        return (
            <Box>
                <Box>
                    <FormControl isInvalid={errors.profilePictFileCropped && touched.profilePictFileCropped}>
                        <FormLabel>
                            <FormattedMessage id='label.your.picture' />
                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                        </FormLabel>
                        {!croppedImageUrl ?
                            <Button
                                size='sm'
                                cursor='pointer'
                                as='label'
                                color='brand.1.500'
                                bg='white'
                                border='solid 1px'
                                borderColor='brand.1.500'
                                boxShadow='sm'
                                _hover={{
                                    bg: 'brand.1.600',
                                    color: 'white'
                                }}
                                htmlFor='profilPict'
                            >
                                <FormattedMessage id='browse' />
                            </Button>
                            :
                            <Box
                                position='relative'
                            >
                                <Box
                                    position='absolute'
                                    top={0}
                                    left={0}
                                    p={2}
                                >
                                    <Button
                                        onClick={() => this.handleDelete()}
                                        size='sm'
                                        background='red.500'
                                        color='white'
                                        fontSize='xs'
                                        p={1}
                                        h='auto'
                                        _hover={{
                                            bg: 'red.600'
                                        }}
                                    >
                                        <FormattedMessage id='button.delete' />
                                        <SmallCloseIcon />
                                    </Button>
                                </Box>
                                <Image
                                    alt="Crop"
                                    maxW={'150px'}
                                    src={croppedImageUrl}
                                    borderRadius='sm'
                                />
                            </Box>
                        }
                        <FormErrorMessage>Ce champs est obligatoire</FormErrorMessage>
                    </FormControl>
                    <input
                        id='profilPict'
                        type="file"
                        accept="image/jpeg"
                        onChange={this.onSelectFile}
                        style={{
                            border: "0",
                            clip: "rect(0 0 0 0)",
                            height: "1px",
                            margin: "-1px",
                            overflow: "hidden",
                            padding: "0",
                            position: "absolute",
                            width: "1px"
                        }}
                    />
                </Box>

                <Modal
                    isOpen={isCropperVisible}
                    size='sm'
                >
                    <ModalBody>
                        <ModalOverlay />
                        <ModalContent
                            p={2}
                            pb={{ base: 16, lg: 2 }}
                            borderRadius={{ base: 0, lg: 'md' }}

                        >
                            {src && !srcBase64 ? (
                                <ImageRotate
                                    src={src}
                                    validate={(base64Value) => {
                                        // console.log('Copy base64 from rotator', base64Value)
                                        this.setState({ 'srcBase64': base64Value });
                                    }
                                    }
                                />
                            ) : null}
                            {src && srcBase64 ? (
                                <Stack
                                    overflow='hidden'
                                    p={2}
                                >
                                    <Heading fontSize={{ base: 'xl', lg: 'xl' }}>
                                        <FormattedMessage id='picture.cropping' />&nbsp;:
                                    </Heading>
                                    <Text>
                                        <FormattedMessage id='picture.cropping.notice' />&nbsp;:
                                    </Text>
                                    <ReactCrop
                                        src={srcBase64}
                                        crop={crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}

                                        imageStyle={{ maxWidth: '100%' }}
                                    />
                                    <Flex alignItems='flex-start'>
                                        <Button
                                            colorScheme='blue'
                                            onClick={() => {
                                                this.setState({ isCropperVisible: false })
                                            }
                                            }
                                        >
                                            <FormattedMessage id='picture.cropping.validate' />
                                        </Button>
                                    </Flex>
                                </Stack>) : null}
                        </ModalContent>
                    </ModalBody>
                </Modal>
            </Box>
        )
    }
}

export default UploaderWithCrop