import * as React from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import axios from 'axios';

export const LegalPageViewer = ({
    isVisible,
    handleClose,
    documentUrl
}) => {

    const [content, setContent] = React.useState(null);

    React.useEffect(
        () => {
            if (isVisible) {
                axios.get(
                    documentUrl,
                    { mode: 'no-cors' }
                ).then(
                    result => setContent(result.data)
                )
            }
        }
    )
    return (
        <Modal isOpen={isVisible} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Mentions légales</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {content ?
                        <div className={'wysiwyg'} dangerouslySetInnerHTML={{ __html: content }} />
                        : null}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}