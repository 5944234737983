import { Button } from '@chakra-ui/button'
import { AttachmentIcon, DeleteIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { Input } from '@chakra-ui/input'
import { Box, Center, Flex, Grid, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import axios from 'axios'
import { transform } from 'framer-motion'
import React, { useState } from 'react'
import Debug from './debug'

const BrowseAndJoinManyDocuments = ({
    fieldName,
    values,
    setFieldValue,
    isDisabled,
    userId,
    uploadEndpoint
}) => {

    const [uploadProgress, setUploadProgress] = useState(false);

    const uploadConfig = {
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadProgress(percentCompleted);
        },
    }

    // const handleUpload = e => {

    //     for (let index = 0; index < e.target.files.length; index++) {
    //         const file = e.target.files[index];

    //         const fileToUpload = e.target.files[0];
    //         const data = new FormData();

    //         data.append('file', fileToUpload);
    //         data.append('fieldId', fieldName);
    //         data.append('userId', userId);

    //         axios.post(
    //             `${process.env.REACT_APP_API_URL}${uploadEndpoint}`,
    //             data,
    //             uploadConfig
    //         )
    //             .then(res => { // then print response status
    //                 console.log(res)
    //                 setFieldValue('ok')
    //                 setUploadProgress(false)
    //             });

    //     }

    // }

    const proceedUpload = files => {

        for (let index = 0; index < files.length; index++) {
            const file = files[index];

            const data = new FormData();

            data.append('file', file);
            data.append('fieldId', fieldName);
            data.append('userId', userId);

            axios.post(
                `${process.env.REACT_APP_API_URL}${uploadEndpoint}`,
                // `https://intranet.ad-education.com/inscription_biel.php/student/attach/${userId}`,
                data,
                uploadConfig
            )
                .then(res => { // then print response status
                    setFieldValue(res.data)
                    setUploadProgress(false)
                });

        }

    }

    // const handleDelete = () => {

    //     setFieldValue(null)
    // }

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        proceedUpload(files);
    }

    const handleDelete = (doc, index) => {

        axios.delete(
            `${process.env.REACT_APP_API_URL}${uploadEndpoint}`,
            {
                data: {
                    fileName: doc,
                    fieldId: fieldName,
                    userId: userId
                }
            }
        ).then(res => {
            setFieldValue(res.data)
        })
    }

    return (
        <>
            {/* <Debug data={values} /> */}
            <Center
                cursor='copy'
                border='dashed 2px'
                borderColor='gray.300'
                borderRadius='md'
                transition='transform 200ms ease-in-out, border-color 200ms ease-in-out'
                _hover={{
                    borderColor: 'brand.1.500',
                    transform: 'scale(1.03)'
                }}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                p={30}
                my={2}
            >
                <Box>
                    <Text textAlign='center' fontSize='sm' color='gray.600' mb={2}>
                        Déposez ici vos fichiers .pdf ou .jpg
                    </Text>
                    <Center>
                        <Button
                            as='label'
                            size="sm"
                            htmlFor={fieldName}
                            cursor='pointer'
                            isLoading={uploadProgress > 0}
                        >
                            {/* Browse and join */}
                            <AttachmentIcon mr={1} />
                            Parcourir et joindre
                        </Button>
                        <Input
                            id={fieldName ? fieldName : 'id'}
                            accept="image/jpeg,application/pdf"
                            display='none'
                            type='file'
                            multiple
                            onChange={(e) => proceedUpload(e.target.files)}
                        />
                    </Center>
                    <Center>
                        <Text
                            ml={1}
                            color='gray.500'
                            fontSize='sm'
                            display={uploadProgress ? 'block' : 'none'}
                            opacity={uploadProgress ? 1 : 0}
                            transition='opacity 200ms ease-in-out'
                        >
                            {`${uploadProgress} %`}
                        </Text>
                    </Center>


                </Box>
            </Center>

            {
                values && values.length ?
                    <SimpleGrid
                        my={4}
                        columns={{
                            base: 1,
                            lg: 3
                        }}
                        gap={{
                            base: 4, lg: 5
                        }}
                    >
                        {values.map((item, index) =>
                            <Grid
                                gridTemplateColumns={`80% 1fr`}
                                gap={1}
                            >
                                <Box
                                    key={`${item}-${index}`}
                                    onClick={() => handleDelete(values[index], index)}
                                    cursor='pointer'
                                    borderRadius='3px'
                                    fontSize='sm'
                                    px={2}
                                    py={1}
                                    boxShadow='sm'
                                    bg='brand.1.500'
                                    color='white'
                                    fontWeight='bold'
                                    transition='background 400ms ease-in-out'
                                    overflow='hidden'
                                    _hover={{
                                        background: 'brand.1.600'
                                    }}
                                >
                                    <Flex
                                        justifyContent='space-between'
                                    >
                                        <Center overflow='hidden'>
                                            <AttachmentIcon mr={1} />
                                            <Heading
                                                isTruncated
                                                fontSize='sm'
                                                maxW='100%'
                                            >
                                                {item.name}

                                            </Heading>
                                        </Center>
                                        <Box>
                                            <SmallCloseIcon />
                                        </Box>
                                    </Flex>
                                </Box>
                                <Button
                                    href={
                                        `${item.previewUrl}&name=${item.name}&time=${item.time}&userId=${userId}`
                                    }
                                    as={`a`}
                                    w={`full`}
                                    target={`_blank`}
                                    size={`sm`}>
                                    Voir</Button>
                            </Grid>
                        )}
                    </SimpleGrid>
                    : null
            }
        </>
    )
}

export default BrowseAndJoinManyDocuments