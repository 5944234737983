import React from 'react'
import Helmet from 'react-helmet'
import {
    ChakraProvider,
    extendTheme,
    Box,
    Flex,
    Grid,
    Image,
    Link
} from '@chakra-ui/react'
import Footer from '../Footer'
import getColorTheme from '../../Utils/getColorTheme'
import getLogo from '../../Utils/getLogo'
import getFavicon from '../../Utils/getFavicon'
import Fonts from '../../Utils/Fonts'
import { IntlProvider, FormattedMessage } from 'react-intl'
import frTranslations from '../../Translations/fr'
import enTranslations from '../../Translations/en'
import TeacherFormNav from '../Teacher/Nav'

const GenericLayout = (props) => {
    const colors = getColorTheme(props.school ? props.school.code : '')
    const theme = extendTheme({
        colors,
        fonts: {
            // heading: 'AW Conqueror Didot'
        }
    })
    const logo = () => {
        return (
            "https://static.wixstatic.com/media/461157_bb44ba9a3f1047e3874a29e924f658e7~mv2.jpg/v1/crop/x_106,y_0,w_1543,h_1241/fill/w_388,h_310,al_c,q_80,usm_0.66_1.00_0.01/ad-education.webp"
        )
    }

    // const logo = () => {
    //     return(
    //         getLogo(
    //             props.school ? props.school.code : ''
    //         )
    //     )
    // } 

    const translations = {
        "en": enTranslations,
        "fr": frTranslations
    }

    return (
        <IntlProvider
            messages={translations[props.locale]} locale={props.locale} defaultLocale='en'
        >
            <ChakraProvider theme={theme}>
                <Fonts />
                <Helmet>
                    <link rel='icon' href={getFavicon('ade')} />
                </Helmet>
                <Grid
                    templateColumns={{
                        base: '100%',
                        lg: '300px 1fr',
                        xl: '320px 1fr'
                    }}
                    minH='100vh'
                >
                    <Box>
                        <Box
                            position='sticky'
                            top={0}
                            p={{ base: 5, lg: 10 }}
                            h={{ base: 'auto', lg: '100vh' }}
                        >
                            <Flex
                                justifyContent='space-between'
                                direction='column'
                                h={{ base: 'auto', lg: '100%' }}
                            >
                                <Box>
                                    <Flex
                                        alignItems='center'
                                        justifyContent='space-between'
                                        wrap='wrap'
                                    >
                                        {/* { JSON.stringify(props, null, 2 ) } */}
                                        <Image
                                            w={{ base: '100px', lg: '100%' }}
                                            mr={{ base: 1, lg: 0 }}
                                            src={logo() ? logo() : ''}
                                        />
                                    </Flex>
                                </Box>
                                <Box>
                                    <Box
                                        mt={4}
                                        fontSize='12px'
                                        color='gray.400'
                                        display={process.env.REACT_APP_ENV === 'production' ? 'none' : 'block'}
                                    >ENV  : {process.env.REACT_APP_ENV} </Box>
                                </Box>

                                { props.displayNav ? 
                                <Box
                                    display={{
                                        base: 'none',
                                        lg: 'block'
                                    }}
                                >
                                    <TeacherFormNav />
                                </Box>
                                : null }
                            </Flex>
                        </Box>
                    </Box>

                    <Box
                        bg='gray.50'
                        p={{ base: 10, lg: 15 }}
                        px={{ base: 1, lg: '10%' }}
                        pb={{ base: '200px', lg: 48 }}
                    >
                        {props.children}
                        {/* <Footer data={ props.school } locale={props.locale}/> */}
                    </Box>
                </Grid>
            </ChakraProvider>
        </IntlProvider>
    )
}

export default GenericLayout