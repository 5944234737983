const getAge = (dateString) => { // birthday is a date

    // console.log('dateString', dateString)
    if (dateString) {

        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    else {
        return 'error'
    }
}

export default getAge


// => update composant birthDate
// Comportement attendu : date à saisir correspondant à un âge entre 10 et 100 ans selon l'année courrante.
// Si la saisie ne correspond pas, le champs est en erreur

// empêcher pré-remplissage Chrome
// max à 20 char
