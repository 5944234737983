import { useState, useEffect } from 'react'
import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react"
import { DigitsForm } from './DigitsForm';
import axios from 'axios';
import { getUserData } from '../Utils/getUserData';
import Debug from '../Components/Form/debug';
import Layout from '../Components/Layouts/student-funnel'
import { PasswordCreationForm } from './PasswordCreationForm';
import { FormSection, FormSectionTitle, FormSectionLegend, FieldBlock } from '../Components/Field/Elements';
import { Link } from '@reach/router'
import { scroller } from 'react-scroll'



export const AccountCreation = ({ id, lang }) => {

    const [codeEmailSent, setCodeEmailSent] = useState(false);
    const [codeEmailValid, setCodeEmailValid] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false);
    const [userData, setUserData] = useState(false);

    const API_URL = process.env.REACT_APP_API_URL

    useEffect(
        () => {
            async function getUserData() {
                const request = await axios.get(
                    `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${id}`
                )
                setUserData(request.data);
            }
            if (!userData && id) { getUserData() }

        }, [id]
    )

    // const userData = 'a'
    // const userData = await getUserData(id);
    // console.log('userData', userData)


    const backendSendDigitsCode = async () => {
        try {
            // let processRequest = await axios.post(`${API_URL}/otpsendmail/${id}`);
            // if (processRequest.status === 'ok') {
            setCodeEmailSent(true);
            // }
        } catch (error) { (console.log(error)) }
    }
    const handleValidDigits = () => {
        setCodeEmailValid(true);
        verticalScollTo(2)
    }
    const handleValidAccountCreated = () => {
        setAccountCreated(true)
        verticalScollTo(3)
    }

    const verticalScollTo = (id) => {
        setTimeout(
            () => {
                scroller.scrollTo(`account-creation-step-${id}`, { duration: 1500, smooth: true, offset: -100, delay: 0 });
            }, 0
        )
    }


    if (!userData) return <>Loading</>

    return (

        <Layout
            school={userData.school}
            locale={lang}
        >

            <Stack spacing={0}>

                <Heading my={{ base: 4, lg: 12 }}>
                    {userData.firstName},
                    vous allez maintenant créer{' '}
                    <Text
                        display='inline-block'
                        borderBottom='solid 3px'
                        borderBottomColor='brand.1.500'
                    >
                        votre compte email
                    </Text>
                </Heading>

                <FormSection id="account-creation-step-1" >
                    <FormSectionTitle>Création de votre compte utilisateur</FormSectionTitle>
                    <FormSectionLegend>Avant de vous inviter à saisir la totalité des informations nécessaires, vous allez créer votre compte sur notre système informatique. Cela vous permettra de nous transférer des informations en toute sécurité.</FormSectionLegend>
                    <FieldBlock isActive={!codeEmailValid}>
                        <Stack spacing={5}>
                            <p>En premier lieu, vous allez recevoir un code à 6 chiffres sur votre email. Ce code a une validité de 8h après sa réception.</p>
                            {!codeEmailSent ?
                                <Box>
                                    <Button
                                        onClick={() => backendSendDigitsCode()}
                                    >
                                        Recevoir et saisir mon code
                                    </Button>
                                </Box>
                                :
                                <Stack spacing={5}>
                                    <Text>Récupérez votre compte à 6 chiffres dans votre boîte mail et saisissez le ici :</Text>
                                    <DigitsForm id={id} handleValidDigits={handleValidDigits} />
                                    <Flex alignItems={'center'}>Vous n'avez pas reçu le code email ou il a expiré,{' '}<Button ml={1} variant="link">demandez un nouveau code</Button>.</Flex>
                                </Stack>
                            }
                        </Stack>
                    </FieldBlock>
                </FormSection>



                {codeEmailValid ?
                    <FormSection id="account-creation-step-2">
                        <FormSectionTitle>Création d'un mot de passe robuste</FormSectionTitle>
                        <FieldBlock isActive={!accountCreated}>

                            <Stack spacing={5}>
                                <Text>Vous allez maintenant créer un mot de passe qui sera votre mot de passe de connexion unique pour tous les usages numérique ADE.</Text>
                                <PasswordCreationForm id={id} handleValidAccountCreated={() => handleValidAccountCreated()} userData={userData} />
                            </Stack>
                        </FieldBlock>
                    </FormSection>
                    : null}

                {accountCreated ?
                    <FormSection id="account-creation-step-3">
                        <FormSectionTitle>Création de votre compte utilisateur</FormSectionTitle>
                        <FieldBlock isActive={true}>
                            <Stack spacing={5}>
                                <Text>Votre compte a bien été créé, vous pouvez maintenant poursuivre votre inscription.</Text>
                                <Box>
                                    <Button
                                        as={Link}
                                        to={`/${lang}/profile/${id}`}
                                    >
                                        Poursuivre votre inscription
                                    </Button>
                                </Box>
                            </Stack>
                        </FieldBlock>
                    </FormSection>
                    : null}

            </Stack>

        </Layout>
    )
}