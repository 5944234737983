import { Box, Button, Center, Heading, Spinner, Stack, Text } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Layout from './Layouts/student-funnel'
import getFavicon from '../Utils/getFavicon'
import Helmet from 'react-helmet'
import { navigate } from '@reach/router';

const QuotaExceeded = (props) => {

    const [data, setData] = useState(false)

    useEffect(() => {
        if (!data) {
            getData();
        }

    }, []);

    const getData = () => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${props.id}`
        )
            .then(
                (response) => {
                    if (response.data) {
                        setData(response.data)
                        if (response.data.status !== 'quotaExceeded') {
                            navigate(`/${props.lang}/admission/${props.id}`)
                        }
                    }
                },
                (error) => {
                    console.log(error)
                }
            )
    }
    const [availabilityAlerte, setAvailabilityAlerte] = useState(false);
    const handleSubscribeToAvailabilityAlerte = () => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/contactme/`,
            {
                studentId: props.id
            }
        );
        setAvailabilityAlerte(true);
    }

    return (

        data ?
            <>
                <Layout
                    school={data.school}
                    locale={props.lang}
                    apiData={data}
                >
                    <Helmet>
                        <title>
                            {props.lang === 'fr' ?
                                `Inscriptions closes`
                                :
                                ``
                            }
                        </title>
                        <link rel='icon' href={getFavicon(data.school.code)} />
                    </Helmet>

                    <Box
                        py={10}
                    >
                        <Heading
                            fontSize={{ base: 32, lg: 38 }}
                            color='gray.700'
                        >
                            {props.lang === 'fr' ?
                                `Bonjour ${data.firstName}`
                                : `Hello ${data.firstName}`}
                        </Heading>
                        <Stack
                            py={10}
                            spacing={4}
                        >
                            {
                                props.lang === 'fr' ?

                                    <>
                                        <Text>
                                            Votre bulletin d'inscription en ligne a été temporairement suspendu
                                            {data.registerFileReturnDate ? ` car vous avez dépassé la date butoir d'inscription suivante : ${data.registerFileReturnDate}` : null}
                                            .

                                        </Text>
                                        <Text>
                                            Pas de panique, votre bulletin d'inscription peut être réactivé en fonction des places disponibles dans la formation souhaitée.
                                        </Text>
                                        <Text>
                                            Pour nous faire une demande de réactivation, cliquez sur le bouton ci-dessous.
                                        </Text>
                                    </>

                                    :
                                    <div>English versdion</div>
                            }
                            <Box>
                                {!availabilityAlerte ?
                                    <Button
                                        colorScheme='teal'
                                        onClick={() => handleSubscribeToAvailabilityAlerte()}
                                    >

                                        Réactiver mon inscription
                                    </Button>
                                    :
                                    <Stack
                                        p={4}
                                        bg='white'
                                        rounded={'md'}
                                        shadow={'md'}
                                        spacing={3}
                                    >
                                        <Text
                                        >
                                            {data.firstName}, votre demande a bien été prise en compte !
                                        </Text>
                                        <Text>
                                            Notre équipe vous recontactera prochainement pour traiter votre demande.
                                        </Text>
                                    </Stack>
                                }
                            </Box>
                            <Text>
                                {/* Merci de contacter l'école pour plus d’information. */}
                            </Text>
                        </Stack>

                    </Box>


                </Layout>


            </>
            :
            <Center minH='100vh'>
                <Spinner />
            </Center>
    )
}

export default QuotaExceeded