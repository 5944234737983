import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState, useEffect } from 'react'


const HighSchoolLabel = ({
    schoolId,
    deleteValue,
    isDisabled
 }) => {

    const [data, setData] = useState({ hits: [] });

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                `https://intranet.ad-education.com/inscription_biel.php/lyceeid/${schoolId}`,
            );
            setData(result.data);
        };

        fetchData();
    }, []);

    return (
        <FormControl>
            <FormLabel>Quel lycée fréquentiez-vous quand vous avez passé le bac&nbsp;?</FormLabel>
            { data ?
                <Button
                    onClick={()=> deleteValue()}
                    h='auto'
                    p={2}
                    whiteSpace='pre-wrap'
                    size='sm'
                    bg='brand.1.500'
                    color='white'
                    _hover={{
                        bg:'brand.1.600'
                    }}
                    isDisabled={ isDisabled }
                >
                    {data.appellation_officielle}
                    {!isDisabled ? 
                        <SmallCloseIcon />
                    : null }
                </Button>
            : null
            }
        </FormControl>
    )
}

export default HighSchoolLabel