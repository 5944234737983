import { Box, Button, Input, Text, Tooltip } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import './suggest.css';

// Imagine you have a list of languages that you'd like to autosuggest.


// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, data) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : data.filter(school =>
    // school.patronyme_uai.toLowerCase().slice(0, inputLength) === inputValue
    school.patronyme_uai.toLowerCase().includes(inputValue) ? true : false

  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.patronyme_uai;



const renderSuggestionsContainer = ({ containerProps, children }) => {
  const { ref, ...restContainerProps } = containerProps;
  const callRef = isolatedScroll => {
    if (isolatedScroll !== null) {
      ref(isolatedScroll.component);
    }
  };

  return (
    <Box
      position='relative'
      ref={callRef}
      {...restContainerProps}
    >
      {/* <pre>
                { JSON.stringify( restContainerProps, null, 1 )}
            </pre> */}
      { children ?
        <Box
          position='absolute'
          top={0}
          left={0}
          bg='white'
          w='full'
          border='solid 1px'
          borderColor='gray.300'
        >
          {children}
        </Box>
        : null}
    </Box>
  );
}

class SchoolSuggest extends React.Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      data: []
    };
  }

  componentDidMount() {
    axios.get(
      `https://intranet.ad-education.com/inscription_biel.php/lycee/${this.props.postalCode}`
    ).then((r) => this.setState({ data: r.data }))
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.state.data)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => (
      <Box borderBottom='solid 1px' borderBottomColor='gray.100'>
        <Tooltip hasArrow label={`${suggestion.denomination_principale} ${suggestion.patronyme_uai}`}>

          <Button
            variant='link'
            size='sm'
            whiteSpace='pre-wrap'
            textAlign='left'
            onClick={() => this.props.setFieldValue(suggestion.id)}
          >
            {`${suggestion.patronyme_uai}`}
          </Button>
        </Tooltip>
      </Box>
    );

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Saisissez le nom de votre lycée',
      value,
      onChange: this.onChange
    };

    const renderInputComponent = inputProps => (
      <Box>
        <Input
          borderLeftRadius={{ base: 'md', lg:0 }}
          bg='white'
          {...inputProps}
        />
      </Box>
    );

    // Finally, render it!
    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
        //renderSuggestionsContainer={renderSuggestionsContainer}
        />
        {/* <pre>
                { JSON.stringify( suggestions, null, 1 ) }
            </pre> */}
      </>
    )
  }
}

export default SchoolSuggest