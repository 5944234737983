import React from 'react'
import Helmet from 'react-helmet'
import {
    ChakraProvider,
    extendTheme,
    Box,
    Flex,
    Grid,
    Image,
    Link
} from '@chakra-ui/react'
import Footer from '../Footer'
import getColorTheme from '../../Utils/getColorTheme'
import getLogo from '../../Utils/getLogo'
import getFavicon from '../../Utils/getFavicon'
import Fonts from '../../Utils/Fonts'
import { IntlProvider, FormattedMessage } from 'react-intl'
import frTranslations from '../../Translations/fr'
import enTranslations from '../../Translations/en'
import Debug from '../Form/debug'

const FunnelLayout = (props) => {
    const { apiData } = props
    const colors = getColorTheme(props.school ? props.school.code : '')
    const theme = extendTheme({
        colors,
        fonts: {
            // heading: 'AW Conqueror Didot'
        }
    })
    const logo = () => {
        return (
            props.school.logoUrl ?
                props.school.logoUrl
                :
                getLogo(
                    props.school ? props.school.code : ''
                )
        )
    }

    const translations = {
        "en": enTranslations,
        "fr": frTranslations
    }

    return (
        <IntlProvider
            messages={translations[props.locale]} locale={props.locale} defaultLocale='fr'
        >
            <ChakraProvider theme={theme}>
                <Fonts />
                <Helmet>
                    <link rel='icon' href={getFavicon(props.school.code)} />
                </Helmet>
                <Grid
                    templateColumns={{
                        base: '100%',
                        lg: '300px 1fr',
                        xl: '320px 1fr'
                    }}
                    minH='100vh'
                >
                    <Box>
                        <Box
                            position='sticky'
                            top={10}
                            p={{ base: 5, lg: 10 }}
                        >
                            <Flex
                                alignItems='center'
                                justifyContent='space-between'
                                wrap='wrap'
                            >
                                <Image
                                    w={{ base: '100px', lg: '100%' }}
                                    mr={{ base: 1, lg: 0 }}
                                    src={logo() ? logo() : ''}
                                />
                            </Flex>
                            <Box
                                mt={4}
                                fontSize='12px'
                                color='gray.400'
                                display={process.env.REACT_APP_ENV === 'production' ? 'none' : 'block'}
                            >ENV  : {process.env.REACT_APP_ENV} </Box>

                        </Box>

                    </Box>
                    <Box
                        bg='gray.50'
                        p={{ base: 10, lg: 15 }}
                        px={{ base: 1, lg: '10%' }}
                    >
                        {props.children}
                        <Footer data={props} apiData={apiData} locale={props.locale} />
                    </Box>
                </Grid>
            </ChakraProvider>
        </IntlProvider>
    )
}

export default FunnelLayout