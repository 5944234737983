import { Box, Heading, Stack } from '@chakra-ui/layout'
import React from 'react'

const FieldGroup = ({ children, title }) => {
    return(
        <Box
                p={4}
                    border='solid 1px'
                    borderColor='gray.200'
                    borderRadius='md'
                    bg='whiteAlpha.800'
        >
            <Heading
                fontSize={{ base:'md', lg:'md'}}
                fontWeight='normal'
                mb={3}
                color='gray.500'
                textTransform='uppercase'
            >
                <Box
                    as='span'
                    bg='whiteAlpha.800'
                >
                    { title }
                </Box>
            </Heading>
            <Stack>
            { children }

            </Stack>
        </Box>
    )
}
 export default FieldGroup