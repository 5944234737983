import { useState, useEffect } from 'react'
import { Button, Box, HStack, Input } from '@chakra-ui/react'
import axios from 'axios';

export const DigitsForm = ({ handleValidDigits, id }) => {

    const [values, setValues] = useState([]);
    const [changeCount, setChangeCount] = useState(0);

    useEffect(() => {
        const handlePaste = (event) => {
            let textPasted = event.clipboardData.getData('text');
            let textPastedArray = textPasted.split('').slice(0, 6);
            setValues(textPastedArray.map(item => parseInt(item)).filter(item => (typeof item === 'number' && item > 0)));
        }
        window.addEventListener('paste', handlePaste)
        return () => {
            window.removeEventListener('paste', handlePaste)
        };
    })

    // useEffect(() => {
    //     console.log('values hase just changed')
    //     // setValues(values);
    //     setChangeCount(changeCount + 1)
    // }, [values])

    const DigitInput = ({ index, value, id }) => (
        <>
            <Input

                key={value}
                id={`digit-input-field-${index}`}
                value={value}
                minLength={1}
                maxLength={2}
                min={0}
                max={9}
                onBlur={() => {
                    // console.log(`#digit-input-${parseInt(index) + 1}`, document.querySelector(`#digit-input-${index + 1}`))
                }}
                onChange={(e) => {
                    const value = e.target.value;
                    let updatedValues = values;
                    updatedValues[index] = value ? parseInt(value) : ``;
                    setValues([...updatedValues]);

                    // if (value !== '') {
                    setTimeout(
                        () => {
                            if (value !== '') {
                                const nextField = document.querySelector(`#digit-input-field-${parseInt(index) + 1}`);
                                if (nextField) {
                                    nextField.focus();
                                    nextField.select();
                                }
                            } else {
                                const prevField = document.querySelector(`#digit-input-field-${parseInt(index) - 1}`);
                                if (prevField) {
                                    prevField.focus();
                                    prevField.select();
                                }
                            }
                        }, 100
                    )


                }}

                w={'28px'}
                textAlign='center'
                fontSize='md'
                p={1}
                bg={'white'}
                type="number"
                size="lg"

                // rounded={"none"}
                roundedRight={index === 5 ? `md` : `none`}
                roundedLeft={index === 0 ? `md` : `none`}
            />
        </>
    )
    const InputStack = ({ values }) => {
        let stack = []
        for (const i in [1, 2, 3, 4, 5, 6]) {
            stack.push(
                <DigitInput
                    value={values[i]}
                    key={`digit-input-${i}-${values[i]}`}
                    id={`digit-input-${i}-${values[i]}`}
                    index={parseInt(i)}
                />
            )
        }
        return stack
    }
    const handleSubmit = async () => {
        try {
            alert(JSON.stringify(values, null, 1))
            // const request = await axios.post(`${process.env.REACT_APP_API_URL}/valid-account-creation-digit-code/${id}`, { code: values });
            handleValidDigits();
        } catch (error) {
            console.log(error);
        }
    }

    const isInputValid = () => {
        if (values.length < 6) return false
        else {
            return values.filter(item => typeof (item) === 'number').length === 6
        }
    }

    return (
        <Box>
            <HStack spacing={0} py={4}>
                {/* <DigitInput /> */}
                <InputStack values={values} key={values.join('-')} />
            </HStack>
            <Button
                onClick={() => handleSubmit()}
                disabled={!isInputValid()}
            >
                Valider
            </Button>
        </Box>
    )
}