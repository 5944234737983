import { useState } from 'react'
import axios from 'axios';
import { Formik, Form, Field } from "formik"
import * as yup from 'yup';
import { Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, SimpleGrid, Stack } from '@chakra-ui/react'
import Debug from '../Components/Form/debug';
import { GoEye, GoEyeClosed } from "react-icons/go";


export const PasswordCreationForm = ({ id, handleValidAccountCreated, userData }) => {
    const [showRealPassword, setShowRealPassword] = useState(false);
    return (
        <>
            <Formik
                initialValues={{ password: '', passwordConfirmation: '' }}
                onSubmit={async (values, actions) => {
                    console.log(values)
                    handleValidAccountCreated();
                    const request = await axios.post(
                        `${process.env.REACT_APP_API_URL}/createStudentAccount/${id}`,
                        values
                    )
                }}
                validationSchema={
                    yup.object().shape({
                        password: yup
                            .string('Must be a string')
                            .min(8, 'Votre mot de passe doit faire à minima 8 carractère')
                            .matches(
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                "Il doit contenir à minima, une majuscule, une minuscule, un chiffre et un caractère spécial"
                            )
                            .test("containing-hints-firstname", "Le mot de passe ne doit pas ressembler à votre prénom",
                                function (value) {
                                    return (
                                        value &&
                                        value.indexOf(userData.firstName.slice(0, 4)) === -1
                                        && value.toUpperCase().indexOf(userData.firstName.toUpperCase().slice(0, 4)) === -1
                                        && value.toLowerCase().indexOf(userData.firstName.toLowerCase().slice(0, 4)) === -1
                                    )
                                        ? true : false
                                })
                            .test("containing-hints-lastname", "Le mot de passe ne doit pas ressembler à votre nom",
                                function (value) {
                                    return (
                                        value &&
                                        value.indexOf(userData.lastName.slice(0, 4)) === -1
                                        && value.toUpperCase().indexOf(userData.lastName.toUpperCase().slice(0, 4)) === -1
                                        && value.toLowerCase().indexOf(userData.lastName.toLowerCase().slice(0, 4)) === -1
                                    )
                                        ? true : false
                                })
                            .test("containing-hints-email", "Le mot de passe ne doit pas ressembler à votre email",
                                function (value) {
                                    return (
                                        value &&
                                        value.indexOf(userData.email.slice(0, 4)) === -1
                                        && value.toUpperCase().indexOf(userData.email.toUpperCase().slice(0, 4)) === -1
                                        && value.toLowerCase().indexOf(userData.email.toLowerCase().slice(0, 4)) === -1
                                    )
                                        ? true : false
                                })
                            .required(`Un mot de passe est requis`)
                        ,
                        passwordConfirmation: yup
                            .string()
                            .oneOf([yup.ref('password'), null], `La confirmation n'est pas identique.`)
                            .required('La confirmation est requise')

                    })

                }
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                    isValid,
                    handleChange,
                    handleBlur
                }) => (
                    <Form>
                        <Stack spacing={4}>
                            <Debug data={userData} />
                            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={{ base: 2, lg: 4 }}>
                                <Field name='password'>
                                    {({ field, meta }) => (
                                        <FormControl isInvalid={meta.touched && meta.error}>
                                            <FormLabel>Mot de passe :</FormLabel>
                                            <Box position="relative">
                                                <Input
                                                    {...field}
                                                    type={!showRealPassword ? 'password' : 'text'}
                                                    autoComplete="new-password"
                                                    required
                                                />
                                                <Button
                                                    pos='absolute'
                                                    right='0'
                                                    top='0'
                                                    variant={'shadow'}
                                                    size="sm"
                                                    h={"full"}
                                                    zIndex="banner"
                                                    _focus={{
                                                        shadow: "none"
                                                    }}
                                                    onClick={() => setShowRealPassword(!showRealPassword)}
                                                >
                                                    {!showRealPassword ? <GoEye /> : <GoEyeClosed />}
                                                </Button>
                                            </Box>

                                            <FormErrorMessage>{meta.error}</FormErrorMessage>

                                            <FormHelperText>
                                                Ce mot de passe doit faire au moins 8 caractères et contenir au moins 1 minuscule, 1 majuscule et un caractère spécial.
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='passwordConfirmation'>
                                    {({ field, meta }) => (
                                        <FormControl isInvalid={meta.touched && meta.error}>
                                            <FormLabel>Confirmation du mot de passe :</FormLabel>
                                            <Input
                                                {...field}
                                                type={!showRealPassword ? 'password' : 'text'}
                                                autoComplete="new-password"
                                                required
                                            />
                                            <FormErrorMessage>{meta.error}</FormErrorMessage>

                                        </FormControl>
                                    )}
                                </Field>

                            </SimpleGrid>
                            <Box>
                                <Button
                                    disabled={
                                        Object.keys(touched).length === 0
                                        || Object.keys(errors).length > 0
                                        || Object.keys(values).length === 0
                                    }
                                    type="submit">Valider la création du mot de passe</Button>
                            </Box>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
    )
}