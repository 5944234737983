import React from 'react'

import { Box, Button, Heading, List, ListItem } from '@chakra-ui/react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'



const TeacherFormNav = ({}) => {
    const items = [
        { label: "Photo", id: 'profilepicture', fieldName: 'profilpicture' },
        { label: "Civilité", id: 'civility', fieldName: 'civility' },
        { label: "Coordonnées", id: 'contact', fieldName: 'contact' },
        { label: "Profil et parcours", id: 'diplomas', fieldName: 'diplomas' },
        { label: "Paie et facturation", id: 'billing', fieldName: 'billing' },
        { label: "Documents", id: 'documents', fieldName: 'documents' },
        { label: "Chartes", id: 'charts', fieldName: 'charts' },

    ]

    const scrollTo = ( elementId ) => {
        scroller.scrollTo( elementId, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        })
      }

    return (
        <Box>
            <Heading
                fontSize='sm'
                textTransform='uppercase'
                letterSpacing='wider'
                mb={2}
            >
                <Box
                    as='span'
                    borderBottom='solid 2px'
                    borderBottomColor='brand.1.500'
                >
                Étapes
                </Box>&nbsp;:
            </Heading>
            <List
                fontSize='sm'
            >
                {items.map(item =>
                    // settings[item.fieldName] && settings[item.fieldName].isEnabled ? 
                    <ListItem key={item.id}>
                        <Button
                            onClick={(e)=>{
                                e.preventDefault();
                                scrollTo( item.id );
                            }}
                            as={'a'}
                            href={`#${item.id}`}
                            size='sm'
                            variant='link'
                        >
                            {item.label}
                        </Button>
                    </ListItem>
                    // : null
                )}
            </List>
        </Box>
    )
}

export default TeacherFormNav