import React, { useState } from 'react'
import { loadStripe } from "@stripe/stripe-js";
import {
  Box,
  Button
} from '@chakra-ui/react'
import { Icon } from "@chakra-ui/react"

import { useIntl } from 'react-intl'
import axios from 'axios';


const StripeButton = (props) => {
  const { stripe_priceNewId, stripe_priceRenewId, stripe_publicKey, stripe_publicKeyDev } = props.school

  const { type } = props
  const locale = useIntl()['locale']

  let stripeApiKey = process.env.REACT_APP_ENV === `production` ? stripe_publicKey : stripe_publicKeyDev
  // const stripePromise = loadStripe(
  //   getSchoolPriceStripId(props.school.code).publicKey,
  // )

  const handleGetSessionId = async (event) => {

    const stripePromise = loadStripe(stripeApiKey);

    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/inscription_biel.php/payment/init`,
      {
        studentId: `${props.id}`,
        publicKey: stripeApiKey,
        // publicKey: "pk_test_51MgMAdGslzqITv2E5hVWCJ68yu7V0izHWgbqVsKxLYLoS0RqxpM81jQWW53FQ8GJ47cdbMFEH6aGWt8OEMp1jM5z00DO0INeTE",
        stripePriceId: type === 'new' ? stripe_priceNewId : stripe_priceRenewId,
        successUrl: `/${locale}/payment/success/${props.id}`,
        cancelUrl: `/${locale}/payment/${props.id}`,
        schoolCode: props.school.code,
        env: process.env.REACT_APP_ENV
      }
    );

    console.log(`response`, response)

    const result = await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });

  };


  return (
    <>
      {/* key : {stripeApiKey} */}
      <Button
        // onClick={() => { handleClick() }}
        onClick={() => handleGetSessionId()}
        color='white'
        bg='brand.1.500'
        _hover={{
          bg: 'brand.1.600'
        }}
      >
        <Icon viewBox="0 0 472.001 472.001" w={8} h={6} color="white" mr={2} color='white'>

          <path fill="currentColor" d="M431.313,81.376H40.688C18.252,81.376,0,99.628,0,122.063v227.875c0,22.436,18.252,40.688,40.688,40.688h390.625
              c22.436,0,40.688-18.252,40.688-40.688V122.063C472.001,99.627,453.749,81.376,431.313,81.376z M40.688,93.376h390.625
              c15.817,0,28.688,12.869,28.688,28.687v25.734h-448v-25.734C12.001,106.245,24.87,93.376,40.688,93.376z M431.313,378.625H40.688
              C24.87,378.625,12,365.756,12,349.938V176.796h448v173.142C460.001,365.756,447.132,378.625,431.313,378.625z"/>
          <path fill="currentColor" d="M143.369,243.591H73.344C60.472,243.591,50,254.063,50,266.935v27.75c0,12.871,10.472,23.344,23.344,23.344h70.025
              c12.872,0,23.344-10.473,23.344-23.344v-27.75C166.713,254.063,156.241,243.591,143.369,243.591z M154.713,294.685
              c0,6.254-5.089,11.344-11.344,11.344H73.344c-6.255,0-11.344-5.09-11.344-11.344v-27.75c0-6.254,5.089-11.344,11.344-11.344
              h70.025c6.255,0,11.344,5.09,11.344,11.344V294.685L154.713,294.685z"/>
          <path fill="currentColor" d="M417.456,243.591H222.195c-3.313,0-6,2.688-6,6s2.687,6,6,6h195.261c3.312,0,6-2.688,6-6S420.771,243.591,417.456,243.591
              z"/>
          <path fill="currentColor" d="M315.995,290.418h-93.8c-3.313,0-6,2.687-6,6s2.687,6,6,6h93.8c3.312,0,6-2.687,6-6S319.309,290.418,315.995,290.418z" />

        </Icon>
        {
          locale === 'en' ?
            'Pay the fees'
            :
            'Accéder au règlement'
          // props.student.type === 'new'
          //   ?
          //   ` frais de dossier`
          //   :
          //   props.student.school.code === 'eac' || props.student.school.code === 'ing' ? ` frais de réinscription` : ` arrhes de réinscription`
        }
      </Button>
    </>
  )
}
export default StripeButton