// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getAuth, FacebookAuthProvider } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAUl5IxWhFaVdUZZ5nrfN_vMi2ixI9Qt-8",
    authDomain: "ad-auth-351d3.firebaseapp.com",
    projectId: "ad-auth-351d3",
    storageBucket: "ad-auth-351d3.appspot.com",
    messagingSenderId: "317162826327",
    appId: "1:317162826327:web:20bec29552fa1b7a381112"
};

// Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export const auth = getAuth(firebase_app)
export default firebase_app;
