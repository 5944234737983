import React, { useState } from 'react'
import {
  Box,
  Button,
} from '@chakra-ui/react'
import ReactJson from 'react-json-view'

const Debug = ({ data }) => {

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  if (process.env.REACT_APP_ENV !== 'development') {
    return null
  }
  return (
    <Box py={2}
      maxW='800px'
      overflow='scroll'
    >
      <Button variant='outline' colorScheme='orange' onClick={handleToggle}>
        Debug
      </Button>
      <Box mt={4} display={show ? 'block' : 'none'}>
        <ReactJson src={data} />
      </Box>
    </Box>
  )
}

export default Debug