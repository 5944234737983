import { FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Divider, Stack, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select'
import axios from 'axios';
import { Field, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react'
import Debug from '../Form/debug';

const ALevelSpecialityOptions = ({
    data,
    values,
    setHasUpdates,
    setFieldValue
}) => {

    if (!data || !data.options || !data.options) return null

    return (
        <>
            {/* <Debug data={data} /> */}
            <Divider />
            <Text>Ce baccalauréat comportait des options, nous aimerions les connaître.</Text>
            {/* <Debug data={values.aLevelSpecialityOptions} /> */}
            <FieldArray
                name={`aLevelSpecialityOptions`}
                render={
                    arrayHelpers => (
                        <>
                            <Stack>
                                {
                                    values.aLevelSpecialityOptions.map(
                                        (item, index) =>

                                            <Box>
                                                <FormLabel>
                                                    Quelle était votre option n°{index + 1}
                                                </FormLabel>
                                                <Field name={`aLevelSpecialityOptions.${index}`}>
                                                    {({ field, meta }) => (
                                                        <Select
                                                            {...field}
                                                            placeholder={index === 0 ? `Choisissez votre première option` : `Choisissez votre seconde option`}
                                                            onChange={(e) => {
                                                                setHasUpdates(true);
                                                                setFieldValue(field.name, e.target.value)
                                                            }}
                                                        >
                                                            {
                                                                data.options.map(item => <option key={item.id} value={item.id}>{item.libelle}</option>)
                                                            }
                                                        </Select>
                                                    )}
                                                </Field>
                                            </Box>
                                    )
                                }
                            </Stack>
                        </>
                    )
                }
            />
        </>
    )
}

export default ALevelSpecialityOptions