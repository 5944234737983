import React from 'react';
import Helmet from 'react-helmet';
import {
    ChakraProvider,
    CSSReset,
    Box,
    Text,
    Flex,
    Image,
} from '@chakra-ui/react';
import getFavicon from './Utils/getFavicon'

export const ErrorScreen = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <title>Application des admission ADE</title>
                <link rel='icon' href={getFavicon('default')} />
            </Helmet>
            <CSSReset />
            <Flex
                justify='center'
                alignItems='center'
                h='100vh'
            >
                <Flex
                    wrap='wrap'
                >
                    <Box w='100%'>
                        <Image mx='auto' h='auto' w='auto' src="https://static.wixstatic.com/media/461157_bb44ba9a3f1047e3874a29e924f658e7~mv2.jpg/v1/crop/x_106,y_0,w_1543,h_1241/fill/w_194,h_155,al_c,q_80,usm_0.66_1.00_0.01/ad-education.webp" />
                    </Box>
                    <Text
                        textAlign='center'
                        w='100%'
                        color='red.500'
                    >
                        Erreur : cette adresse ne correspond à aucune fonctionnalité. Si vous arrivez ici depuis un lien reçu par email, vérifiez qu'il n'a pas été tronqué.
                    </Text>
                </Flex>
            </Flex>
        </ChakraProvider>
    )
}