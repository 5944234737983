import React from 'react'
import {
    Box,
    FormControl,
    FormErrorMessage,
    Text,
    Stack,
    Checkbox,
} from '@chakra-ui/react'

import { Field } from "formik";

const Step4 = formikProps => {
    const { errors, touched, values, setFieldValue } = formikProps;
    const fields = [
        {
            name: "schoolChart",
            label: "Règlement intérieur"
        },
        {
            name: "wifiChart",
            label: "Charte wifi ADE"
        },
        {
            name: 'imageRights',
            label: "Droit à l'image"
        },
        {
            name: "exactInfoCertificate",
            label: "J'atteste que les informations communiquées sont réelles et exactes"
        }
    ]
    return (
        <Box>
            {/* <pre>
                { JSON.stringify( {errors, touched }, null, 2 )}
            </pre> */}
            <Stack spacing={5}>
                {fields.map(item =>
                    <>
                        <Field name={item.name}>
                            {({ field, form }) => (
                                <FormControl
                                    isInvalid={touched[item.name] && errors[item.name]}
                                >
                                    <Box
                                        as='label'
                                        display='block'
                                        bg='white'
                                        borderRadius='3px'
                                        border='solid 1px'
                                        borderColor='gray.100'
                                        p={3}
                                        radius={1}
                                        htmlFor={item.name}
                                    >
                                        <Checkbox
                                            {...field}
                                            id={item.name}
                                        >
                                            <Text>{item.label}</Text>
                                        </Checkbox>
                                    </Box>
                                    <FormErrorMessage>{errors[item.name]}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                    </>

                )}
            </Stack>
        </Box>
    )
}

export default Step4