import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Heading,
    Input,
    // Select,
    SimpleGrid,
    Stack,
    Text,
    Checkbox,
    CheckboxGroup,
    HStack,
    Select,
} from '@chakra-ui/react'
import { Field, FieldArray, ErrorMessage } from "formik";
import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import getAge from '../../Utils/getAge'
import PhoneNumberField from './PhoneNumberField'

import { AddressField } from '../Address/AddressField'
import { handleRecordAdressComponents } from '../Address/handleRecordAdressComponents';
import Debug from './debug';
import { Step2ErrorNotifications } from './Step2ErrorNotifications';

const Step2 = props => {
    const { values, errors, touched, setFieldValue, setFieldTouched } = props;
    const [fill, setFill] = useState(false)
    const translations = useIntl()['messages'];
    const locale = useIntl()['locale'];


    useEffect(() => {
        setFieldValue('mailRecipient', [`${values.firstName} ${values.lastName} ${values.email}`])
        // setFieldValue('responsibles', {'email':''})
        if (getAge(values.birthDate) > 18) {
            setFieldValue('validateResponsibles', true)
        }

        // 
    }, []);

    const formatResponsiblesRecipientsValue = (responsibles) => {
        let formattedValues = []
        for (let index = 0; index < responsibles.length; index++) {
            const person = responsibles[index];
            formattedValues.push(`${person.firstName} ${person.lastName} ${person.email}`)
        }
        formattedValues.push(`${values.firstName} ${values.lastName} ${values.email}`)
        return formattedValues
    }

    return (
        <Box>
            <Stack spacing={5}>
                <Heading
                    as='h3'
                    fontSize={{ base: 17, lg: 22 }}
                >
                    {values.responsibles && values.responsibles.length < 2 ?
                        <Text as='span'>
                            <FormattedMessage id="legal.representative.plural" />
                        </Text>
                        :
                        <Text as='span'><FormattedMessage id="legal.representative.plural" /></Text>
                    }
                </Heading>

                {locale === "fr" ?
                    <Text fontSize='14px' color='gray.600'>Tous les champs marqués{' '}
                        <Text as="span" color='red.700'>*</Text>
                        {' '}sont obligatoires.
                    </Text>
                    :
                    <Text fontSize='14px' color='gray.700'>All the fields marked with{' '}
                        <Text as="span" color='red.700'>*</Text>
                        {' '}are mandatory.
                    </Text>
                }

                <Box>
                    <Text color='gray.600'>
                        {getAge(values.birthDate) < 18 ?
                            <>
                                <FormattedMessage id="legal.representative.notice.minor" />
                                <Text color='red.800'>
                                    <FormattedMessage id="legal.representative.notice.minor.2" />
                                </Text>
                            </>
                            :

                            <FormattedMessage id="legal.representative.notice" />
                        }
                    </Text>
                </Box>

                {/* Self studies payer */}
                {values.responsibles.length === 0 && values.selfPayStudies ?
                    <Box>
                        <FormattedMessage id="studies.payer.warning" />
                    </Box>
                    : null}



                {!fill ?
                    <FieldArray name="responsibles">
                        {({ push, remove, index }) =>
                            <React.Fragment>
                                {values.responsibles && values.responsibles.length > 0 && values.responsibles.map((responsible, index) =>
                                    <Stack
                                        bg='white'
                                        p={{ base: 2, lg: 8 }}
                                        spacing={6}
                                        borderRadius='md'
                                        boxShadow='sm'
                                        key={`Reponsible-${index}`}
                                    >
                                        {index + 1 === values.responsibles.length ?
                                            <Flex justify='flex-start'>
                                                <Button
                                                    onClick={() => {
                                                        remove(index);
                                                        setFieldValue('selfPayStudies', true);
                                                    }}
                                                    size='sm'
                                                >
                                                    <CloseIcon w={3} h={3} mr={1} />
                                                    <FormattedMessage id="legal.representative.remove" />
                                                </Button>
                                            </Flex>
                                            : null}
                                        <SimpleGrid
                                            columns={{ base: 1, lg: 2 }}
                                            gap={4}
                                        >
                                            <Field name={`responsibles[${index}].firstName`}>
                                                {({ field, form }) => (
                                                    <FormControl
                                                        isInvalid={
                                                            errors.responsibles
                                                            && errors.responsibles[index]
                                                            && errors.responsibles[index].firstName
                                                            && touched
                                                            && touched.responsibles
                                                            && touched.responsibles[index]
                                                            && touched.responsibles[index].firstName
                                                        }
                                                    >
                                                        <FormLabel htmlFor={`responsibles[${index}].firstName`}>
                                                            <FormattedMessage id="label.firstname" />
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                        </FormLabel>
                                                        <Input {...field} id={`responsibles[${index}].firstName`} placeholder={translations['placeholder.responsible.firstname']} bg='white' />
                                                        <ErrorMessage name={`responsibles[${index}].firstName`}>
                                                            {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                        </ErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name={`responsibles[${index}].lastName`}>
                                                {({ field, form }) => (
                                                    <FormControl
                                                        isInvalid={
                                                            errors.responsibles
                                                            && errors.responsibles[index]
                                                            && errors.responsibles[index].lastName
                                                            && touched
                                                            && touched.responsibles
                                                            && touched.responsibles[index]
                                                            && touched.responsibles[index].lastName
                                                        }
                                                    >
                                                        <FormLabel htmlFor={`responsibles[${index}].lastName`}>
                                                            <FormattedMessage id="placeholder.lastname" />
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                        </FormLabel>
                                                        <Input {...field} id={`responsibles[${index}].lastName`} placeholder={translations['placeholder.responsible.lastname']} bg='white' />
                                                        <ErrorMessage name={`responsibles[${index}].lastName`}>
                                                            {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                        </ErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </SimpleGrid>


                                        <Debug data={values.responsibles[0]} />
                                        <SimpleGrid
                                            columns={{ base: 1, lg: 2 }}
                                            gap={4}
                                        >

                                            <Field name={`responsibles[${index}].address`}>
                                                {({ field, form, meta }) => (
                                                    <FormControl>
                                                        <FormLabel htmlFor="address">
                                                            {/* <FormattedMessage id="label.birthplace" /> */}
                                                            Numéro et rue de résidence :
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                            <Input {...field} />
                                                        </FormLabel>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name={`responsibles[${index}].city`}>
                                                {({ field, form, meta }) => (
                                                    <FormControl>
                                                        <FormLabel htmlFor="city">
                                                            {/* <FormattedMessage id="label.birthplace" /> */}
                                                            Ville :
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                            <Input {...field} />
                                                        </FormLabel>
                                                    </FormControl>
                                                )}
                                            </Field>

                                            <Field name={`responsibles[${index}].postalCode`}>
                                                {({ field, form, meta }) => (
                                                    <FormControl>
                                                        <FormLabel htmlFor="postalCode">
                                                            {/* <FormattedMessage id="label.birthplace" /> */}
                                                            Code postal :
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                            <Input {...field} />
                                                        </FormLabel>
                                                    </FormControl>
                                                )}
                                            </Field>

                                            <Field name={`responsibles[${index}].country`}>
                                                {({ field, form, meta }) => (
                                                    <FormControl>
                                                        <FormLabel htmlFor="country">
                                                            {/* <FormattedMessage id="label.birthplace" /> */}
                                                            Pays :
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                            <Input {...field} />
                                                        </FormLabel>
                                                    </FormControl>
                                                )}
                                            </Field>



                                            {/* 
                                            <Field name={`responsibles[${index}].address`}>
                                                {({ field, form, meta }) => (
                                                    <FormControl
                                                        isInvalid={
                                                            errors.responsibles
                                                            && errors.responsibles[index]
                                                            && errors.responsibles[index].address
                                                            && touched
                                                            && touched.responsibles
                                                            && touched.responsibles[index]
                                                            && touched.responsibles[index].address
                                                        }
                                                    >
                                                        <FormLabel htmlFor={`responsibles[${index}].address`}>
                                                            <FormattedMessage id="label.address" />
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                        </FormLabel>

                                                        <AddressField
                                                            // initialValue={meta.value}
                                                            initialValue={`${values.responsibles[index].address ? values.responsibles[index].address : ''} ${values.responsibles[index].postalCode ? values.responsibles[index].postalCode : ''} ${values.responsibles[index].city ? values.responsibles[index].city : ''} ${values.responsibles[index].country ? values.responsibles[index].country : ''}`}
                                                            newAddressCallback={
                                                                (adressObject) => {
                                                                    handleRecordAdressComponents(
                                                                        adressObject,
                                                                        setFieldValue,
                                                                        setFieldTouched,
                                                                        `responsibles[${index}].`,
                                                                        `responsibles[${index}].address`
                                                                    )
                                                                }
                                                            }
                                                        />
                                                        <ErrorMessage name={`responsibles[${index}].address`}>
                                                            {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                        </ErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>

                                            <Field name={`responsibles[${index}].postalCode`}>
                                                {({ field, form, meta }) => (
                                                    <FormControl
                                                        isInvalid={meta.error && meta.touched}
                                                    >
                                                        <FormLabel htmlFor="birthPlace_postalCode">
                                                            <FormattedMessage id="label.postalcode.confirmation" />
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                        </FormLabel>
                                                        <Input
                                                            bg='white'
                                                            {...field}
                                                        />
                                                        <FormErrorMessage color='red.700'>
                                                            <FormattedMessage id='label.postal.code.missing.notice' />
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field> */}

                                        </SimpleGrid>

                                        <Field name={`responsibles[${index}].phone`}>
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        errors.responsibles
                                                        && errors.responsibles[index]
                                                        && errors.responsibles[index].phone
                                                        && touched
                                                        && touched.responsibles
                                                        && touched.responsibles[index]
                                                        && touched.responsibles[index].phone
                                                    }
                                                >
                                                    <FormLabel htmlFor={`responsibles[${index}].phone`}>
                                                        <FormattedMessage id="label.phone.number" />
                                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                    </FormLabel>

                                                    <PhoneNumberField
                                                        {...field}
                                                        phone={values.responsibles[index].phone}
                                                        phonePrefix={values.responsibles[index].phonePrefix}
                                                        setPhoneValue={
                                                            (phone) => {
                                                                setFieldValue(`responsibles[${index}].phone`, phone, true, true)
                                                                setFieldTouched(`responsibles[${index}].phone`, true, true)
                                                            }
                                                        }
                                                        setPhonePrefixValue={
                                                            (prefix) => {
                                                                setFieldValue(`responsibles[${index}].phonePrefix`, prefix, true, true)
                                                                setFieldTouched(`responsibles[${index}].phonePrefix`, true, true)
                                                            }
                                                        }
                                                    />

                                                    <ErrorMessage name={`responsibles[${index}].phone`}>
                                                        {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                    </ErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name={`responsibles[${index}].email`}>
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        errors.responsibles
                                                        && errors.responsibles[index]
                                                        && errors.responsibles[index].email
                                                        && touched
                                                        && touched.responsibles
                                                        && touched.responsibles[index]
                                                        && touched.responsibles[index].email
                                                    }
                                                >
                                                    <FormLabel htmlFor={`responsibles[${index}].email`}>
                                                        <FormattedMessage id="label.email" />
                                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                    </FormLabel>
                                                    <Input {...field} id={`responsibles[${index}].email`} type="email" placeholder={translations['placeholder.email']} bg='white' />
                                                    <ErrorMessage name={`responsibles[${index}].email`}>
                                                        {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                    </ErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name={`responsibles[${index}].relationshipType`}>
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        errors.responsibles
                                                        && errors.responsibles[index]
                                                        && errors.responsibles[index].relationshipType
                                                        && touched
                                                        && touched.responsibles
                                                        && touched.responsibles[index]
                                                        && touched.responsibles[index].relationshipType
                                                    }
                                                >
                                                    <FormLabel htmlFor={`responsibles[${index}].email`}>
                                                        <FormattedMessage id="label.relationship" />
                                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                    </FormLabel>
                                                    <Select {...field} id={`responsibles[${index}].relationshipType`} placeholder={translations['label.choose']} bg='white'>
                                                        <option value='MOTHER'>
                                                            {translations["placeholder.mother"]}
                                                        </option>
                                                        <option value='FATHER'>
                                                            {translations["placeholder.father"]}
                                                        </option>
                                                        <option value='OTHER'>
                                                            {translations["placeholder.other"]}
                                                        </option>
                                                    </Select>
                                                    <ErrorMessage name={`responsibles[${index}].relationshipType`}>
                                                        {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                    </ErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        {values.responsibles && values.responsibles[index] && values.responsibles[index].relationshipType && values.responsibles[index].relationshipType.toLowerCase() === 'other' ?

                                            <Field name={`responsibles[${index}].relationshipOther`}>
                                                {({ field, form }) => (
                                                    <FormControl
                                                        isInvalid={
                                                            errors.responsibles
                                                            && errors.responsibles[index]
                                                            && errors.responsibles[index].relationshipOther
                                                            && touched
                                                            && touched.responsibles
                                                            && touched.responsibles[index]
                                                            && touched.responsibles[index].relationshipOther
                                                        }
                                                    >
                                                        <FormLabel htmlFor={`responsibles[${index}].relationshipOther`}>
                                                            <FormattedMessage id="placeholder.relationshipOther" />
                                                            <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                        </FormLabel>
                                                        <Input {...field} id={`responsibles[${index}].relationshipOther`} bg='white' />
                                                        <ErrorMessage name={`responsibles[${index}].relationshipOther`}>
                                                            {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                        </ErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>

                                            : null}

                                        <Field name={`responsibles[${index}].isStudiesPayer`}>

                                            {({ field, form, meta }) => (
                                                <FormControl
                                                    isInvalid={
                                                        errors.responsibles
                                                        && errors.responsibles[index]
                                                        && errors.responsibles[index].isStudiesPayer
                                                        && touched
                                                        && touched.responsibles
                                                        && touched.responsibles[index]
                                                        && touched.responsibles[index].isStudiesPayer
                                                    }
                                                >
                                                    <FormLabel htmlFor={`responsibles[${index}].isStudiesPayer`}>
                                                        <FormattedMessage id="placeholder.isStudiesPayer" />
                                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                                    </FormLabel>
                                                    <Select
                                                        value={meta.value}

                                                        onChange={(e) => {
                                                            let isPayer = e.target.value === 'true' ? true : false;

                                                            if (isPayer) {
                                                                // case responsible is payer
                                                                setFieldValue('selfPayStudies', false);
                                                                setFieldValue(`responsibles[${index}].isStudiesPayer`, true, true);
                                                            } else {
                                                                // case responsible is not payer
                                                                if (index === 0) {
                                                                    // Case first reponsible
                                                                    if (values.responsibles[1] && values.responsibles[1].isStudiesPayer) {
                                                                        setFieldValue('selfPayStudies', false);
                                                                    } else { setFieldValue('selfPayStudies', true); }
                                                                } else {
                                                                    // case second responsible
                                                                    if (values.responsibles[0].isStudiesPayer) {
                                                                        setFieldValue('selfPayStudies', false);
                                                                    } else { setFieldValue('selfPayStudies', true); }
                                                                }
                                                                setFieldValue(`responsibles[${index}].isStudiesPayer`, false, true);
                                                            }
                                                        }}
                                                        id={`responsibles[${index}].isStudiesPayer`}
                                                        placeholder={translations['label.choose']}
                                                        bg='white'
                                                    >
                                                        <option value={true}>
                                                            {locale === "fr" ? 'Oui' : 'Yes'}
                                                        </option>
                                                        <option value={false}>
                                                            {locale === "fr" ? 'Non' : 'No'}

                                                        </option>
                                                    </Select>
                                                    <ErrorMessage name={`responsibles[${index}].isStudiesPayer`}>
                                                        {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                    </ErrorMessage>
                                                </FormControl>
                                            )}

                                        </Field>

                                        <Field name={`responsibles[${index}].job`}>
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        errors.responsibles
                                                        && errors.responsibles[index]
                                                        && errors.responsibles[index].job
                                                        && touched
                                                        && touched.responsibles
                                                        && touched.responsibles[index]
                                                        && touched.responsibles[index].job
                                                    }
                                                >
                                                    <FormLabel htmlFor={`responsibles[${index}].job`}>
                                                        <FormattedMessage id="placeholder.job" />
                                                    </FormLabel>
                                                    <Input {...field} id={`responsibles[${index}].job`} bg='white' />
                                                    <ErrorMessage name={`responsibles[${index}].job`}>
                                                        {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                                    </ErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                    </Stack>
                                )}


                                <Box
                                    display={values.responsibles && values.responsibles.length === 2 ? 'none' : 'flex'}
                                >
                                    <Button
                                        size='sm'
                                        onClick={() => {
                                            setFieldValue('selfPayStudies', false);
                                            push({ email: '', country: 'FRANCE', phonePrefix: '+33' });
                                        }}
                                    >
                                        <AddIcon w={3} h={3} mr={1} />
                                        <FormattedMessage id="legal.representative.add" />
                                    </Button>
                                </Box>


                            </React.Fragment>
                        }
                    </FieldArray> :

                    values.responsibles && values.responsibles.length > 0 ?
                        <Box
                            bg='white'
                            borderRadius='10px'
                            overflow='hidden'
                            border='solid 1px'
                            borderColor='gray.200'
                        >
                            {
                                values.responsibles.map((responsible, index) =>
                                    <Box
                                        p={5}
                                        border='solid 1px'
                                        borderColor='gray.200'
                                    >
                                        <Text fontWeight='bold'>{`${responsible.firstName ? responsible.firstName : ''} ${responsible.lastName ? responsible.lastName : ''}`}</Text>
                                        <Text fontSize='15px'>{`${responsible.email ? responsible.email : ''} / ${responsible.address ? responsible.address : ''} ${responsible.city ? responsible.city : ''}`}</Text>
                                    </Box>
                                )}
                        </Box>
                        : null}



                {values.responsibles && values.responsibles.length > 0 && !errors.responsibles ?
                    <Box>
                        <Button
                            colorScheme={fill ? 'blue' : 'blue'}
                            size='md'
                            onClick={() => {
                                setFill(!fill)
                                setFieldValue('mailRecipient', formatResponsiblesRecipientsValue(values.responsibles))
                                setFieldValue('validateResponsibles', !fill)
                            }}
                        >
                            {!fill ? <FormattedMessage id="validate.legal.responsibles" /> : <FormattedMessage id="update.legal.responsibles" />}
                        </Button>
                    </Box>
                    : null}

                <Box>
                    {values.responsibles && values.responsibles.length && fill > 0 ?
                        <Field name="mailRecipient">
                            {({ field, form }) => (
                                <FormControl isInvalid={errors.mailRecipient && touched.mailRecipient} mt={4}>
                                    <FormLabel htmlFor="gender">
                                        <FormattedMessage id="label.mail.recipient" />
                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                    </FormLabel>
                                    <CheckboxGroup
                                        colorScheme="blue"
                                        id='mailRecipient'
                                        name='mailRecipient'
                                        bg='white'
                                        defaultValue={values.mailRecipient}
                                    >
                                        <HStack spacing={5} >
                                            <Checkbox
                                                {...field}
                                                isDisabled
                                                bg='white'
                                                borderRadius='2rem'
                                                p={2} px={3}
                                                value={`${values.firstName}${values.lastName} ${values.email}`}
                                            >
                                                {`${values.firstName} ${values.lastName}`}
                                            </Checkbox>
                                            {
                                                values.responsibles && values.responsibles.length > 0
                                                && values.responsibles.map((responsible, index) =>
                                                    responsible.firstName && responsible.lastName ?
                                                        <Checkbox
                                                            {...field}
                                                            // defaultChecked={ values.responsibles.includes( `${values.responsibles[0].firstName} ${values.responsibles[0].lastName} ${values.responsibles[0].email}` ) }
                                                            // isChecked={ true }
                                                            // onChange={ (e)=> console.log(e.target.value)}

                                                            bg='white'
                                                            borderRadius='2rem'
                                                            p={2} px={3}
                                                            value={`${responsible.firstName ? responsible.firstName : ''} ${responsible.lastName ? responsible.lastName : ''} ${responsible.email ? responsible.email : ''}`}
                                                        >
                                                            {`${responsible.firstName} ${responsible.lastName ? responsible.lastName : ''}`}
                                                        </Checkbox>
                                                        : null
                                                )}
                                        </HStack>
                                    </CheckboxGroup>

                                    <FormErrorMessage>{form.errors.mailRecipient}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        : null}
                </Box>
            </Stack>

            <Step2ErrorNotifications touched={touched} errors={errors} />

            {/* <Debug data={values.responsibles} /> */}


        </Box>
    )
}

export default Step2