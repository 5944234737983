import { FormLabel } from '@chakra-ui/form-control';
import { Box, Stack } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Debug from '../Form/debug';
import ALevelSpecialityOptions from './ALevelSpecialityOptions';

const ALevelSpecialityWithOptions = ({
    setFieldValue,
    isDisabled,
    value,
    values,
    setHasUpdates
}) => {
    const [data, setData] = useState(null);
    const [mainCategory, setMainCategory] = useState(null);

    let aLevelYear = parseInt(values.aLevelYear)

    // console.log('aLevelYear', aLevelYear, typeof (aLevelYear))

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                `https://intranet.ad-education.com/inscription_biel.php/seriesoptionsbac/`,
            );

            console.log(result.data);

            setData(result.data);
            if (value) {
                console.log("value", value);
                setMainCategory(getMainCategoryByItemId(result.data))
            } else {
                console.log("no value");
            }
        };

        fetchData();
    }, []);

    const getMainCategoryByItemId = (data) => {
        for (let index = 0; index < data.length; index++) {
            const category = data[index];
            console.log('category', category)

            if (!category.items) continue;

            for (let index2 = 0; index2 < category.items.length; index2++) {
                const element = category.items[index2];
                console.log(element)
                // console.log(element.id, value)
                if (element && element.id === value) {
                    console.log('match');
                    return category.categoryName;
                } else {
                    console.log('no match');
                }

            }

        }
    }

    if (!data || !data.length) return null

    // return <Debug data={[mainCategory]} />

    return (
        <Stack
            spacing={4}
        >

            {/* <Debug data={[values.aLevelSpeciality]} /> */}
            {/* <Debug data={[mainCategory]} /> */}
            <Debug data={[mainCategory]} />

            <Select
                placeholder='Choisissez la spécialité de votre bac'
                // onChange={(e) => setFieldValue(parseInt(e.target.value))}
                onChange={(e) => setMainCategory(e.target.value)}
                bg='white'
                isDisabled={isDisabled}
                value={mainCategory}
            >
                {data && data.length ?
                    data.map(item =>
                        <option key={item.categoryName} value={item.categoryName}>{item.categoryName}</option>
                    )
                    : null}
            </Select>

            {
                (
                    mainCategory && data.filter(item => item.categoryName === mainCategory)[0].items
                )
                    || value && mainCategory

                    ?
                    <Box>

                        <FormLabel>Et votre série ?</FormLabel>
                        <Select
                            placeholder='Choisissez la série de votre bac'
                            onChange={(e) => {
                                setFieldValue(`aLevelSpeciality`, parseInt(e.target.value));
                                setFieldValue(`aLevelSpecialityOptions`, [0, 0]);
                                setHasUpdates(true);
                            }}
                            // onChange={(e) => setMainCategory(e.target.value)}
                            bg='white'
                            isDisabled={isDisabled}
                            value={value ? value : null}
                        >
                            {
                                data.filter(item => item.categoryName === mainCategory)[0].items.map(item =>
                                    aLevelYear >= item.fromYear
                                        && aLevelYear <= item.untilYear
                                        ?
                                        <option key={item.id} value={item.id}>{item.libelle}</option>
                                        : null
                                )
                            }
                        </Select>
                    </Box>
                    : null
            }

            {mainCategory && value && value !== `` ?
                <ALevelSpecialityOptions
                    key={`${mainCategory}-${value}`}
                    values={values}
                    setHasUpdates={setHasUpdates}
                    setFieldValue={setFieldValue}
                    data={
                        data
                            .filter(item => item.categoryName === mainCategory)[0].items
                            .filter(item => item.id === value)[0]
                    }
                />
                : null}
        </Stack>
    )
}

export default ALevelSpecialityWithOptions