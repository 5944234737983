import React, { Component } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import Layout from '../Layouts/student-funnel'
import getFavicon from '../../Utils/getFavicon'
import { navigate } from '@reach/router'
import { Box, Center, Flex, Grid, Heading, Text } from '@chakra-ui/layout'
import ProfileForm from './ProfileForm'
import ProfileFormNav from './Nav'
import { Spinner } from '@chakra-ui/spinner'
import Debug from '../Form/debug'
import { ErrorScreen } from '../../ErrorScreen'
import { useAuth } from '../../Context/Auth'


class ProfileFormLoader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialValues: null,
            isProfilLoaded: false,
            isError: false,
            student: {},
            data: {},
            settings: false
            // settings:{
            //     // ineNumber: { isEnabled: true, value:'1234567890A', isValid: true },
            //     // rqthFile: { isEnabled: false, value:'///' },
            //     // cvFile: { isEnabled: true, value:'///' },
            //     // apprenticeNumber: { isEnabled: true, value:'///' },
            //     // aLevelHighSchool: { isEnabled: true, value:123 },
            //     // aLevelYear: { isEnabled: true, value:2003, isValid:true },
            // }
        }
    }

    componentDidMount() {
        const _this = this
        axios.get(
            `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${this.props.id}`
            // `${process.env.REACT_APP_API_URL}/student/${this.props.id}`
        )
            .then(
                (response) => {
                    if (response.data) {
                        if (response.data.status === 'completed') {
                            navigate(`/${this.props.lang}/profile/success/${this.props.id}`)
                        }
                        else if (response.data.status != 'paid') {
                            navigate(`/${this.props.lang}/admission/${this.props.id}`)
                        }
                        _this.setState({
                            settings: response.data,
                            initialValues: response.data,
                            student: response.data,
                            data: response.data,
                            isProfilLoaded: true,
                            isError: false,
                        })
                    } else {
                        _this.setState({
                            isError: true
                        })
                    }
                },
                (error) => {
                    _this.setState({
                        isError: true
                    })
                    console.log(error)
                }
            )
    }

    render() {
        const { initialValues, isError, settings, data } = this.state


        // return <Debug data={data} />
        return (
            initialValues && Object.keys(data).length > 0 ?
                <Layout
                    school={initialValues.school}
                    locale={this.props.lang}
                >
                    {this.state.isProfilLoaded && this.state.settings ?
                        <>
                            <Helmet>
                                <title>
                                    {this.props.lang === 'fr' ?
                                        `${initialValues.firstName ? initialValues.firstName : 'Bonjour'}, complétez votre profil ¬ ${initialValues.school.title}`
                                        :
                                        `${initialValues.firstName ? initialValues.firstName : 'Hello'}, complete your profile ¬ ${initialValues.school.title}`
                                    }
                                </title>
                                <link rel='icon' href={getFavicon(initialValues.school.code)} />
                            </Helmet>
                            <Grid
                                px={{ base: 4, lg: 0 }}
                                gridTemplateColumns={{
                                    base: `100%`,
                                    lg: '70% 1fr'
                                }}
                                gap={{
                                    base: 0,
                                    lg: 10
                                }}
                            >
                                <Box maxW='800px'>
                                    <Heading my={{ base: 4, lg: 12 }}>
                                        {initialValues.firstName},
                                        complétez{' '}
                                        <Text
                                            display='inline-block'
                                            borderBottom='solid 3px'
                                            borderBottomColor='brand.1.500'
                                        >
                                            votre profil
                                        </Text>
                                    </Heading>
                                    {initialValues && settings ?
                                        <ProfileForm
                                            // data={data}
                                            studentId={this.props.id}
                                            settings={settings}
                                        />
                                        : <Flex><Spinner /></Flex>}



                                </Box>
                                <Box
                                    pt={'40'}
                                    display={{ base: 'none', lg: 'block' }}
                                >
                                    <Box
                                        position='sticky'
                                        top='10'
                                    >
                                        {/* <ProfileFormNav
                                            settings={settings}
                                        /> */}
                                    </Box>
                                </Box>

                            </Grid>


                        </>
                        :
                        <Box>
                            <Center minH='100vh'>
                                <Spinner />
                            </Center>
                        </Box>
                    }

                </Layout>
                : null
        )
    }
}

export default ProfileFormLoader