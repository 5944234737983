const getFavicon = (id) => {
    // console.log('favicon', id)
    let favicon
    switch (id) {
        case 'eac':
            favicon = '/Favicons/eac.png'
            break;
        case 'sae':
            favicon = '/Favicons/edc.png'
            break;
        case 'edc':
            favicon = '/Favicons/edc.png'
            break;
        case 'ecv':
            favicon = '/Favicons/ecv.ico'
            break;
        case 'esd':
            favicon = '/Favicons/esd.png'
            break;
        case 'ing':
            favicon = '/Favicons/ing.ico'
            break;
        case 'esp':
            favicon = '/Favicons/esp.png'
            break;
        case 'esparf':
            favicon = '/Favicons/espa.ico'
            break;
        default:
            favicon = '/Favicons/default.png'
            break;
    }
    return (favicon)
}

export default getFavicon