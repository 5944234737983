import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Box, Flex, Grid, SimpleGrid } from '@chakra-ui/layout'
import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest';
import SchoolSuggest from './SchoolSuggest';

const HighSchoolSelector = ( { schoolId, setFieldValue }) => {

    const [ cp, setCp ] = useState('')

    return(
        <Box>
            <FormControl>
                <FormLabel>
                    Quel était votre lycée lors de votre année de terminale :
                </FormLabel>
                <Grid
                    columns={{ base : 1, lg:2 }}
                    templateColumns={{
                        base:`100%`,
                        lg:`35% 65%`
                    }}
                    gap={{
                        base:2, lg:0 
                    }}
                >
                    <Box>
                        <Input
                            bg='white'
                            placeholder='Code Postal'
                            borderRightRadius={{ base:'md', lg:0 }}
                            onChange={ (e)=> setCp( e.target.value.slice(0,2) ) }
                            
                        />
                    </Box>
                    <Flex>
                        {/* <Input
                            bg='white'
                            placeholder='Name'
                            borderLeftRadius={0}
                            type='text'
                        /> */}
                        <Box
                            // border='solid 1px'
                            // borderColor='gray.200'
                            w='full'
                            // borderRightRadius='md'
                            opacity={ cp ? '1' : .4 }
                        >
                            {/* { cp.length === 2 ?  */}
                        <SchoolSuggest
                            postalCode={ cp }
                            key={ cp.slice(0,2) }
                            setFieldValue={ setFieldValue }
                        />
                        {/* : null } */}

                        </Box>
                    </Flex>
                </Grid>
                <FormHelperText>
                    Saisissez le code postal de votre lycée.
                </FormHelperText>
            </FormControl>
        </Box>
    )
}

export default HighSchoolSelector