import { Box, Center } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import css from '../../Styles/wysiwyg.css'

const DocumentViewer = ({ url }) => {

    const [data, setData] = useState( false )

    useEffect(() => {
        (async () => {
            axios.get(url)
                .then((res)=>{
                    console.log( res.data )
                    setData( res.data )
                })
        })();
      }, []);

    return(
        <Box>
            {
                data ?
                    <Box className={'wysiwyg'} dangerouslySetInnerHTML={{ __html: data }} />
                :
                    <Center><Spinner /></Center>
                }
        </Box>
    )
}

export default DocumentViewer