import { Box, Heading, Divider, Stack, Text } from '@chakra-ui/layout'
import React from 'react'
import { Element } from 'react-scroll'

const Section = ({ children, id }) => {
    return (
        <Element name={id}>
            <Box
                position='relative'
                id={id}
            >
                <Stack
                    spacing={5}
                >
                    {children}
                </Stack>

                <Divider my={10} />
            </Box>
        </Element>
    )
}
const SectionTitle = ({ children }) => {
    return (
        <Heading
            fontSize={{ base:'2xl', lg:'lg' }}
            mb={2}
            pt={3}
        >{children}</Heading>
    )
}

const SectionLegend = ({ children }) => {
    return (
        <Box>
            <Text
                color='gray.700'
                fontSize='sm'
            >
                {children}
            </Text>
        </Box>
    )
}

export { Section, SectionTitle, SectionLegend }