import React from 'react'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Debug from '../Form/debug';

// import { config } from './Utils/Config';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import {
    Box,
    Text
} from '@chakra-ui/react'

export const AddressField = ({ newAddressCallback, initialValue }) => {

    const [value, setValue] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const [rawAddress, setRawAddress] = React.useState(null);

    const [formatedData, setFormatedData] = React.useState(null)


    React.useEffect(
        () => {
            if (value && value.label) { setRawAddress(value.label) }

        }, [value]
    );


    React.useEffect(
        () => {
            if (formatedData && Object.keys(formatedData).length > 0) newAddressCallback(formatedData)
            // if (formatedData && formatedData.formatted_address) newAddressCallback(formatedData)
        }, [formatedData]
    );

    React.useEffect(
        () => {
            // console.log('Raw address', rawAddress)
            console.log('value', value)
            let rawAddress = ''
            let rawCity = ''
            if (value && value.label) {
                rawAddress = value.label.split(',').slice(0, -2).map(item => item.trim()).join(' ');
                // rawCity = value.label.split(',').slice(0, -1).slice(-1)[0].trim();
                rawCity = value.label.split(',')
                rawCity = rawCity ? rawCity.slice(0, -1) : null
                rawCity = rawCity ? rawCity.slice(-1)[0] : null
                rawCity = rawCity ? rawCity.trim() : null
                // console.log('Raw city', rawCity)

            }

            if (value && value.value.place_id) {
                geocodeByPlaceId(value && value.value ? value.value.place_id : null)
                    .then(results => {
                        setResult(results);

                        // console.log('API Results', results)

                        let number,
                            route,
                            locality,
                            postalCode,
                            country
                                = ''


                        for (const [key, value] of Object.entries(results[0].address_components)) {
                            // console.log(`${key}`, value);
                            // console.log(value.types[0]);
                            switch (value.types[0]) {
                                case "street_number":
                                    number = value.long_name;
                                    // setNumber(value.short_name)
                                    break;
                                case "route":
                                    route = value.long_name;
                                    // setRoute(value.short_name)
                                    break;
                                case "locality":
                                    locality = value.long_name;
                                    // setLocality(value.short_name)
                                    break;
                                case "postal_code":
                                    postalCode = value.long_name;
                                    // setPostalCode(value.short_name)
                                    break;
                                case "country":
                                    country = value.long_name;
                                    // setCountry(value.short_name)
                                    break;

                                default:
                                    break;
                            }
                        }

                        setFormatedData({
                            ...(number ? { number: number } : null),
                            ...(route ? { route: route } : null),
                            city: locality ? locality : rawCity ? rawCity : '',
                            ...(postalCode ? { postalCode: postalCode } : null),
                            ...(country ? { country: country } : null),
                            address: `${results[0].formatted_address}`,
                            rawAddress: rawAddress
                        });

                        console.log('Address formated : ', {
                            ...(number ? { number: number } : null),
                            ...(route ? { route: route } : null),
                            city: locality ? locality : rawCity ? rawCity : '',
                            ...(postalCode ? { postalCode: postalCode } : null),
                            ...(country ? { country: country } : null),
                            address: `${results[0].formatted_address}`,
                            rawAddress: rawAddress
                        })

                        // console.log(
                        //     {
                        //         ...(number ? { number: number } : null),
                        //         route: route,
                        //         city: locality,
                        //         ...(postalCode ? { postalCode: postalCode } : null),
                        //         ...(country ? { country: country } : null),
                        //         address: `${results[0].formatted_address}`,
                        //         rawAddress: rawAddress
                        //     }
                        // )

                    })
                    .catch(error => console.error(error));
            }

        }, [value]
    )

    // return (<div>coucou</div>)

    return (
        <>
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                selectProps={{
                    value,
                    defaultInputValue: initialValue ? initialValue : null,
                    onChange: setValue,
                    placeholder: 'Saisissez ici ...',
                    noOptionsMessage: () => 'Saisissez l\'adresse recherchée ...',
                    loadingMessage: () => 'Chargement...',
                    isClearable: true,
                    escapeClearsValue: true,
                    backspaceRemovesValue: true,
                    clearValue: () => setValue(null)
                }}
            />
        </>
    )
}